import {LockClosedIcon} from '@heroicons/react/outline'
import {TooltipLite, BaseLockedTooltipContent} from '@components/elements'
import classNames from 'classnames'

export const LockedWrapperIcon = ({
    className,
    isBlocked,
    children,
    exceedProducts,
    exceedCabinets,
    isTrial,
}) => {
    return isBlocked || isTrial || exceedProducts || exceedCabinets ? (
        <TooltipLite
            offset={[0, 10]}
            content={
                <BaseLockedTooltipContent
                    isBlocked={isBlocked}
                    isTrial={isTrial}
                    exceedCabinets={exceedCabinets}
                    exceedProducts={exceedProducts}
                />
            }
            classNameBody={'md:p-2 max-w-lg'}
        >
            {(ref) => (
                <div ref={ref} className={classNames(className, 'relative')}>
                    {children}

                    <div className={'absolute right-0 top-0'}>
                        <LockClosedIcon
                            className={
                                'h-4 w-4 flex-shrink-0 text-gray-400 dark:text-gray-400'
                            }
                        />
                    </div>
                </div>
            )}
        </TooltipLite>
    ) : (
        children
    )
}
