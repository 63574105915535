import {observer} from 'mobx-react'
import {Card} from '@components/layout'

import {SettingsSelect} from '@components/applicationUi/SettingsSelect'
import {MarketIcon, Mdash} from '@components/elements'
import {InfoField} from '@components/applicationUi'
import {Select} from '@components/forms/Selects'
import {TextField} from '@components/forms/TextFields'

export const ImportWbInfoFilters = observer(({filterStore}) => {
    return (
        <Card innerClassName={'space-y-2'}>
            <h2
                className={
                    'text-base font-medium text-gray-800 dark:text-gray-200'
                }
            >
                Отбор отчетов
            </h2>
            <SettingsSelect
                label={'Кабинет'}
                selected={filterStore.cabinet.selected}
                options={filterStore.cabinet.options}
                onChange={filterStore.cabinet.setSelectedItem}
            >
                {({option, selected, active}) => (
                    <div className={'flex items-center'}>
                        <MarketIcon
                            iconClassName={'w-4x5 h-4x5 '}
                            size={20}
                            name={option.market}
                            className={'mr-3 flex-shrink-0'}
                        />
                        <span className={'truncate'}>{option.name}</span>
                    </div>
                )}
            </SettingsSelect>
            <InfoField
                label={'Юр.лицо'}
                helpContent={
                    'Если вы хотите изменить юр.лицо или привязать текущее юр.лицо к другому кабинету - обратитесь в тех. поддержку.'
                }
            >
                <span className={'text-sm'}>
                    {filterStore.cabinetCompanyName
                        ? filterStore.cabinetCompanyName
                        : ''}
                </span>
            </InfoField>
        </Card>
    )
})

ImportWbInfoFilters.displayName = 'ImportWbInfoFilters'
