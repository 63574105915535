import {Doughnut} from 'react-chartjs-2'
import {DashboardStubIcon} from '@components/elements/Icon/Icon'
import {Card} from '@components/layout'
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js'
import {observer} from 'mobx-react'
import {useColorGradient, useSelector, useBlocked} from '@hooks'
import {convertAmountValue} from '@components/elements'
import {Loader} from '@components/elements/Loader'
import {ChartPieIcon} from '@heroicons/react/outline'

ChartJS.register(ArcElement, Tooltip, Legend)
export const StructureDoughnut = observer(
    ({
        doughnutData,
        sizeSm,
        loader,
        label,
        doughnutKey,
        getLabels,
        getCustomBackgroundColor,
        getTooltipLabel,
    }) => {
        const isBlocked = useBlocked()
        const {isDarkTheme} = useSelector((store) => store.themeStore)
        const {getGradient} = useColorGradient()
        const colorCount = doughnutKey.length - 1
        const colorHex = isBlocked
            ? isDarkTheme
                ? '#929da6'
                : '#e5e7eb'
            : '#9f494a'
        const colorHoverHex = isBlocked
            ? isDarkTheme
                ? '#808891'
                : '#e5e7eb'
            : '#e76767'

        const colorGradient =
            colorCount > 0 ? getGradient(colorHex, colorCount, 20) : colorHex
        const hoverColorGradient =
            colorCount > 0
                ? getGradient(colorHoverHex, colorCount)
                : colorHoverHex

        const getBackgroundColor = (colorGradient, isHover) => {
            let i = 0
            const getIndex = () => {
                return i++
            }
            if (isBlocked) return doughnutKey.map(() => colorGradient[0])
            return getCustomBackgroundColor(colorGradient, getIndex, isHover)
        }
        const defaultDatasets = {
            backgroundColor: getBackgroundColor(colorGradient),
            hoverBackgroundColor: getBackgroundColor(hoverColorGradient, true),
            borderColor: doughnutKey.map((item) =>
                !isDarkTheme ? 'rgba(255,255,255,1)' : 'rgba(55, 65, 81, 1)'
            ),
            borderWidth: 2,
        }

        const data = {
            labels: getLabels(),
            datasets: [
                {
                    label: 'CostStructure',
                    data: doughnutData.map((item) =>
                        convertAmountValue({
                            value: item.rate || 0,
                            showCurrency: false,
                        }).replace(',', '.')
                    ),
                    ...defaultDatasets,
                },
            ],
        }

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return tooltipItem[0].label
                        },
                        label: getTooltipLabel,
                    },
                },
            },
            mode: 'nearest',
            intersect: true,
        }
        return (
            <Card>
                <div className={'flex flex-col items-center space-y-4'}>
                    <span
                        className={
                            !sizeSm
                                ? 'text-2xl text-gray-700 dark:text-gray-200'
                                : 'text-md font-medium text-gray-800 dark:text-gray-100'
                        }
                    >
                        {label}
                    </span>
                    {loader ? (
                        <div className={'flex h-[350px] items-center'}>
                            <Loader overlayed={false} />
                        </div>
                    ) : !doughnutData.length ? (
                        <div
                            className={
                                'mb-8 flex h-[350px] w-full justify-center'
                            }
                        >
                            <ChartPieIcon
                                className={
                                    'h-full max-h-[300px] w-1/2 max-w-[300px] text-gray-300 dark:text-gray-500'
                                }
                            />
                        </div>
                    ) : (
                        <Doughnut
                            options={options}
                            data={data}
                            plugins={[]}
                            type={'doughnut'}
                            fallbackContent={
                                <div
                                    className={
                                        'flex-1 items-center justify-center'
                                    }
                                >
                                    <DashboardStubIcon className={''} />
                                </div>
                            }
                        />
                    )}
                </div>
            </Card>
        )
    }
)
