import {useEffect, useState} from 'react'
import {TextField} from '@components/forms/TextFields'
import {useDebouncedFunction} from '@hooks'

export const SearchTextField = ({
    onChange,
    value,
    changeDelay = 500,
    ...rest
}) => {
    const [currentValue, setCurrentValue] = useState('')
    const [isStop, setIsStop] = useState(false)

    useEffect(() => {
        if (value !== undefined && value !== currentValue) {
            setCurrentValue(value)
        }
    }, [value])

    useEffect(() => () => {
        return () => {
            setIsStop(true)
        }
    })
    const onChangeDelay = useDebouncedFunction(onChange, changeDelay, isStop)

    const handleChangeValue = (e) => {
        const value = e.target.value
        setCurrentValue(value)
        onChangeDelay(e)
    }

    return (
        <TextField
            {...rest}
            value={currentValue}
            onChange={handleChangeValue}
        />
    )
}
