import {useEffect, useRef, useState, useMemo} from 'react'
import {observer} from 'mobx-react'
import './styles/components/alert.scss'
import './styles/components/tooltip-lite.scss'
import {RenderRoutes, ROUTES} from './routes'
import {useHistory, useLocation} from 'react-router-dom'
import {useResponsive, useSelector, useHiddenConfirmCookies} from '@hooks'
import {AUTH_URLS} from '@routesEnums'
import {gtmAuthCompleted} from '@utils/googleUtils/utils'
import {carrotQuestInit} from '@utils/scripts/carrotQuest'
import {getStandName} from '@utils/helpers'
import {OnboardingStepStore} from '@components/navigation/header/Onboarding/store/OnboardingStepStore'
import {OnboardingContext} from '@components/navigation/header/Onboarding/context/OnboardingContext'
import {LimitBlockedProvider} from '@context/LimtiBlockedContext'
import {ToastContainerWrapper} from '@components/layout/ToastContainerWrapper'
import {NotificationsProvider} from '@components/overlays/notifications'
import {PathBlockedProvider} from '@context/PathBlockedContext'
import {TrialBlockedProvider} from '@context/TrialBlockedContext'

const useConstructor = (callBack = () => {}) => {
    const hasBeenCalled = React.useRef(false)
    if (hasBeenCalled.current) return
    callBack()
    hasBeenCalled.current = true
}

const App = observer(() => {
    const history = useHistory()
    const location = useLocation()
    const authStore = useSelector((store) => store.authStore)
    const marketStore = useSelector((store) => store.marketStore)
    const userStore = useSelector((store) => store.userStore)
    const settingsStore = useSelector((store) => store.settingsStore)
    const {checkRole, setIsFullSidebar} = useSelector(
        (store) => store.layoutSidebarStore
    )
    const [onboardingStepStore] = useState(
        () => new OnboardingStepStore(marketStore, authStore)
    )

    const isOnceRef = useRef(false)
    useEffect(() => {
        if (getStandName() !== 'dev') {
            carrotQuestInit()
        }
        // vkPlayerInit()

        const servicesSection = JSON.parse(
            localStorage.getItem('servicesSection')
        )
        marketStore.setServicesSection(!!servicesSection)
    }, [])
    useHiddenConfirmCookies()

    const {xxl} = useResponsive()
    useEffect(() => {
        if (xxl) {
            setIsFullSidebar(true)
        } else {
            setIsFullSidebar(false)
        }
    }, [])

    useEffect(() => {
        checkRole()
        if (userStore.userData.user_uuid && !isOnceRef.current) {
            gtmAuthCompleted(userStore)
            fetchAnalyticsData()
            isOnceRef.current = true
        }
    }, [userStore.userData])

    useConstructor(() => {
        authStore.setHistory(history)
        authStore.setAuthDataFromStorages(
            !AUTH_URLS.some((url) => location.pathname.includes(url))
        )
    })

    useEffect(() => {
        if (userStore.userData.user_uuid) {
            onboardingStepStore.getOnboardCurrentStep()
        }
        return () => {
            onboardingStepStore.destroy()
        }
    }, [userStore.userData.user_uuid])

    const fetchAnalyticsData = async () => {
        await settingsStore.fetchSettingsData()
    }

    return (
        <PathBlockedProvider userStore={userStore}>
            <LimitBlockedProvider userStore={userStore}>
                <TrialBlockedProvider userStore={userStore}>
                    <NotificationsProvider>
                        <OnboardingContext.Provider
                            value={useMemo(
                                () => ({onboardingStepStore}),
                                [onboardingStepStore]
                            )}
                        >
                            <RenderRoutes routes={ROUTES} />
                            <ToastContainerWrapper />
                        </OnboardingContext.Provider>
                    </NotificationsProvider>
                </TrialBlockedProvider>
            </LimitBlockedProvider>
        </PathBlockedProvider>
    )
})

App.displayName = 'App'

export default App
