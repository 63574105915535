import {ApiFactory} from '@services/ApiFactory'
import {CatalogUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'

const catalogInstance = ApiFactory.createApi(baseConfig)

class CatalogService {
    static getCatalog = async (query) => {
        return await catalogInstance.get(CatalogUrl.catalog, query)
    }

    static getCard = async (id) => {
        return await catalogInstance.get(CatalogUrl.cardId(id))
    }

    static getCardStats = async (id, query) => {
        return await catalogInstance.get(CatalogUrl.cardStats(id), query)
    }

    static generateBatchUpdateTemplate = async (query) => {
        return await catalogInstance.post(
            CatalogUrl.batchUpdateTemplateGenerate,
            query
        )
    }

    static getBatchUpdateTemplate = async (query) => {
        return await catalogInstance.getFileWithGet(
            CatalogUrl.batchUpdateTemplate,
            query
        )
    }

    static getBatchUpdateTemplateStatus = async () => {
        return await catalogInstance.get(CatalogUrl.batchUpdateTemplateStatus)
    }

    static getBatchUpdateStatus = async () => {
        return await catalogInstance.get(CatalogUrl.batchUpdateStatus)
    }

    static getBatchUpdateResultsList = async (query) => {
        return await catalogInstance.get(
            CatalogUrl.batchUpdateResultsList,
            query
        )
    }

    static postBatchUpdateFile = async (payload) => {
        return await catalogInstance.postFile(
            CatalogUrl.batchUpdateFile,
            payload
        )
    }

    static getBatchUpdateValidationErrors = async (resultId) => {
        return await catalogInstance.getFileWithGet(
            CatalogUrl.validationErrors(resultId)
        )
    }
}

export default CatalogService
