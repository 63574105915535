import {forwardRef} from 'react'
import {NavLink, useRouteMatch, useLocation} from 'react-router-dom'
import cn from 'classnames'
import './headerTabItem.scss'
import {Badge, TooltipLite} from '@components/elements'
import {observer} from 'mobx-react'

const Tab = forwardRef(
    ({disabled, search, warn, count, name, url, id, onClickTab}, ref) => {
        return (
            <NavLink
                ref={ref}
                className={cn(
                    'tab-item text-gray-500',
                    'group -mx-1 inline-flex items-center whitespace-pre-line text-lg font-medium hover:text-gray-300',
                    {
                        'pointer-events-none text-gray-400': disabled,
                    }
                )}
                activeClassName={cn(
                    'tab-item--active',
                    'text-gray-800 dark:text-gray-200'
                )}
                to={{
                    pathname: `${url}/${id}/`,
                    search,
                }}
                onClick={onClickTab}
            >
                {name}
                {!!count && (
                    <Badge
                        className={'ml-3'}
                        rounded
                        bright
                        color={warn ? 'red' : 'gray'}
                    >
                        {count}
                    </Badge>
                )}
            </NavLink>
        )
    }
)

const HeaderTabsItem = observer(({tab, onClickTab}) => {
    const {visible, disabled, id, name, count, warn, tooltip} = tab
    const match = useRouteMatch()
    const location = useLocation()

    const handleClickTab = () => {
        if (typeof onClickTab === 'function') onClickTab(id)
    }

    const tabProps = {
        disabled,
        warn,
        count,
        name,
        id,
        url: match.url,
        search: location.search,
        onClickTab: handleClickTab,
    }

    if (visible !== undefined && visible === false) return null

    if (tooltip) {
        return (
            <TooltipLite
                classNameBody={'max-w-[250px]'}
                offset={[0, 10]}
                content={tooltip}
            >
                {(ref) => {
                    return <Tab ref={ref} {...tabProps} />
                }}
            </TooltipLite>
        )
    }
    return <Tab {...tabProps} />
})

export {HeaderTabsItem}
