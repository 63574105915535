import {observer} from 'mobx-react'
import {SettingsSelect} from '@components/applicationUi/SettingsSelect'
import {Card} from '@components/layout'

export const ImportWbInfoSettings = observer(({filterStore}) => {
    const handleChangePriorityData = (value) => {
        filterStore.priorityData.setSelectedItem(value)
        filterStore.patchSource()
    }
    return (
        <Card innerClassName={'space-y-2'}>
            <h2
                className={
                    'text-base font-medium text-gray-800 dark:text-gray-200'
                }
            >
                Настройка и загрузка отчетов
            </h2>
            <SettingsSelect
                label={'Приоритет источника данных'}
                disabled={!filterStore.cabinet.selected?.key}
                selected={filterStore.priorityData.selected}
                options={filterStore.priorityData.options}
                onChange={handleChangePriorityData}
                helpContent={
                    'Выберите приоритетный источник данных. Если за диапазон дат доступны данные только из одного источника (API или Excel), по умолчанию используется этот источник, вне зависимости от настройки'
                }
            />
        </Card>
    )
})

ImportWbInfoSettings.displayName = 'ImportWbInfoSettings'
