import {observer} from 'mobx-react'
import {SettingsHelp} from '@components/applicationUi'

export const InfoField = observer(({label, helpContent, children}) => {
    return (
        <div
            className={
                'grid grid-cols-8 items-center space-x-4 text-gray-800 dark:text-gray-100'
            }
        >
            {/*<div className={'flex w-2/3 space-x-2'}>*/}
            <div className={'col-span-4 flex space-x-2'}>
                <span>{label}</span>
            </div>
            <div
                className={
                    'col-span-3 flex items-center justify-between space-x-4'
                }
            >
                {children}
            </div>
            {helpContent && (
                <div className={'col-span-1 flex justify-end'}>
                    <SettingsHelp content={helpContent} />
                </div>
            )}
        </div>
    )
})
