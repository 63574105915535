import {observer} from 'mobx-react'
import {Modal, ModalHeader} from '@components/overlays'

import {FillingCredsDialog} from './FillingCredsDialog/FillingCredsDialog'

export const FillingCredsModal = observer(({open, onClose, ...props}) => {
    return (
        <Modal className={'sm:max-w-[580px]'} open={open} onClose={onClose}>
            <ModalHeader title={'Уточните ваши данные'} onCancel={onClose} />
            <FillingCredsDialog onClose={onClose} {...props} />
        </Modal>
    )
})
