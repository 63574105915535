//libs
import {useRef, useState, useEffect} from 'react'
import classNames from 'classnames'

//components
import {ButtonGroup, Button, LockedWrapperIcon} from '@components/elements'

//icons
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid'
import {Select} from '@components/forms/Selects'
import {useDebouncedFunction} from '@hooks'

export const Pagination = (props) => {
    const {
        max = 5,
        page: current,
        limit,
        total,
        totalPages,
        isLimit = false,
        onClickPage,
        onChangeLimit = () => {},
        className,
        isBlocked,
        disabled,
        totalRatio,
        externalButton,
    } = props

    const {current: maxView} = useRef(max)
    const [pages, setPages] = useState([])
    //const [options, setPages] = useState([])

    const calculatedTotal =
        totalRatio > 0 ? Math.floor(total * totalRatio) : total
    const calculatedTotalPages = Math.ceil(calculatedTotal / limit)

    const options = [
        {
            id: 10,
            name: 10,
        },
        {
            id: 50,
            name: 50,
        },
        {
            id: 100,
            name: 100,
        },
    ]

    useEffect(() => {
        initPages()
    }, [current, totalPages])

    const isFirstPages = () => {
        return current + 1 < maxView
    }

    const isLastPages = () => {
        const halfOfMaxView = Math.round(maxView / 2)
        return current + maxView > totalPages + halfOfMaxView - 1
    }

    const generatePageNumbers = (start = 0) => {
        const newPages = []
        if (totalPages < maxView) {
            for (let i = 0; i < totalPages; i++) {
                newPages.push(i + start + 1)
            }
        } else {
            for (let i = 0; i < maxView; i++) {
                newPages.push(i + start + 1)
            }
        }

        setPages(newPages)
    }

    const initPages = () => {
        const halfOfMaxView = Math.round(maxView / 2)
        if (isFirstPages()) return generatePageNumbers()
        if (isLastPages()) {
            const start = totalPages > maxView ? totalPages - maxView : 0
            return generatePageNumbers(start)
        }

        return generatePageNumbers(current - halfOfMaxView)
    }

    const onClickPageDebounce = useDebouncedFunction(onClickPage, 500)

    const handleChangePage = (index) => () => {
        if (index > totalPages || index < 1) return
        onClickPageDebounce(index)
    }

    const renderPageNumberView = (number, index) => {
        if (
            (index === 0 && !isFirstPages()) ||
            (index === pages.length - 1 && !isLastPages())
        )
            return '...'
        else return number
    }

    const handleChangeLimit = (value) => {
        onChangeLimit(value.id)
    }

    return (
        <div
            className={classNames(
                className,
                'flex items-center justify-between border-t border-gray-200 bg-gray-100 px-4 py-3 sm:px-6',
                'dark:border-gray-600 dark:bg-gray-800'
            )}
        >
            <div className='flex flex-1 justify-between md:hidden'>
                <Button
                    color={'white'}
                    disabled={disabled || isBlocked || current < 2}
                    onClick={handleChangePage(current - 1)}
                >
                    Назад
                </Button>
                <Button
                    color={'white'}
                    disabled={disabled || isBlocked || current > totalPages - 1}
                    onClick={handleChangePage(current + 1)}
                >
                    Вперед
                </Button>
            </div>
            <div className='hidden sm:flex-1 md:flex md:items-center md:justify-between'>
                <div>
                    <p className='text-sm text-gray-700 dark:text-gray-400'>
                        с{' '}
                        <span className='font-medium'>
                            {(current - 1) * limit + 1}
                        </span>{' '}
                        по{' '}
                        <span className='font-medium'>
                            {total > limit
                                ? (current - 1) * limit + limit
                                : total}
                        </span>{' '}
                        из <span className='font-bold'>{total}</span>{' '}
                        результатов{' '}
                        {totalRatio > 0 ? (
                            <span className='font-bold'>{`(доступно ${calculatedTotal})`}</span>
                        ) : null}
                    </p>
                </div>
                <div className={'flex gap-4'}>
                    {externalButton}
                    {isLimit && (
                        <LockedWrapperIcon isBlocked={isBlocked}>
                            <Select
                                disabled={disabled || isBlocked}
                                //className={'w-full'}
                                portal
                                options={options}
                                selected={{id: limit, name: limit}}
                                onChange={handleChangeLimit}
                            />
                        </LockedWrapperIcon>
                    )}
                    <LockedWrapperIcon isBlocked={isBlocked} className={'flex'}>
                        <ButtonGroup>
                            <Button
                                size={'sm'}
                                color={'white'}
                                disabled={disabled || isBlocked || current < 2}
                                onClick={handleChangePage(current - 1)}
                            >
                                <span className='sr-only'>Previous</span>
                                <ChevronLeftIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                />
                            </Button>
                            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                            {!isFirstPages() && totalPages > maxView && (
                                <Button
                                    size={'sm'}
                                    color={'white'}
                                    disabled={disabled || isBlocked}
                                    onClick={handleChangePage(1)}
                                >
                                    1
                                </Button>
                            )}
                            {pages.map((page, index) => {
                                return (
                                    <Button
                                        key={page}
                                        size={'sm'}
                                        color={'white'}
                                        disabled={
                                            disabled ||
                                            isBlocked ||
                                            page > calculatedTotalPages
                                        }
                                        onClick={handleChangePage(page)}
                                        active={current === page}
                                    >
                                        {renderPageNumberView(page, index)}
                                    </Button>
                                )
                            })}
                            {!isLastPages() && totalPages > maxView && (
                                <Button
                                    size={'sm'}
                                    color={'white'}
                                    disabled={
                                        disabled ||
                                        isBlocked ||
                                        totalPages > calculatedTotalPages
                                    }
                                    onClick={handleChangePage(totalPages)}
                                >
                                    {totalPages}
                                </Button>
                            )}
                            <Button
                                size={'sm'}
                                color={'white'}
                                disabled={
                                    disabled ||
                                    isBlocked ||
                                    current + 1 > calculatedTotalPages ||
                                    current > totalPages - 1
                                }
                                onClick={handleChangePage(current + 1)}
                            >
                                <span className='sr-only'>Next</span>
                                <ChevronRightIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                />
                            </Button>
                        </ButtonGroup>
                    </LockedWrapperIcon>
                </div>
            </div>
        </div>
    )
}
