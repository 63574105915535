import {Component} from 'react'
import {rootStore} from '@'
import {observer} from 'mobx-react'

class ErrorBoundary extends Component {
    componentDidCatch(error, errorInfo) {
        rootStore.errorStore.setHasError(true)
        rootStore.errorStore.setError(error)
        rootStore.errorStore.setErrorInfo(errorInfo)
    }

    render() {
        if (rootStore.errorStore.hasError) {
            return this.props.component
        }

        return <>{this.props.children}</>
    }
}

export default observer(ErrorBoundary)
