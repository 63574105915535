//images
import card1 from '@assets/images/services/card-1.svg?url'
import card2 from '@assets/images/services/card-2.svg?url'
import card3 from '@assets/images/services/card-3.svg?url'
import card4 from '@assets/images/services/card-4.svg?url'
import card5 from '@assets/images/services/card-5.svg?url'
import card6 from '@assets/images/services/card-6.svg?url'
import card7 from '@assets/images/services/card-7.png?url'
import card8 from '@assets/images/services/card-8.png?url'
import card9 from '@assets/images/services/card-9.png'

export const services = [
    {
        title: 'Выведение на маркетплейсы',
        requestType: 'request_service_open',
        description: [
            'Масштабируйте свой бизнес за счет выхода на новые платформы. Поможем безошибочно создать магазин на любом маркетплейсе под ключ.',
            'Создание магазина на Мегамаркете при покупке Seller24 - бесплатно!',
        ],
        img: card1,
        imgClass: 'scale-x-[-1] -mr-4 h-[130px] lg:h-[140px]',
        modal: {
            description:
                'Персональный менеджер предложит оптимальную схему отгрузки товаров.\n' +
                '\n' +
                '✓ Расскажем, как работать с личным кабинетом поставщика.\n' +
                '✓ Зарегистрируем кабинет и загрузим 50 SKU.\n' +
                '✓ Настроим логистику (выберем склад).\n' +
                '✓ Выберем выгодную логистическую схему.\n' +
                '✓ Проконсультируем, как готовить товары к отгрузке. \n' +
                '✓ Оформим заявку на первую поставку и проверим приемку. \n' +
                '\n' +
                'Оставьте заявку и мы расскажем подробности. \n',
            cta: 'Оставить заявку',
        },
        serviceId: 'connect_mp',
    },
    {
        title: 'Продвижение магазина',
        requestType: 'request_service_promotion',
        description: [
            'Не получается выйти на желаемый оборот? Доверьтесь нам. Составим план продвижения на основе аудита вашего магазина. Настроим рекламу, которая окупается.',
        ],
        img: card3,
        imgClass: 'h-[160px] lg:h-[180px] -mb-4',
        modal: {
            description:
                'Громко заявите о себе, используя актуальные инструменты рекламы. Чтобы сэкономить время и расходовать бюджеты эффективно, доверьте продвижение нашим специалистам:  \n' +
                '\n' +
                '✓ Проанализируем товарную категорию (тариф Promo).\n' +
                '✓ Внедрим бесплатные и платные методы.\n' +
                '✓ Составим стратегию продвижения и медиа-план.\n' +
                '✓ Настроим рекламные кампании и добавим товары в акции маркетплейса.\n' +
                '✓ Проведем аудит магазина (анализ воронки продаж, аналитика позиций товаров в поиске, проверка отзывов и рейтинга, оценка эффективности продвижения и ценовой политики).\n' +
                '✓ Еженедельные видео-встречи с экспертом.\n' +
                '✓ Детальные отчеты каждую неделю.\n' +
                '✓ Продвижением вашего магазина займутся персональный аккаунт-менеджер и маркетолог.\n' +
                '\n' +
                'Оставьте заявку и менеджер расскажет все подробности.\n',
            cta: 'Оставить заявку',
        },
        serviceId: 'seo',
    },
    {
        title: 'Аккаунтинг магазина',
        requestType: 'request_service_accounting',
        description: [
            'Поручите команде экспертов рутинные задачи: от отгрузок и работы с отзывами до контроля остатков и подготовки отчетов. Благодаря использованию актуальных инструментов продвижения поможем увеличить выручку и маржинальность.\n',
        ],
        img: card7,
        imgClass: 'h-[125px] lg:h-[150px] -mb-3',
        modal: {
            description:
                'Делегируйте работу над личным кабинетом специалистам Seller24 и сконцентрируйтесь на расширении бизнеса. \n' +
                '\n' +
                'Комплексная работа экспертов: аккаунт-менеджер, маркетолог, аналитик, SEO специалист, копирайтер, дизайнер.\n' +
                '\n' +
                '✓ Внешняя аналитика категорий товара.\n' +
                '✓ Внутренняя аналитика кабинета.\n' +
                '✓ Аудит контента.\n' +
                '✓ Аудит продвижения.\n' +
                '✓ Операционный аудит магазина (товарная аналитика, ABC-анализ, анализ региональных продаж, маржинальная прибыль, юнит-экономика).\n' +
                '✓ Медиаплан продвижения.\n' +
                '✓ Операционное сопровождение магазина.\n' +
                '\n' +
                'Оставьте заявку, менеджер свяжется с вами и расскажет все подробности.\n',
            cta: 'Оставить заявку',
        },
        serviceId: 'accounting',
    },
    // {
    //     title: 'Сопровождение',
    //     description: [
    //         'Возьмем на себя все рутинные задачи: от отгрузок и работы с отзывами до контроля остатков и подготовки отчетов. А еще каждый месяц будем предоставлять отчет по продажам с рекомендациями, чтобы вы торговали в плюс.',
    //     ],
    //     img: card2,
    //     imgClass: '-mr-9 h-[130px] lg:h-[150px] -mb-6',
    //     modal: {
    //         description:
    //             'Вы оформили карточки товаров, загрузили контент, но продаж все еще нет или очень мало? Не стоит переживать, наша служба заботы о клиентах придет на помощь и не оставит вас наедине с проблемами — специалисты дадут советы и оперативно ответят на любые вопросы. \n' +
    //             '\n' +
    //             'Вместе мы правильно выстроим каждый этап работы: проанализируем, что нужно улучшить, настроим рекламу (бесплатные инструменты + дополнительный трафик), поднимем рейтинг магазина, увеличим выручку и будем следить за аналитикой. Услуга подойдет и новичкам, и действующим селлерам.  \n' +
    //             '\n' +
    //             'Оставьте заявку и персональный менеджер расскажет все подробности.\n',
    //         cta: 'Оставить заявку',
    //     },
    //     serviceId: 'sales_support',
    // },
    {
        title: 'Идеальный контент магазина',
        requestType: 'request_service_content',
        description: [
            'Создадим продающий контент для вашего магазина и карточек товаров: сделаем студийные фото товара, RICH-контент, дизайн фото карточки товара, инфографику и описание с SEO-оптимизацией.',
        ],
        img: card4,
        imgClass: 'h-[100px] w-[130px] lg:w-[140px] mb-2 lg:mb-4 mr-2 lg:mr-4',
        modal: {
            description:
                'Карточки товаров должны выделяться на фоне конкурентов и быть информативными. Продающий контент увеличит конверсию в переход в карточку товара.\n' +
                '\n' +
                'Работаем под ключ:\n' +
                '\n' +
                '✓ Собираем оптимизированное SEO-ядро и составляем продающее описание карточки товара.\n' +
                '✓ Прорабатываем визуальную воронку карточки товара, включая допсейл других товаров из вашего магазина.\n' +
                '✓ Выделяем преимущества товара за счет инфографики.\n' +
                '✓ Создаем дизайн фото товара, которое выделит вас среди аналогичных предложений конкурентов.\n' +
                '✓ Ретушируем ваши фотографии товаров.\n' +
                '✓ Создаем RICH-контент.\n' +
                '✓ Оформляем витрину на главной странице магазина.\n' +
                '\n' +
                'Оставьте заявку и мы расскажем все подробности.',
            cta: 'Оставить заявку',
        },
        serviceId: 'infographics',
    },
    // {
    //     title: 'Настройка и ведение рекламы',
    //     description: [
    //         'Заказывая у нас настройку внутренней рекламы, вы экономите бюджет и время на изучение тонкостей продвижения и постоянное отслеживание метрик. Каждый месяц формируем отчеты об эффективности кампаний.',
    //     ],
    //     img: card9,
    //     imgClass: 'h-[125px] lg:h-[150px] -mb-3',
    //     modal: {
    //         description:
    //             'Вы создали магазин и классно оформили карточки, что дальше? Не ждите первых продаж — генерите их сами! Сделать это можно с помощью стимуляции органических продаж — то есть закупки товаров для их продвижения в поисковой выдаче. Результатом такой механики является решение сразу несколько важных задач: \n' +
    //             '\n' +
    //             '• Формирование истории покупок;\n' +
    //             '• Наличие положительных отзывов с фото и видео; \n' +
    //             '• Ранжирование карточки товара от объёма заказов; \n' +
    //             '• Рост видимости в поисковой выдаче по заданным ключевым словам; \n' +
    //             '• Повышение общего рейтинга магазина; \n' +
    //             '• Рост продаж. \n' +
    //             '\n' +
    //             'В услугу входит: \n' +
    //             '\n' +
    //             '• Расчет количества выкупов и отзывов, необходимых для достижения результата;\n' +
    //             '• Составление календаря выкупов;\n' +
    //             '• Оформление заказов и их забор из ПВЗ;\n' +
    //             '• Копирайтинг и размещение отзывов;\n' +
    //             '• Сопровождение проекта до достижения поставленных целей;\n' +
    //             '\n' +
    //             'Оставьте заявку и персональный менеджер расскажет все подробности.\n',
    //         cta: 'Узнать подробнее',
    //     },
    //     serviceId: 'buyouts',
    // },
    {
        title: 'Аналитика товарных ниш',
        requestType: 'request_service_analytics',
        description: [
            'Проверим, прибыльна ли товарная ниша, в которую вы хотите зайти. Оценим уровень конкуренции и монополизации, подсчитаем средний объем продаж и оптимальное количество SKU для магазина.',
        ],
        img: card6,
        imgClass: 'h-[125px] lg:h-[150px] -mb-3',
        modal: {
            description:
                'Проанализируем товарную категорию и дадим рекомендации.\n' +
                '\n' +
                '✓ Уровень конкуренции в нише и объемы продаж.\n' +
                '✓ Приоритетный ценовой сегмент.\n' +
                '✓ Рекомендуемая себестоимость и оптимальное количество артикулов.\n' +
                '✓ Детальные отчеты.\n' +
                '\n' +
                'Оставьте заявку, менеджер свяжется с вами и расскажет все подробности.\n',
            cta: 'Оставить заявку',
        },
        serviceId: 'niche_analytics',
    },
    {
        title: 'Академия Seller24',
        requestType: 'request_service_academy',
        description: [
            'Все о бизнесе на маркетплейсах: теория, практика, бонусы, сообщество и поддержка. Профессиональные навыки с первых дней обучения. Все лекции закрепляются практическими занятиями.',
        ],
        img: card8,
        imgClass: 'h-[125px] lg:h-[150px] -mb-3',
        modal: {
            description:
                'Поможем разобраться в нюансах работы и собрать команду. Быстро погрузитесь в тему с помощью наших обучающих материалов и консультаций от экспертов Seller24. По окончанию программы выдаем именной сертификат. \n' +
                '\n' +
                'Кому подходит: \n' +
                '– начинающие селлеры, которые не понимают правил и алгоритмов площадок; \n' +
                '– опытные продавцы, которые хотят масштабировать бизнес и нанять сотрудников; \n' +
                '– менеджеры маркетплейсов, которым нужна удаленная работа с доходом от 150 000 ₽ в месяц (есть тариф со стажировкой). \n' +
                '\n' +
                '✓ Совершенствуйте свой бизнес с практическими заданиями. \n' +
                '✓ Онлайн-встречи и обратная связь от специалистов Seller24. \n' +
                '✓ Доступно, экспертно и в удобном вам темпе. \n' +
                '✓ Гибкий график и возможность пройти курс несколько раз (доступ остается на год). \n' +
                'Поможем с поиском персонала — от собеседований до сопровождения в течение первых двух месяцев работы. \n' +
                '\n' +
                'Оставьте заявку, менеджер свяжется с вами и расскажет все подробности.\n',
            cta: 'Оставить заявку',
        },
        serviceId: 'recruitment_and_training_of_personnel',
    },
]
