import {ApiFactory} from '@services/ApiFactory'
import {baseConfig} from '@services/apiConfigs'
import {MonitoringUrl} from '@routesEnums'

const monitoringInstance = ApiFactory.createApi(baseConfig)

class MonitoringService {
    static postProductByUrl = async (payload) => {
        return await monitoringInstance.post(
            MonitoringUrl.addProductByUrl,
            payload
        )
    }

    static getProductPositions = async (payload) => {
        return await monitoringInstance.get(
            MonitoringUrl.productPositions,
            payload
        )
    }

    static postProducts = async (payload) => {
        return await monitoringInstance.post(MonitoringUrl.addProducts, payload)
    }

    static deleteProducts = async (payload) => {
        return await monitoringInstance.delete(
            MonitoringUrl.deleteProducts,
            payload
        )
    }
}

export default MonitoringService
