import {useMemo} from 'react'
import {PlayIcon} from '@components/elements/Icon'
import {parseRutubeVideoUrl} from '@utils/helpers'

export const VideoPreview = ({videoUrl, width, height, onClick}) => {
    const videoId = useMemo(() => parseRutubeVideoUrl(videoUrl), [videoUrl])
    const thumbnailUrl = `https://rutube.ru/api/video/${videoId}/thumbnail/?redirect=1`

    return (
        <div
            style={{width, height}}
            className={'relative cursor-pointer'}
            onClick={onClick}
        >
            <img
                className={'h-full w-full object-cover'}
                src={thumbnailUrl}
                alt='Video Preview'
            />
            <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
                <PlayIcon className='h-8 w-8 text-blue-500' />
            </div>
        </div>
    )
}
