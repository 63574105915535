import {GlobeIcon, KeyIcon} from '@heroicons/react/outline'
import {useSelector} from '@hooks'
import {Header} from '@components/navigation'
import {observer} from 'mobx-react'
import {GoBack} from '@components/applicationUi/GoBack'
import {NotificationPage} from '@components/applicationUi'

const PageNotFound = observer(({setSidebarOpen}) => {
    const {myProfileOptions} = useSelector((store) => store.layoutSidebarStore)
    const {homePage} = useSelector((store) => store.userStore)

    return (
        <>
            <Header
                setSidebarOpen={setSidebarOpen}
                menuOptions={myProfileOptions}
            >
                <GoBack />
            </Header>
            <NotificationPage
                title={'Данная страница не найдена!'}
                description={
                    'Проверьте правильность адреса страницы или перейдите на главную. '
                }
                icon={
                    <GlobeIcon className='mx-auto h-20 w-20 text-blue-500 dark:text-blue-300' />
                }
                link={homePage}
                linkLabel={'На главную'}
            />
        </>
    )
})

export default PageNotFound
