import {useState} from 'react'
import {observer} from 'mobx-react'
import {format} from 'date-fns'
import {ru} from 'date-fns/locale'
import {CustomCalendar} from '@components/forms/DatePicker'
import {Button, LinkUnderline, MarketIcon} from '@components/elements'
import {TextField} from '@components/forms/TextFields'
import {SIGNS} from '@components/elements/Amount/convertAmount'
import {ModalCaller, ModalConfirm} from '@components/overlays'
import {useSectionInstructions, useSelector} from '@hooks'
import {Select} from '@components/forms/Selects'
import {TaxHistory} from './components/TaxHistory'
import {TaxStore} from '@store/TaxStore'
import {SettingsHelp, TaxOverlayInstructions} from '@components/applicationUi'
import {Toggle, ToggleLabeled} from '@components/forms'
import {toast} from 'react-toastify'

export const TaxRatingOverlay = observer(({onUpdateList, selectedCabinet}) => {
    const marketStore = useSelector((store) => store.marketStore)
    const [taxStore] = useState(
        () => new TaxStore(selectedCabinet, marketStore.credentialsOptions)
    )

    const {linkOpen, onClickLink, setLinkOpen} = useSectionInstructions()

    const checkValidate = (e) => {
        const value = e.target.value
        const regex = /^\d*\.?\d{0,2}$/
        const isValid = regex.test(value) || value === ''
        return [value, isValid]
    }
    const handleChangeTax = (e) => {
        const [value, isValid] = checkValidate(e)
        if (isValid) {
            taxStore.setTax(value)
        }
    }
    const handleChangeNds = (e) => {
        const [value, isValid] = checkValidate(e)
        if (isValid) {
            taxStore.setNds(value)
        }
    }

    const openWarningTax = ({message}) => {
        new ModalCaller({
            bodyClass: 'pb-0 mb-0',
            children: (
                <ModalConfirm
                    isModal={false}
                    title={message}
                    description={'Все равно сохранить?'}
                    onAccept={() => handleClickSave(true)}
                />
            ),
        })
    }

    const handleClickSave = async (isForce = false) => {
        try {
            const data = await taxStore.postTax()
            if (data.result) {
                await taxStore.getTax(taxStore.cabinet.key)
                await onUpdateList(data)
            }
        } catch (e) {
            if (!e.response.data.result) {
                // return openWarningTax(e.response.data)
                toast(e.response.data.message, {type: 'error'})
            }
            console.log(e)
        }
    }

    const handleDeleteTax = async (item) => {
        try {
            await taxStore.deleteTax(item.id)
            await taxStore.getTax(taxStore.cabinet.key)
            if (!taxStore.taxes.length) {
                onUpdateList({})
            } else {
                onUpdateList(taxStore.taxes[0])
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleChangeType = (item) => {
        taxStore.dict.setSelectedItem(item)
        taxStore.setTax(item.value)
    }

    const handleChangeUseNds = (value) => {
        taxStore.setUseNds(value)
        if (!value && taxStore.ndsSpecial) {
            taxStore.setNdsSpecial(false)
        }
    }

    return (
        <div className={'flex flex-col space-y-4'}>
            <Select
                placeholder={'Выберите кабинет'}
                selected={taxStore.cabinet}
                options={marketStore.credentialsOptions}
                onChange={taxStore.setCabinet}
            >
                {({option, selected, active}) => (
                    <div className={'flex items-center'}>
                        <MarketIcon
                            iconClassName={'w-4x5 h-4x5'}
                            size={20}
                            name={option.market}
                            className={'mr-3 flex-shrink-0'}
                        />
                        <span className={''}>{option.name}</span>
                    </div>
                )}
            </Select>
            <LinkUnderline
                label={'Инструкция по настройке'}
                onClick={onClickLink}
            />
            <div
                className={
                    'flex flex-col gap-2 text-gray-800 dark:text-gray-200'
                }
            >
                {/*<div className={'flex space-x-2'}>*/}
                {/*    <span>Текущая система налога:</span>*/}
                {/*    <span className={'text-gray-600 dark:text-gray-400'}>*/}
                {/*        {taxStore.currentTax.name*/}
                {/*            ? taxStore.currentTax.name*/}
                {/*            : 'Зависит от кабинета'}*/}
                {/*    </span>*/}
                {/*</div>*/}
                {/*<div className={'flex space-x-2'}>*/}
                {/*    <span>Текущая ставка налога:</span>*/}
                {/*    <span className={'text-gray-600 dark:text-gray-400'}>*/}
                {/*        {taxStore.currentTax.value ? (*/}
                {/*            <Amount*/}
                {/*                value={taxStore.currentTax.value}*/}
                {/*                currency={SIGNS_KEY.PER}*/}
                {/*            />*/}
                {/*        ) : (*/}
                {/*            'Зависит от кабинета'*/}
                {/*        )}*/}
                {/*    </span>*/}
                {/*</div>*/}
            </div>
            <div className={'grid grid-cols-2 items-center gap-2'}>
                <div className={'flex gap-4 text-gray-800 dark:text-gray-200'}>
                    <span>Система налогообложения</span>
                    <SettingsHelp
                        content={
                            'Указанная ставка при расчете применяется к сумме продаж за вычетом возвратов. Например, если у вас система налогообложения УСН-Доходы, и ставка в вашем регионе 6%, рекомендуем указать 7% (6+1% с оборота свыше 300 тыс. руб.). Для других систем налогообложения (УСН Доходы-Расходы) укажите расчетное значение'
                        }
                    />
                </div>
                <Select
                    placeholder={'Выберите тип'}
                    selected={taxStore.dict.selected}
                    options={taxStore.dict.options}
                    onChange={handleChangeType}
                />
            </div>
            {taxStore.isWb ? (
                <div className={'grid grid-cols-2 items-center gap-2'}>
                    <div
                        className={
                            'flex items-center gap-4 text-gray-800 dark:text-gray-200'
                        }
                    >
                        <span>Учитывать СПП</span>
                        <SettingsHelp
                            content={
                                'При включении опции расчет налога будет происходить от цены продажи с учетом СПП'
                            }
                        />
                    </div>
                    <Toggle
                        checked={taxStore.useSpp}
                        onChange={taxStore.setUseSpp}
                    />
                </div>
            ) : null}
            <div className={'grid grid-cols-2 items-center gap-2'}>
                <div
                    className={
                        'flex items-center gap-4 text-gray-800 dark:text-gray-200'
                    }
                >
                    <span>Ставка УСН/ННП</span>
                    {/*<SettingsHelp*/}
                    {/*    content={*/}
                    {/*        'При включении опции расчет налога будет происходить от цены продажи с учетом СПП'*/}
                    {/*    }*/}
                    {/*/>*/}
                </div>
                <div className={'flex items-center gap-4'}>
                    <TextField
                        className={'w-full'}
                        type={'number'}
                        inputClassName={'text-center py-1 px-1'}
                        value={taxStore.tax}
                        onChange={handleChangeTax}
                    />
                    <span className={'text-gray-600 dark:text-gray-400'}>
                        {SIGNS.PER}
                    </span>
                </div>
            </div>
            <div className={'grid grid-cols-2 items-center gap-2'}>
                <div
                    className={
                        'flex items-center gap-4 text-gray-800 dark:text-gray-200'
                    }
                >
                    <span>Учитывать НДС</span>
                    {/*<SettingsHelp*/}
                    {/*    content={*/}
                    {/*        'При включении опции расчет налога будет происходить от цены продажи с учетом СПП'*/}
                    {/*    }*/}
                    {/*/>*/}
                </div>
                <Toggle
                    checked={taxStore.useNds}
                    onChange={handleChangeUseNds}
                />
            </div>
            <div className={'grid grid-cols-2 items-center gap-2'}>
                <div
                    className={
                        'flex items-center gap-4 text-gray-800 dark:text-gray-200'
                    }
                >
                    <span>Особый режим НДС</span>
                    {/*<SettingsHelp*/}
                    {/*    content={*/}
                    {/*        'При включении опции расчет налога будет происходить от цены продажи с учетом СПП'*/}
                    {/*    }*/}
                    {/*/>*/}
                </div>
                <ToggleLabeled
                    disabled={!taxStore.useNds}
                    checked={taxStore.ndsSpecial}
                    onChange={taxStore.setNdsSpecial}
                />
            </div>
            <div className={'grid grid-cols-2 items-center gap-2'}>
                <div
                    className={
                        'flex items-center gap-4 text-gray-800 dark:text-gray-200'
                    }
                >
                    <span>Ставка НДС</span>
                    {/*<SettingsHelp*/}
                    {/*    content={*/}
                    {/*        'При включении опции расчет налога будет происходить от цены продажи с учетом СПП'*/}
                    {/*    }*/}
                    {/*/>*/}
                </div>
                <div className={'flex items-center gap-4'}>
                    <TextField
                        className={'w-full'}
                        type={'number'}
                        disabled={!taxStore.useNds}
                        inputClassName={'text-center py-1 px-1'}
                        value={taxStore.nds}
                        onChange={handleChangeNds}
                    />
                    <span className={'text-gray-600 dark:text-gray-400'}>
                        {SIGNS.PER}
                    </span>
                </div>
            </div>
            <div className={'block space-x-2 text-gray-800 dark:text-gray-200'}>
                <span>Выберите дату, с которой cтавка будет изменена:</span>
                <span className={'text-blue-500 dark:text-blue-300'}>
                    {format(taxStore.date, 'dd.MM.yyyy', {
                        locale: ru,
                    })}
                </span>
            </div>
            <div className={'w-1/2'}>
                <CustomCalendar
                    date={taxStore.date}
                    months={1}
                    onChange={taxStore.setDate}
                />
            </div>
            <div className={'w-1/2'}>
                <Button
                    className={'w-full'}
                    onClick={handleClickSave}
                    disabled={!taxStore.cabinet.key}
                >
                    Сохранить
                </Button>
            </div>
            <div className={'flex flex-col'}>
                <span className={'mb-4 text-gray-600 dark:text-gray-400'}>
                    История изменения ставки:
                </span>
                <TaxHistory
                    per
                    history={taxStore.taxes}
                    getTypeName={taxStore.getTypeName}
                    onDelete={handleDeleteTax}
                />
            </div>
            <TaxOverlayInstructions open={linkOpen} setOpen={setLinkOpen} />
        </div>
    )
})
