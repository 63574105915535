import {useModalContext} from '@components/overlays'
import {Button} from '@components/elements'
import {CheckIcon} from '@heroicons/react/outline'

import {observer} from 'mobx-react'
import {useSelector} from '@hooks'

export const SuccessLegalDialog = observer(({legalOptions, onApply}) => {
    const marketStore = useSelector((store) => store.marketStore)
    const {onClose} = useModalContext()
    const handleClick = () => {
        onApply()
        onClose()
    }

    return (
        <div
            className={
                'flex flex-col items-center gap-4 text-gray-800 dark:text-gray-200'
            }
        >
            <CheckIcon className={'h-10 w-10 flex-shrink-0 text-green-600'} />

            <p className={'text-center'}>Данные загружены</p>
            <p className={'text-center'}>
                {`Юр. лицо ${
                    legalOptions.legalName
                } привязано к кабинету ${marketStore.getCabinetNameById(
                    legalOptions.cabinet
                )}`}
            </p>
            <div className={'flex justify-center'}>
                <Button onClick={handleClick}>
                    <span>Продолжить</span>
                </Button>
            </div>
        </div>
    )
})

SuccessLegalDialog.displayName = 'SuccessLegalDialog'
