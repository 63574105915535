import {useEffect, useRef} from 'react'
import {useOnClickOutside} from '@hooks'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {TextField} from '@components/forms/TextFields'
import {CheckIcon, ExclamationIcon} from '@heroicons/react/outline'

const schema = yup.object().shape({
    count: yup
        .number()
        .typeError('Введите целое число')
        .positive('Введите целое число > 0')
        .integer('Введите целое число > 0'),
})

export const EditCountInput = ({
    onVisibility,
    onChange,
    count,
    customSchema,
}) => {
    const editCountInputRef = useRef(null)
    const formRef = useRef(null)

    useOnClickOutside(editCountInputRef, () => onVisibility(false))

    const {
        register,
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(customSchema ? customSchema : schema),
        defaultValues: {
            count,
        },
        mode: 'onChange',
    })

    useEffect(() => {
        setValue('count', count)
    }, [count])

    const handleClickSaveCount = () => {
        formRef.current.click()
    }

    const onSubmit = async ({count}) => {
        onChange(count)
        onVisibility(false)
    }

    return (
        <div
            ref={editCountInputRef}
            className={
                'flex items-center space-x-2 text-gray-600 dark:text-gray-400'
            }
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    {...register('count')}
                    className={'w-20'}
                    inputClassName={'text-center py-1 px-1'}
                    inputDefaultAbsence={false}
                    error={!!errors.count}
                />
                <input ref={formRef} type={'submit'} hidden />
            </form>
            {!!errors.count ? (
                <div className={'flex items-center space-x-2 text-xs'}>
                    <ExclamationIcon className='h-6 w-6 text-yellow-400' />
                    <span>{errors.count.message}</span>
                </div>
            ) : (
                <CheckIcon
                    className='h-6 w-6 cursor-pointer text-green-600 hover:opacity-80'
                    aria-hidden='true'
                    onClick={handleClickSaveCount}
                />
            )}
        </div>
    )
}
