import {TooltipLite, BaseLockedTooltipContent} from '@components/elements'
import classNames from 'classnames'

export const LockedWrapperWithoutIcon = ({
    className,
    isBlocked,
    children,
    exceedProducts,
    exceedCabinets,
    isTrial,
}) => {
    return isBlocked || isTrial || exceedProducts || exceedCabinets ? (
        <TooltipLite
            offset={[0, 10]}
            content={
                <BaseLockedTooltipContent
                    isBlocked={isBlocked}
                    isTrial={isTrial}
                    exceedCabinets={exceedCabinets}
                    exceedProducts={exceedProducts}
                />
            }
            classNameBody={'md:p-2 max-w-[250px]'}
        >
            {(ref) => (
                <div ref={ref} className={classNames(className, 'relative')}>
                    {children}
                </div>
            )}
        </TooltipLite>
    ) : (
        children
    )
}
