import {useState} from 'react'
import {ChevronDownIcon} from '@heroicons/react/solid'
import {motion, AnimatePresence} from 'framer-motion'
export const Accordion = ({triggerContent, opened, children}) => {
    const [isOpen, setIsOpen] = useState(opened)

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    const rotationTransition = {
        duration: 0.6,
    }

    return (
        <div>
            <div
                className='flex cursor-pointer items-center space-x-4'
                onClick={toggleOpen}
            >
                <span>{triggerContent}</span>
                <motion.div
                    animate={{rotate: isOpen ? 180 : 0}}
                    transition={rotationTransition}
                    className='text-blue-500'
                >
                    <ChevronDownIcon className='h-6 w-6' />
                </motion.div>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{opacity: 0, height: 0}}
                        animate={{opacity: 1, height: 'auto'}}
                        exit={{opacity: 0, height: 0}}
                        transition={{duration: 0.6}}
                        className='overflow-hidden'
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}
