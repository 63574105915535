import {observer} from 'mobx-react'
import {Button, LinkUnderline} from '@components/elements'
import {wbAuthVariant} from '../../constants'

export const WildberriesOAuthStart = observer(
    ({wbAuthStore, onCancel, onSuccess, onError, connectorData}) => {
        const handleClickToApiKey = () => {
            wbAuthStore.setAuthVariant(wbAuthVariant.apiKey)
        }
        const handleClickOAuth = () => {
            wbAuthStore.startWbAuth(connectorData?.id || undefined)
        }
        return (
            <div
                className={
                    'flex w-full flex-col gap-4 text-gray-800 dark:text-gray-200'
                }
            >
                <div className={'flex gap-4'}>
                    <Button className={'w-full'} onClick={handleClickOAuth}>
                        Авторизоваться
                    </Button>
                    <Button
                        className={'w-full'}
                        color={'white'}
                        onClick={onCancel}
                    >
                        Отменить
                    </Button>
                </div>
                {!connectorData && (
                    <div className={'flex flex-col items-center gap-4'}>
                        <p className={'text-center'}>
                            Упрощенная авторизация доступна только для
                            владельцев магазина.
                        </p>
                        <div className={'flex flex-col items-center gap-2'}>
                            <p className={''}>
                                Если вы менеджер или сотрудник компании:
                            </p>
                            <LinkUnderline
                                onClick={handleClickToApiKey}
                                className={'text-center'}
                                label={
                                    'Авторизоваться через личный кабинет Wildberries'
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    }
)

WildberriesOAuthStart.displayName = 'WildberriesOAuthForm'
