import {updateCancelToken} from '@services/apiService'
import {baseConfig} from '@services/apiConfigs'
import {BillingUrl, ProductsUrl} from '@routesEnums'
import {ApiFactory} from '@services/ApiFactory'

const productsInstance = ApiFactory.createApi(baseConfig)

class ProductsService {
    static getProducts = async (payload) => {
        return await productsInstance.post(ProductsUrl.products, payload)
    }

    static getProduct = async (id) => {
        return await productsInstance.get(ProductsUrl.productId(id))
    }

    static getPackages = async (id) => {
        return await productsInstance.get(ProductsUrl.productPackagesById(id))
    }

    static putPackages = async (id, payload) => {
        return await productsInstance.put(
            ProductsUrl.productPackagesById(id),
            payload
        )
    }

    static getProductPrices = async (id) => {
        return await productsInstance.get(ProductsUrl.productPricesById(id))
    }

    static editProductPrices = async (id, body) => {
        return await productsInstance.put(
            ProductsUrl.productPricesById(id),
            body
        )
    }

    static getProductVat = async (id) => {
        return await productsInstance.get(ProductsUrl.productVatById(id))
    }

    static editProductVat = async (id, body) => {
        return await productsInstance.put(ProductsUrl.productVatById(id), body)
    }

    static getProductAttributes = async (id) => {
        return await productsInstance.get(ProductsUrl.productAttributesById(id))
    }

    static editProductAttributes = async (id, body) => {
        return await productsInstance.put(
            ProductsUrl.productAttributesById(id),
            body
        )
    }

    static getDictionary = async (body, source) => {
        const newConfig = {
            cancelToken: updateCancelToken(source),
        }
        return await productsInstance.post(
            ProductsUrl.dictionary,
            body,
            newConfig
        )
    }

    static getMasterDictionary = async (body) => {
        return await productsInstance.post(ProductsUrl.masterDictionary, body)
    }

    static putExportProducts = async (body) => {
        return await productsInstance.put(ProductsUrl.exportProducts, body)
    }
}

export default ProductsService
