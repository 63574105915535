import cn from 'classnames'
import {Badge, DropdownItem} from '@components/elements'
import {NavLink, useLocation, useRouteMatch} from 'react-router-dom'

import {observer} from 'mobx-react'

export const TabsMobileItem = observer(({changeActiveTab, tab}) => {
    const {visible, disabled, id, name, count, warn} = tab
    const match = useRouteMatch()
    const location = useLocation()

    if (visible !== undefined && visible === false) return null
    return (
        <DropdownItem isClosed onClick={() => {}}>
            <NavLink
                className={cn(
                    'group -mx-1 inline-flex items-center px-1 text-lg font-medium text-gray-500 hover:text-gray-400',
                    {
                        'pointer-events-none text-gray-400': disabled,
                    }
                )}
                activeClassName={cn('text-gray-800 dark:text-gray-200')}
                to={{
                    pathname: `${match.url}/${id}/`,
                    search: location.search,
                }}
                onClick={() => changeActiveTab(name)}
            >
                {name}
                {!!count && (
                    <Badge
                        className={'ml-3'}
                        rounded
                        bright
                        color={warn ? 'red' : 'gray'}
                    >
                        {count}
                    </Badge>
                )}
            </NavLink>
        </DropdownItem>
    )
})
