import {forwardRef} from 'react'
import * as PropTypes from 'prop-types'
import cn from 'classnames'
import './Button.scss'

export const Button = forwardRef(
    (
        {
            className = '',
            color = 'primary',
            size = 'base',
            children,
            onClick,
            startIcon,
            endIcon,
            rounded,
            type = 'button',
            active,
            disabled,
            ...rest
        },
        ref
    ) => {
        return (
            <button
                {...rest}
                ref={ref}
                type={type}
                disabled={disabled}
                className={cn(
                    className,
                    'relative inline-flex items-center justify-center border font-medium shadow-sm transition-all focus:outline-none disabled:opacity-50',
                    size === 'sm' && 'px-2.5 py-1.5 text-xs',
                    size === 'md' && 'px-3 py-2 text-sm font-medium leading-4',
                    size === 'base' && 'px-4 py-3 text-sm font-medium',
                    size === 'lg' && 'px-4 py-3 text-base font-medium',
                    size === 'xl' && 'px-6 py-3 text-base font-medium',
                    color === 'primary' &&
                        `border-transparent bg-blue-500 text-white dark:bg-blue-400 ${
                            !disabled
                                ? 'hover:bg-blue-500 dark:hover:bg-blue-300'
                                : ''
                        }`,
                    color === 'secondary' &&
                        `border-transparent bg-blue-100 text-blue-500 ${
                            !disabled ? 'hover:bg-blue-200' : ''
                        }`,
                    color === 'white' &&
                        `border-gray-300 bg-white text-gray-700 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 ${
                            !disabled
                                ? 'hover:bg-gray-50 dark:hover:bg-gray-600'
                                : ''
                        }`,
                    active ? 'z-10' : 'z-0',
                    {
                        'rounded-full': rounded === true,
                        'rounded-md': !rounded && size !== 'sm',
                        rounded: !rounded && size === 'sm',
                        'active-primary': active && color === 'primary',
                        'active-white': active && color === 'white',
                    }
                )}
                onClick={onClick}
            >
                {startIcon && (
                    <div className='-ml-0.5 mr-2 h-4 w-4'>{startIcon}</div>
                )}
                {children}
                {endIcon && (
                    <div className='-mr-1 ml-2 h-5 w-5' aria-hidden='true'>
                        {endIcon}
                    </div>
                )}
            </button>
        )
    }
)

Button.displayName = 'Button'

Button.propTypes = {
    className: PropTypes.string,
    rounded: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'secondary', 'white']),
    size: PropTypes.oneOf(['sm', 'md', 'base', 'lg', 'xl']),
    onClick: PropTypes.func,
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
}
