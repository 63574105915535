import {useEffect, useState} from 'react'
import {SearchIcon} from '@heroicons/react/outline'
import {useDebouncedFunction} from '@hooks'

export const SelectSearch = ({value, onChange}) => {
    const [isStop, setIsStop] = useState(false)

    const [currentValue, setCurrentValue] = useState(value)
    useEffect(() => {
        return () => {
            setIsStop(true)
        }
    }, [])
    const setValueDebounce = (e) => {
        onChange(e)
    }

    const setValuePending = useDebouncedFunction(setValueDebounce, 500, isStop)

    const handleChangeValue = (e) => {
        setValuePending(e)
        setCurrentValue(e.target.value)
    }
    return (
        <div className={'sticky top-0 z-[100] bg-white dark:bg-gray-700'}>
            <div className={'relative inset-0 mb-1'}>
                <SearchIcon
                    className={
                        'absolute bottom-0 left-0 top-0 m-auto ml-4 h-5 w-5 text-gray-500 dark:text-gray-400'
                    }
                />
                <input
                    className={
                        'w-full border-b border-gray-300 bg-transparent py-2 pl-12 outline-none focus:outline-none dark:border-gray-600 dark:text-gray-200'
                    }
                    placeholder={'Поиск...'}
                    value={currentValue}
                    onChange={handleChangeValue}
                />
            </div>
        </div>
    )
}
