import {observer} from 'mobx-react'
import {Select} from '@components/forms/Selects'
import {MarketIcon} from '@components/elements'

import {TextField} from '@components/forms/TextFields'

export const ImportWbFilters = observer(({filtersStore}) => {
    const handleChangeLegalEntity = (e) => {
        filtersStore.setLegalEntity(e.target.value)
    }
    return (
        <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
            <Select
                label={'Кабинет'}
                selected={filtersStore.cabinet.selected}
                options={filtersStore.cabinet.options}
                onChange={filtersStore.cabinet.setSelectedItem}
            >
                {({option, selected, active}) => (
                    <div className={'flex items-center'}>
                        <MarketIcon
                            iconClassName={'w-4x5 h-4x5 '}
                            size={20}
                            name={option.market}
                            className={'mr-3 flex-shrink-0'}
                        />
                        <span className={'truncate'}>{option.name}</span>
                    </div>
                )}
            </Select>
            <TextField
                label={'Юр.лицо'}
                disabled={filtersStore.validLegalEntity}
                error={filtersStore.isLegalEntityError}
                helperText={
                    filtersStore.isLegalEntityError && 'Заполните Юр.лицо'
                }
                value={
                    filtersStore.validLegalEntity
                        ? filtersStore.savedLegalEntity
                        : filtersStore.legalEntity
                }
                onChange={handleChangeLegalEntity}
            />
        </div>
    )
})

ImportWbFilters.displayName = 'ImportWbFilters'
