import {Card} from '@components/layout'
import {ShieldCheckIcon} from '@heroicons/react/outline'
import {Ndash} from '@components/elements'
import {SettingsHelp} from '@components/applicationUi'
import {PARTNERSHIP_TOOLTIP} from '@views/marketplaces/pages/Marketplaces/constants'

export const AuthorizedPartnership = () => {
    return (
        <Card>
            <div className={'flex items-center gap-4'}>
                <ShieldCheckIcon
                    className={
                        'h-8 w-8 flex-shrink-0 text-gray-800 dark:text-gray-200'
                    }
                />
                <span className={'text-gray-800 dark:text-gray-200'}>
                    <span>Seller24</span>
                    <Ndash />
                    <span className={'mr-1 inline-flex space-x-1'}>
                        <span>авторизованный сервис Wildberries</span>
                        <SettingsHelp
                            content={PARTNERSHIP_TOOLTIP.wildberries}
                        />
                        ,
                    </span>
                    <span className={'mr-1 inline-flex space-x-1'}>
                        <span>золотой техпартнер Ozon</span>
                        <SettingsHelp content={PARTNERSHIP_TOOLTIP.ozon} />,
                    </span>
                    <span className={'inline-flex space-x-1'}>
                        <span>технологический партнер Яндекс. Маркет</span>
                        <SettingsHelp content={PARTNERSHIP_TOOLTIP.yandex} />
                    </span>
                </span>
            </div>
        </Card>
    )
}
