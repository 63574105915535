import {observer} from 'mobx-react'
import {useExpanded, usePagination, useTable} from 'react-table'
import {useEffect, useMemo} from 'react'
import {useImportWbTableColumns} from '@views/marketplaces/pages/ImportWb/hooks/useImportWbTableColumns'
import classNames from 'classnames'
import {LoaderFull} from '@components/elements/Loader'
import {EmptyTableResult} from '@components/applicationUi/EmptyTableResult'
import {ProductIcon} from '@components/elements/Icon'
import {Table} from '@components/applicationUi'
import {TablePagination} from '@components/navigation'
import {useBlocked} from '@hooks/customHooks/useBlocked'

export const ImportWbTable = observer(({importWbStore}) => {
    const isBlocked = useBlocked()
    const {total, totalPages, limit} = importWbStore.paginationStore.pagination

    const columns = useImportWbTableColumns()

    const data = useMemo(
        () => [...importWbStore.importWbList],
        [importWbStore.importWbList]
    )

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                total,
                pageSize: limit,
            },
            manualPagination: true,
            pageCount: totalPages,
            useControlledState: (state) =>
                useMemo(
                    () => ({
                        ...state,
                        total,
                    }),
                    [state, total]
                ),
        },
        useExpanded,
        usePagination
    )

    useEffect(() => {
        importWbStore.importWbListPolling.startPolling({
            page: tableInstance.state.pageIndex + 1,
            limit: tableInstance.state.pageSize,
        })
    }, [
        tableInstance.state.pageIndex,
        tableInstance.state.pageSize,
        importWbStore.importWbListPolling.startPolling,
    ])
    return (
        <div
            className={classNames('relative flex flex-col', {
                'h-[calc(100vh-60px)]': importWbStore.importWbList.length,
            })}
        >
            {importWbStore.importWbLoading.loading && <LoaderFull absolute />}
            {!importWbStore.importWbList.length &&
            !importWbStore.importWbLoading.loading ? (
                <EmptyTableResult
                    icon={<ProductIcon className={'h-48 w-48'} />}
                />
            ) : (
                <Table
                    isBlocked={isBlocked}
                    className={'h-full min-h-[500px] w-full'}
                    tableInstance={tableInstance}
                />
            )}
            <TablePagination
                isBlocked={isBlocked}
                className={'sticky bottom-0'}
                isLimit
                tableInstance={tableInstance}
            />
        </div>
    )
})

ImportWbTable.displayName = 'ImportWbTable'
