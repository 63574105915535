import axiosLib from 'axios'
import {TokenService} from '@services/TokenService'
import {
    getBrowser,
    getStandName,
    getUserEnvBrowser,
    keysToCamel,
} from '@utils/helpers'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import {errors} from '@utils/constants'
import {rootStore} from '@'

class Api {
    service
    baseUrl
    awaitingPromise = null
    constructor(config) {
        let service = axiosLib.create(config)
        this.baseUrl = config.baseURL
        service.interceptors.request.use(
            (config) => {
                const token = TokenService.getToken()
                if (config.isAuth === false) {
                    return config
                }
                if (!config.headers.Authorization && token) {
                    config.headers.Authorization = `Bearer ${token}`
                }
                config.headers['x-browser-version'] = getBrowser()
                config.headers['X-User-Env'] = getUserEnvBrowser()
                return config
            },
            (error) => Promise.reject(error)
        )
        service.interceptors.response.use(this.handleSuccess, this.handleError)
        this.service = service
    }

    async get(url, query, config) {
        const newConfig = {
            params: query,
            ...config,
        }
        const {data, headers} = await this.service.get(url, newConfig)
        return {data: keysToCamel(data), headers}
    }

    async get_raw(url, query, config) {
        const newConfig = {
            params: query,
            ...config,
        }
        const {data, headers} = await this.service.get(url, newConfig)
        return {data: data, headers}
    }

    async post(url, body, config) {
        const {data, headers} = await this.service.post(url, body, config)
        return {data: keysToCamel(data), headers}
    }

    async getFile(url, body, config) {
        const newConfig = {
            responseType: 'blob',
            ...config,
        }
        const {data, headers} = await this.service.post(url, body, newConfig)
        return {data, headers}
    }

    async getFileWithGet(url, query, config) {
        const newConfig = {
            responseType: 'blob',
            ...config,
        }
        const {data, headers} = await this.get_raw(url, query, newConfig)
        return {data, headers}
    }

    async postFile(url, file, config, callBack, params = {}) {
        const newConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            ...this._getProgress(callBack),
            ...config,
        }
        const fileData = new FormData()
        if (Array.isArray(file.file)) {
            file.file.forEach((item, index) => {
                fileData.append(`${file.name}`, item)
            })
        } else {
            fileData.append(file.name ? file.name : 'file', file.file)
        }

        const {data, headers} = await this.service.post(
            url,
            fileData,
            newConfig
        )
        return {data: keysToCamel(data), headers}
    }

    async put(url, body, config) {
        const {data, headers} = await this.service.put(url, body, config)
        return {data: keysToCamel(data), headers}
    }

    async patch(url, body, config) {
        const {data, headers} = await this.service.patch(url, body, config)
        return {data: keysToCamel(data), headers}
    }

    async delete(url, body, config) {
        const {data, headers} = await this.service.delete(url, {
            ...config,
            data: body,
        })
        return {data: keysToCamel(data), headers}
    }

    handleSuccess = (response) => {
        return response
    }

    handleError = async (error) => {
        const {response, config} = error
        if (axiosLib.isCancel(error)) return Promise.reject(errors.cancelToken)

        if (response) {
            const {status} = response

            if (status === 401) return this.onRefreshToken(config)
            if (status === 500) return this.onServerErrorRetry(config)
        }
        this.handleUnexpectedError(error)
        return Promise.reject(error)
    }

    async onServerErrorRetry(config) {
        if (!config.__retryAttempt) {
            config.__retryAttempt = true
            try {
                return await this.service(config)
            } catch (retryError) {
                this.handle500Error(retryError)
                return Promise.reject(retryError)
            }
        }
    }

    async onRefreshToken(config) {
        if (config.skipAuthRefresh) return Promise.reject(config)
        if (this.awaitingPromise) {
            await this.awaitingPromise
            const token = TokenService.getToken()
            config.headers.Authorization = `Bearer ${token}`
            return this.service(config)
        }

        this.awaitingPromise = this.refreshToken()
        await this.awaitingPromise
        const token = TokenService.getToken()
        config.headers.Authorization = `Bearer ${token}`
        const retryResponse = await this.service(config)
        this.awaitingPromise = null
        return retryResponse
    }

    async refreshToken() {
        if (!TokenService.isRefreshedTokenExists()) {
            throw new Error('Токен отсутствует')
        }
        await rootStore.authStore.sendRefreshToken()
    }

    handle500Error(error) {
        const errorText = getErrorText(error)
        if (getStandName() === 'prod') {
            rootStore.errorStore.sendErrorGTM(errorText, error.response)
            sendErrorToGTM(errorText)
        }
    }

    handleUnexpectedError(error) {
        if (!error.response || ![401, 500].includes(error.response.status)) {
            sendErrorToGTM(getErrorText(error))
        }
    }

    _getProgress(callback) {
        return {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                )
                callback && callback(percentCompleted)
            },
        }
    }
}

const getErrorText = (error) => {
    const url = error.response?.config?.url
    const baseURL = error.response?.config?.baseURL
    const status =
        error.response && error.response.status ? error.response.status : ''
    return `Запрос ${baseURL}${url} вернул ошибку ${status}. Пожалуйста, обратитесь в поддержку на info@seller24.ru.`
}

const sendErrorToGTM = (errorText) => {
    if (TokenService.isEmulationExists()) return
    GoogleTagManager.dataLayer({
        event: 'mainEvent',
        eventCategory: 'Message - Server',
        eventAction: 'Message',
        eventLabel: errorText,
        email: rootStore.userStore?.userEmail || 'none',
        user_id: rootStore.userStore?.userData?.user_id || 'none',
    })
}

export function getCancelTokenSource() {
    return axiosLib.CancelToken.source()
}

export function updateCancelToken(data) {
    if (data === undefined || data === null) return {}
    if (data.source)
        data.source.cancel('Operation canceled due to new request.')
    data.source = getCancelTokenSource()
    return data.source.token
}

export {Api}
