//libs
import {useCallback, useEffect} from 'react'
import {observer} from 'mobx-react'

//components
import {Button} from '@components/elements'
import {TextField} from '@components/forms/TextFields'
import {toJS} from 'mobx'

export const SberbankConnector = observer((props) => {
    return <></>
    // const {
    //     renderWarningConnection,
    //     validateCredentials,
    //     changeInstanceName,
    //     store,
    //     onLogin,
    //     credential,
    //     onClose,
    //     placeKey,
    //     status,
    //     market
    // } = props

    // useEffect(() => {
    //     store.setCurrentState(credential)
    //     return () => {
    //         store.resetStates()
    //     }
    // }, [])

    // const handleClickStart = () => {
    //     store.requestLinks()
    // }

    // const handleClickConnected = () => {
    //     store.submitAuth(placeKey, () => {
    //         onLogin(onClose)
    //     })
    // }

    // const handleClickEdit = () => {
    //     const fields = toJS(store.credFields)
    //     validateCredentials(fields, handleClickConnected)
    // }

    // const handleChangeInstanceName = useCallback((value) => {
    //     changeInstanceName(
    //         value,
    //         store.setCredFields.bind(this, 'instanceName')
    //     )
    // }, [])

    // const handleChangeValue = useCallback((key, value) => {
    //     store.setCredFields(key, value)
    // }, [])

    // const renderButton = useCallback(() => {
    //     if (status === 'edit') {
    //         return (
    //             <Button
    //                 className={'modal__btn w-full'}
    //                 onClick={handleClickEdit}
    //             >
    //                 <Trans i18nKey={'common.actions.auth.edit'} />
    //             </Button>
    //         )
    //     }

    //     if (!store.isLinkEmpty) {
    //         return (
    //             <Button
    //                 className={'modal__btn w-full'}
    //                 onClick={() =>
    //                     validateCredentials(
    //                         store.credFields,
    //                         handleClickConnected
    //                     )
    //                 }
    //             >
    //                 <Trans i18nKey={'common.actions.auth.connected'} />
    //             </Button>
    //         )
    //     }

    //     return (
    //         <Button
    //             className={'modal__btn w-full'}
    //             disabled={store.isFieldEmpty}
    //             onClick={() =>
    //                 validateCredentials(store.credFields, handleClickStart)
    //             }
    //         >
    //             <Trans i18nKey={'markets.auth.sberbank.actions.next'} />
    //         </Button>
    //     )
    // }, [store.credFields.token.value])

    // const getInputTip = (fieldKey) => {
    //     if (fieldKey === 'instanceName')
    //         return <Trans i18nKey={`markets.${fieldKey}.tip`} />

    //     return (
    //         <span>
    //             <Trans i18nKey={`markets.sberbank.${fieldKey}.tip`}>
    //                 <a
    //                     className={'cursor-pointer text-ui-blue'}
    //                     href={
    //                         'https://partner.sbermegamarket.ru/main/account/api-integration'
    //                     }
    //                     rel={'noopener noreferrer'}
    //                     target={'_blank'}
    //                 >
    //                     _
    //                 </a>
    //             </Trans>
    //         </span>
    //     )
    // }

    // return (
    //     <div className={'acc_mt-place__auth flex flex-col'}>
    //         <div className={'flex flex-col mx-6'}>
    //             {Object.keys(store.credFields).map((fieldKey) => (
    //                 <TextField
    //                     key={fieldKey}
    //                     className={'mb-6'}
    //                     label={'Поле'}
    //                     labelInside
    //                     value={store.credFields[fieldKey].value}
    //                     required={store.credFields[fieldKey].required}
    //                     disabled={store.credFields[fieldKey].disabled}
    //                     tip={
    //                         store.credFields[fieldKey].tip &&
    //                         !store.credFields[fieldKey].disabled &&
    //                         getInputTip(fieldKey)
    //                     }
    //                     onChange={
    //                         fieldKey === 'instanceName'
    //                             ? handleChangeInstanceName
    //                             : handleChangeValue.bind(this, fieldKey)
    //                     }
    //                 />
    //             ))}
    //         </div>
    //         {!store.isLinkEmpty && (
    //             <div className={'flex flex-col px-6'}>
    //                 <div className={'flex items-center mb-4'}>
    //                     <SectionTitle
    //                         level={3}
    //                         i18nKey={'markets.auth.sberbank.title'}
    //                     />
    //                 </div>
    //                 <span className={'mb-4'}>
    //                     <Trans
    //                         i18nKey={`markets.auth.sberbank.info`}
    //                         values={{ market }}
    //                     />
    //                 </span>
    //             </div>
    //         )}
    //         {renderWarningConnection()}
    //         <div className={'flex flex-col px-6'}>{renderButton()}</div>
    //     </div>
    // )
})

SberbankConnector.displayName = 'SberbankConnector'
