import {TooltipLite} from '@components/elements'

import classNames from 'classnames'

export const WithTooltipWrapper = ({children, tooltip, className}) => {
    if (tooltip) {
        return (
            <TooltipLite
                offset={[0, 10]}
                content={tooltip}
                classNameBody={'md:p-2 max-w-[350px]'}
            >
                {(ref) => (
                    <span
                        ref={ref}
                        className={classNames(className, 'cursor-pointer')}
                    >
                        {children}
                    </span>
                )}
            </TooltipLite>
        )
    }
    return children
}
