import {useMemo, useState} from 'react'
import {observer} from 'mobx-react'
import ReactInfiniteScroll from 'react-infinite-scroll-component' //https://www.npmjs.com/package/react-infinite-scroll-component
import {getRandomNumber} from '@utils/helpers'
import {Loader} from '@components/elements/Loader'

export const InfiniteScroll = observer(
    ({
        items = [],
        loadMessage = 'Загрузка данных...',
        className = '',
        innerClassName = '',
        total = 0,
        scrollThreshold = 0.8,
        initialScroll = 0,
        onLoadMore = () => false,
        renderItem = () => false,
        onScroll = () => {},
        emptyListImage = '',
        emptyListMessage = 'Список пуст',
        endMessage = '',
    }) => {
        const [uid] = useState(String(getRandomNumber()))

        const hasMore = useMemo(() => {
            return total - items.length > 0
        }, [total, items])

        const loader = () => (
            <div
                className={'flex w-full items-center justify-center px-4 py-4'}
            >
                <Loader sm overlayed={false} className={'mr-4'} />
                <span>{loadMessage}</span>
            </div>
        )

        if (!items.length) {
            return (
                <div
                    className={
                        'flex h-full w-full flex-col items-center justify-center py-8'
                    }
                >
                    {emptyListImage && (
                        <img className={'mb-4'} src={emptyListImage} alt={''} />
                    )}
                    <p className={'text-2xl text-gray-600 dark:text-gray-400'}>
                        {emptyListMessage}
                    </p>
                </div>
            )
        }

        return (
            <div id={uid} className={`relative overflow-y-auto ${className}`}>
                <ReactInfiniteScroll
                    className={innerClassName}
                    scrollableTarget={uid}
                    dataLength={total}
                    next={onLoadMore}
                    hasMore={hasMore}
                    loader={loader()}
                    endMessage={endMessage}
                    scrollThreshold={scrollThreshold}
                    onScroll={onScroll}
                    initialScrollY={initialScroll}
                >
                    {items.map(renderItem)}
                </ReactInfiniteScroll>
            </div>
        )
    }
)

InfiniteScroll.displayName = 'InfiniteScroll'
