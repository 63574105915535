import {Overlay} from '@components/overlays'
import styles from './Document.module.css'

import policy from '@assets/documents/policy.html?raw'

const DOCUMENTS = {
    policy: policy,
}

const Document = (props) => {
    const {documentName = '', open, setOpen} = props
    return (
        <Overlay setOpen={setOpen} open={open} size={'xl'}>
            <div
                className={styles.document}
                dangerouslySetInnerHTML={{
                    __html: DOCUMENTS[documentName.toLowerCase()],
                }}
            />
        </Overlay>
    )
}

export {Document}
