import {observer} from 'mobx-react'
import {useState} from 'react'
import {Button, SectionInstructions} from '@components/elements'
import {SearchTextField} from '@components/forms/TextFields'
import {Modal, Overlay} from '@components/overlays'
import {ImportWbReculcDataModal} from '../ImportWbReculcDataModal'
import {ModalControl} from '@store/ModalControl'
import {SettingsHelp} from '@components/applicationUi'
import {useSectionInstructions} from '@hooks/customHooks/useSectionInstructions'
import {ImportWbInfoLink} from './ImportWbInfoLink'
import {useSelector} from '@hooks'

export const ImportWbFilters = observer(
    ({
        filterStore,
        importWbOverlayStore,
        impostWbStore,
        importWbStatusesStore,
    }) => {
        const {onClickLink, linkOpen, setLinkOpen} = useSectionInstructions()
        const {isDarkTheme} = useSelector((store) => store.themeStore)
        const [recalcModalControl] = useState(() => new ModalControl())
        const handleChangeSearch = (e) => {
            filterStore.setImportWbSearch(e.target.value)
        }

        const handleClickRecalc = () => {
            recalcModalControl.open()
        }

        const handleApply = async (startDate, endDate) => {
            try {
                await filterStore.postRecalc(startDate, endDate)
                recalcModalControl.close()
                importWbStatusesStore.importStatusesRecalcPolling.startPolling()
                impostWbStore.importWbListPolling.startPolling()
                if (filterStore.marketStore.importStatusesPolling.isPooling) {
                    filterStore.marketStore.importStatusesPolling.stopPolling()
                }
                filterStore.marketStore.importStatusesPolling.startPolling()
            } catch (e) {
                console.log(e)
            }
        }

        return (
            <>
                <div
                    className={
                        'flex flex-col space-x-0 space-y-4 xl:flex-row xl:justify-between xl:space-x-4 xl:space-y-0'
                    }
                >
                    <SectionInstructions onClick={onClickLink} />
                    <div
                        className={
                            'flex flex-col space-x-0 space-y-4 xl:w-1/2 xl:flex-row xl:space-x-4 xl:space-y-0'
                        }
                    >
                        <div className={'flex w-full items-center space-x-4'}>
                            <span
                                className={
                                    'flex-shrink-0 text-gray-800 dark:text-gray-100'
                                }
                            >
                                Поиск по номеру отчета
                            </span>
                            <SearchTextField
                                className={'w-full'}
                                placeholder={'Поиск'}
                                // disabled={!impostWbStore.importWbList.length}
                                value={filterStore.importWbSearch}
                                onChange={handleChangeSearch}
                            />
                        </div>
                    </div>
                    <div className={'flex gap-4 xl:w-1/2'}>
                        <Button
                            className={'w-full'}
                            onClick={importWbOverlayStore.overlayControl.open}
                        >
                            Загрузить фин. отчеты Excel
                        </Button>
                        <div className={'flex w-full items-center gap-4'}>
                            <Button
                                className={'w-full'}
                                onClick={handleClickRecalc}
                                disabled={filterStore.isRecalcDisabled}
                            >
                                Запустить пересчет данных
                            </Button>
                            <SettingsHelp
                                content={
                                    'Опция активна только если вы изменили значение опции "Приоритет источника данных"'
                                }
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    className={'sm:max-w-lg'}
                    open={recalcModalControl.isOpen}
                    onClose={recalcModalControl.close}
                >
                    <ImportWbReculcDataModal
                        onClose={recalcModalControl.close}
                        onApply={handleApply}
                    />
                </Modal>
                <Overlay
                    open={linkOpen}
                    setOpen={setLinkOpen}
                    title={'Инструкция для "Импорт отчетов WB"'}
                    size={'lg'}
                >
                    <ImportWbInfoLink isDark={isDarkTheme} />
                </Overlay>
            </>
        )
    }
)

ImportWbFilters.displayName = 'ImportWbFilters'
