import {NotificationsContext} from './NotificationsContext'
import {NotificationsStore} from '../store/NotificationsStore'
import {useState} from 'react'
import {Overlay} from '@components/overlays'
import {Notifications} from '../components/Notifications'
import {observer} from 'mobx-react'

export const NotificationsProvider = observer(({children}) => {
    const [notificationsStore] = useState(() => new NotificationsStore())
    return (
        <NotificationsContext.Provider value={notificationsStore}>
            {children}
            <Overlay
                open={notificationsStore.notifyControl.isOpen}
                onClose={notificationsStore.notifyControl.close}
                size={'lg'}
                title={'Уведомления'}
            >
                <Notifications />
            </Overlay>
        </NotificationsContext.Provider>
    )
})

NotificationsProvider.displayName = 'NotificationsProvider'
