import {useMemo, useState} from 'react'
import {observer} from 'mobx-react'
import {FillingCredsModal} from '@components/navigation/header/FillingCredsModal'
import {ModalConfirmEmail} from '@components/applicationUi/ModalConfirmEmail'
import {Button} from '@components/elements'

export const ConfirmData = observer(({userData}) => {
    const [openModalFillingCreds, setOpenModalFillingCreds] = useState(false)
    const [openModalConfirmEmail, setOpenModalConfirmEmail] = useState(false)

    const handleClick = () => {
        if (!userData.email || !userData.phone_number) {
            return setOpenModalFillingCreds(true)
        }
        if (!userData.is_email_confirmed && userData.email) {
            return setOpenModalConfirmEmail(true)
        }
    }

    const label = useMemo(() => {
        let label = 'Пожалуйста, подтвердите '
        if (!userData.is_phone_confirmed && userData.is_email_confirmed) {
            label += 'свой номер телефона'
        } else if (
            userData.is_phone_confirmed &&
            !userData.is_email_confirmed
        ) {
            label += 'свою почту'
        } else if (
            !userData.is_phone_confirmed &&
            !userData.is_email_confirmed
        ) {
            label += 'свой номер телефона и почту'
        }
        return label
    }, [userData.is_phone_confirmed, userData.is_email_confirmed])
    return (
        <>
            <div className='flex flex-wrap items-center gap-x-4 gap-y-2'>
                <p className='text-sm leading-6 text-gray-900'>{label}</p>
                <Button
                    className='px-3.5 py-1 text-sm font-semibold shadow-sm'
                    onClick={handleClick}
                >
                    Подтвердить
                </Button>
            </div>
            <FillingCredsModal
                open={openModalFillingCreds}
                onClose={() => setOpenModalFillingCreds(false)}
            />
            <ModalConfirmEmail
                email={userData.email}
                open={openModalConfirmEmail}
                onClose={() => setOpenModalConfirmEmail(false)}
            />
        </>
    )
})

ConfirmData.displayName = 'ConfirmData'
