import {lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {AppPath} from '@routesEnums'
import SidebarLayout from '@views/layouts/SidebarLayout'
import {TokenService} from '@services/TokenService'
import {AccessControlService} from '@utils/access/AccessControlService'
import Promotion from '@views/promotion/Promotion'
import Integrations from '@views/Integrations'
import BlockedAccessContent from '@views/blocked'
import OAuthCallback from '@views/oAuthCallback'
import Marketplaces from '@views/marketplaces'
import PageNotFound from '@views/pageNotFound'
import {rootStore} from './index.jsx'
import {ToastContainerWrapper} from '@components/layout/ToastContainerWrapper'
import Confirm from '@views/confirm'
import AuthNewLayouts from '@views/layouts/AuthNewLayouts'
export const ROUTES = [
    {
        path: AppPath.account,
        component: lazy(
            () => import(/* webpackChunkName: "account" */ './views/account')
        ),
        protected: true,
    },
    {
        path: AppPath.promotion,
        component: Promotion,
        protected: true,
    },
    {
        path: AppPath.integrations,
        component: Integrations,
        protected: true,
    },
    {
        path: AppPath.oauth,
        component: OAuthCallback,
        protected: true,
        withoutLayout: true,
    },
    {
        path: AppPath.confirm,
        component: Confirm,
        withoutLayout: true,
    },
    {
        path: AppPath.quiz,
        component: lazy(
            () => import(/* webpackChunkName: "quiz" */ './views/quiz')
        ),
        withoutLayout: true,
    },
    {
        path: AppPath.mobile,
        component: lazy(
            () =>
                import(
                    /* webpackChunkName: "mobilePage" */ './views/mobilePage'
                )
        ),
        withoutLayout: true,
    },
    {
        path: AppPath.billing,
        component: lazy(
            () => import(/* webpackChunkName: "billing" */ './views/billing')
        ),
        protected: true,
    },
    {
        path: AppPath.dashboard,
        component: lazy(
            () =>
                import(/* webpackChunkName: "dashboard" */ './views/dashboard')
        ),
        protected: true,
    },
    {
        path: AppPath.productAnalytics,
        component: lazy(
            () =>
                import(
                    /* webpackChunkName: "productAnalytics" */ './views/productAnalytics'
                )
        ),
        protected: true,
    },
    {
        path: AppPath.deliveriesRegions,
        component: lazy(
            () =>
                import(
                    /* webpackChunkName: "deliveriesRegions" */ './views/deliveriesRegions'
                )
        ),
        protected: true,
    },
    {
        path: AppPath.profit,
        component: lazy(
            () => import(/* webpackChunkName: "profit" */ './views/profit')
        ),
        dontBlocked: true,
        protected: true,
    },
    // {
    //     path: AppPath.monitoring,
    //     component: lazy(() =>
    //         import(/* webpackChunkName: "monitoring" */ './views/monitoring')
    //     ),
    //     protected: true,
    // },

    /**Отключен*/
    {
        path: AppPath.notificationTelegram,
        component: lazy(
            () => import(/* webpackChunkName: "telegram" */ './views/telegram')
        ),
        protected: true,
    },
    {
        path: AppPath.marketplaces,
        component: Marketplaces,
        protected: true,
    },
    {
        path: AppPath.products,
        component: lazy(
            () => import(/* webpackChunkName: "products" */ './views/products')
        ),
        protected: true,
    },
    {
        path: AppPath.productsAdmin,
        component: lazy(
            () =>
                import(
                    /* webpackChunkName: "productsAdmin" */ './views/productsAdmin'
                )
        ),
        protected: true,
    },
    {
        path: AppPath.product,
        component: lazy(
            () => import(/* webpackChunkName: "product" */ './views/product')
        ),
        protected: true,
    },
    {
        path: AppPath.catalog,
        component: lazy(
            () => import(/* webpackChunkName: "catalog" */ './views/catalog')
        ),
        protected: true,
    },
    {
        path: AppPath.catalogCard,
        component: lazy(
            () =>
                import(
                    /* webpackChunkName: "catalogCard" */ './views/catalogCard'
                )
        ),
        protected: true,
    },
    {
        path: AppPath.orders,
        component: lazy(
            () => import(/* webpackChunkName: "orders" */ './views/orders')
        ),
        protected: true,
    },
    {
        path: AppPath.order,
        component: lazy(
            () => import(/* webpackChunkName: "order" */ './views/order')
        ),
        protected: true,
    },
    {
        path: AppPath.reviews,
        component: lazy(
            () => import(/* webpackChunkName: "reviews" */ './views/reviews')
        ),
        protected: true,
    },
    {
        path: AppPath.authUrl,
        authNew: true,
        component: lazy(
            () => import(/* webpackChunkName: "auth" */ './views/auth')
        ),
    },
    {
        path: AppPath.help,
        component: lazy(
            () => import(/* webpackChunkName: "help" */ './views/help')
        ),
        protected: true,
    },
    {
        path: AppPath.helpList,
        component: lazy(
            () => import(/* webpackChunkName: "help" */ './views/help/HelpList')
        ),
        protected: true,
    },
    {
        path: AppPath.experience,
        component: lazy(
            () =>
                import(
                    /* webpackChunkName: "experience" */ './views/experience'
                )
        ),
        protected: true,
    },
    {
        path: AppPath.adminUsers,
        component: lazy(
            () =>
                import(
                    /* webpackChunkName: "adminUsers" */ './views/adminUsers'
                )
        ),
        protected: true,
    },
    {
        path: AppPath.adminPartners,
        component: lazy(
            () =>
                import(
                    /* webpackChunkName: "adminPartners" */ './views/adminPartners'
                )
        ),
        protected: true,
    },
    {
        path: AppPath.adminStats,
        component: lazy(
            () =>
                import(
                    /* webpackChunkName: "adminStats" */ './views/adminStats'
                )
        ),
        protected: true,
    },
    {
        path: AppPath.adminStatus,
        component: lazy(
            () =>
                import(
                    /* webpackChunkName: "adminStatus" */ './views/adminStatus'
                )
        ),
        protected: true,
    },
    {
        path: AppPath.academy,
        component: lazy(
            () => import(/* webpackChunkName: "academy" */ './views/academy')
        ),
        protected: true,
    },
    {
        path: AppPath.finance,
        component: lazy(
            () => import(/* webpackChunkName: "finance" */ './views/finance')
        ),
        protected: true,
    },
]

const RouteComponent = (route) => {
    if (route.protected && !TokenService.isTokenExists()) {
        return <Redirect to={AppPath.signIn} />
    }

    if (Array.isArray(route.path) && TokenService.isTokenExists()) {
        return <Redirect to={rootStore.userStore.homePage} />
    }

    if (route.withoutLayout) {
        return (
            <Route
                path={route.path}
                exact={route.exact}
                render={(props) => (
                    <Suspense fallback={''}>
                        <route.component {...props} />
                    </Suspense>
                )}
            />
        )
    }

    if (Array.isArray(route.path) && route.authNew) {
        return (
            <Route
                path={route.path}
                exact={route.exact}
                render={(props) => (
                    <AuthNewLayouts>
                        <route.component {...props} />
                    </AuthNewLayouts>
                )}
            />
        )
    }

    let RouteCmp = route.component
    if (!route.dontBlocked) {
        RouteCmp = AccessControlService.isRouteAllowed(route.path)
            ? route.component
            : BlockedAccessContent
    }

    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={(props) => (
                <SidebarLayout
                    isBlocked={!AccessControlService.isRouteAllowed(route.path)}
                >
                    {(layoutProps) => <RouteCmp {...layoutProps} {...props} />}
                </SidebarLayout>
            )}
        />
    )
}

export const RenderRoutes = ({routes}) => {
    return (
        <Switch>
            <Redirect from={'/'} to={rootStore.userStore.homePage} exact />
            {routes.map((route) => (
                <RouteComponent key={route.path} {...route} />
            ))}
            <Route>
                <SidebarLayout>
                    {(layoutProps) => <PageNotFound {...layoutProps} />}
                </SidebarLayout>
            </Route>
        </Switch>
    )
}
