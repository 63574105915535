import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import {useLatest, useResizeObserver} from '@hooks'
import {rafThrottle, isNumber, scheduledDOMUpdate, now} from '../utils'
import {
    DEFAULT_LOAD_MORE_COUNT,
    DEFAULT_OVERSCAN,
    DEFAULT_SCROLLING_DELAY,
} from '../constants'

//
// interface UseDynamicSizeListProps {
//     itemsCount: number;
//     itemHeight?: (index: number) => number;
//     estimateItemHeight?: (index: number) => number;
//     getItemKey: (index: number) => Key;
//     overscan?: number;
//     scrollingDelay?: number;
//     getScrollElement: () => HTMLElement | null;
// }

function validateProps({rowHeight, estimateRowHeight}) {
    if (!rowHeight && !estimateRowHeight) {
        throw new Error('You must pass itemHeight or estimateItemHeight prop')
    }
}

export const useDynamicSizeList = ({
    rowHeight,
    rowsCount,
    // scrollingDelay = DEFAULT_SCROLLING_DELAY,
    overscan = DEFAULT_OVERSCAN,
    getRowKey,
    scrollDirection,
    estimateRowHeight,
    getScrollElement,
    scrollDuration = (d) => Math.min(Math.max(d * 0.075, 100), 500),
    scrollEasingFunction = (t) => -(Math.cos(Math.PI * t) - 1) / 2,
    loadMoreCount = DEFAULT_LOAD_MORE_COUNT,
    isItemLoaded,
    loadMore,
    onScroll,
}) => {
    validateProps({rowHeight, estimateRowHeight})
    const [state, setState] = useState({items: []})
    const isMountedRef = useRef(false)
    const bottomRef = useRef(null)
    const msDataRef = useRef([])
    const allRowRef = useRef([])
    const scrollTopRef = useRef(0)
    const prevScrollTopRef = useRef(0)
    const isScrollingRef = useRef(false)
    const isScrollToItemRef = useRef(false)
    const userScrollRef = useRef(true)
    const hasDynamicSizeRef = useRef(false)
    const prevItemIdxRef = useRef(-1)
    const prevVStopRef = useRef(-1)
    const listHeightRef = useRef(0)
    const durationRef = useLatest(scrollDuration)
    const easingFnRef = useLatest(scrollEasingFunction)
    const isItemLoadedRef = useLatest(isItemLoaded)
    const loadMoreRef = useLatest(loadMore)
    const onScrollRef = useLatest(onScroll)
    const scrollToRafRef = useRef()
    const measurementCacheRef = useRef({})
    const [isScrolling] = useState(false)

    const getItemSize = useCallback((index) => {
        /**
         Если передан метод с явным указанием высоты строки "rowHeight" проверка кэша не происходит.
         Значение берется всегда из переданного параметра.
         */
        if (rowHeight) {
            return rowHeight(index)
        }
        if (
            measurementCacheRef.current[index] &&
            isNumber(measurementCacheRef.current[index])
        ) {
            return measurementCacheRef.current[index]
        }

        /**
         Если кэш отсутствует, используется примерный размер строки.
         Передается пользователем
         */
        return estimateRowHeight(index)
    }, [])

    const getMeasure = useCallback((idx, size) => {
        const start = msDataRef.current[idx - 1]?.end ?? 0
        //idx - индекс, start - начало (топ), end - оффсет, size - размер
        return {idx, start, end: start + size, size}
    }, [])

    const measureItems = useCallback(() => {
        msDataRef.current.length = rowsCount

        for (let i = 0; i < rowsCount; i += 1) {
            const measurement = getMeasure(i, getItemSize(i))
            msDataRef.current[i] = measurement
            measurementCacheRef.current[i] = measurement.size
        }
    }, [getItemSize, getMeasure, rowsCount])

    const getCalcData = useCallback(
        (scrollTop) => {
            const rangeStart = scrollTop
            const rangeEnd = scrollTop + listHeightRef.current

            let totalHeight = 0
            let startIndex = 0
            let endIndex = 0
            const allRows = Array(rowsCount)
            for (let index = 0; index < rowsCount; index++) {
                const row = {
                    index,
                    height: measurementCacheRef.current[index],
                    offsetTop: totalHeight,
                }
                totalHeight += row.height
                allRows[index] = row

                if (row.offsetTop + row.height < rangeStart) {
                    startIndex++
                }

                if (row.offsetTop + row.height < rangeEnd) {
                    endIndex++
                }
            }

            const oStartIndex = Math.max(0, startIndex - overscan)
            const oEndIndex = Math.min(rowsCount - 1, endIndex + overscan)

            const innerMarginOverscanStart = allRows[oStartIndex].offsetTop
            const innerSizeNew = totalHeight - innerMarginOverscanStart

            return {
                oStart: oStartIndex,
                oStop: oEndIndex,
                vStart: startIndex,
                vStop: endIndex,
                innerMargin: innerMarginOverscanStart,
                innerSize: innerSizeNew,
                totalSize: totalHeight,
                allRows,
            }
        },
        [overscan, rowsCount]
    )

    const scrollTo = useCallback(
        (offset, isScrolling = true) => {
            const scrollElement = getScrollElement()
            if (!scrollElement) return

            userScrollRef.current = false
            isScrollingRef.current = isScrolling
            scrollElement.scrollTop = offset
        },
        [getScrollElement]
    )

    const handleScroll = useCallback(
        (scrollTop, isScrolling) => {
            if (!rowsCount) {
                setState({items: []})
                return
            }
            prevScrollTopRef.current = scrollTop
            const calcData = getCalcData(scrollTop)
            const {oStart, oStop, vStop, allRows, vStart} = calcData
            const {innerMargin, innerSize, totalSize} = calcData

            const virtualRows = allRows.slice(oStart, oStop + 1)
            allRowRef.current = allRows
            setState({
                items: virtualRows,
                innerMargin,
                innerSize,
                totalSize,
                listHeight: listHeightRef.current,
                visibility: isMountedRef.current,
                allRows,
            })

            if (!isScrolling) return
            const scrollDown = scrollTop > scrollTopRef.current

            if (onScrollRef.current) {
                onScrollRef.current({
                    overscanStartIndex: oStart,
                    overscanStopIndex: oStop,
                    visibleStartIndex: vStart,
                    visibleStopIndex: vStop,
                    scrollTop,
                    scrollDown,
                    userScroll: userScrollRef.current,
                })
            }

            if (scrollDown && vStop + loadMoreCount >= allRows.length - 1) {
                loadMoreRef.current({
                    lastIndex: oStop,
                    scrollTop,
                    userScroll: userScrollRef.current,
                    loadDown: scrollDown,
                })
            }

            if (!scrollDown && vStart - loadMoreCount <= 0) {
                loadMoreRef.current({
                    lastIndex: oStart,
                    scrollTop,
                    userScroll: userScrollRef.current,
                    loadDown: scrollDown,
                })
            }

            prevVStopRef.current = vStop
        },
        [
            getCalcData,
            rowsCount,
            loadMoreCount,
            loadMoreRef,
            onScrollRef,
            isItemLoadedRef,
            scrollDirection,
        ]
    )

    /**
     Автоопределение высоты контейнера
     */
    useLayoutEffect(() => {
        const scrollElement = getScrollElement()
        if (!scrollElement) return
        const observerCallback = (entries) => {
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return
                }
                const entry = entries[0]
                listHeightRef.current =
                    entry.borderBoxSize[0]?.blockSize ??
                    entry.target.getBoundingClientRect().height
                measureItems()
                handleScroll(scrollTopRef.current)
                if (!isMountedRef.current && listHeightRef.current) {
                    isMountedRef.current = true
                }
            })
        }
        const resizeObserver = new ResizeObserver(observerCallback)

        resizeObserver.observe(scrollElement)

        return () => {
            resizeObserver.disconnect()
        }
    }, [getScrollElement, handleScroll, measureItems, rowsCount])

    /**
     Сохранение scrollTop при изменении прокрутки
     */
    useLayoutEffect(() => {
        const scrollElement = getScrollElement()
        if (!scrollElement) return
        let down = true
        const onScroll = ({target}) => {
            const scrollTop = target.scrollTop
            if (scrollTop === scrollTopRef.current) return
            /**
             Поставив точку остановы можно увидеть прыжки scrollTop при скроле вверх
             */
            if (scrollTopRef.current > scrollTop) {
                down = false
            } else {
                down = true
            }
            console.log(down)
            handleScroll(scrollTop, isScrollingRef.current)
            userScrollRef.current = true
            isScrollingRef.current = true
            isScrollToItemRef.current = false

            scrollTopRef.current = scrollTop
        }

        const throttledHandleScroll = rafThrottle(onScroll)

        scrollElement.addEventListener('scroll', throttledHandleScroll)
        return () => {
            scrollElement.removeEventListener('scroll', throttledHandleScroll)
        }
    }, [getScrollElement, handleScroll])
    // /*
    //       Определение прокручивания списка isScrolling
    //   */
    // useEffect(() => {
    //     const scrollElement = getScrollElement()
    //     if (!scrollElement) return
    //
    //     let timeoutId: NodeJS.Timer = null
    //     const handleScroll = () => {
    //         setIsScrolling(true)
    //
    //         if (typeof timeoutId === 'number') {
    //             clearTimeout(timeoutId)
    //         }
    //
    //         timeoutId = setTimeout(() => {
    //             setIsScrolling(false)
    //         }, scrollingDelay)
    //     }
    //     handleScroll()
    //     scrollElement.addEventListener('scroll', handleScroll)
    //     return () => {
    //         clearTimeout(timeoutId)
    //         scrollElement.removeEventListener('scroll', handleScroll)
    //     }
    // }, [getScrollElement])

    // /**
    //  * Загрузка дополнительных элементов при достижении конца списка
    //  */
    // useEffect(() => {
    //     if (!hasMore || isScrolling) return
    //
    //     const scrollElement = getScrollElement()
    //     if (!scrollElement) return
    //
    //     const handleScroll = () => {
    //         const bottomReached =
    //             scrollElement.scrollHeight - scrollElement.scrollTop <=
    //             scrollElement.clientHeight * 1.5
    //
    //         if (bottomReached) {
    //             onLoadMore()
    //         }
    //     }
    //
    //     const throttledHandleScroll = rafThrottle(handleScroll)
    //     scrollElement.addEventListener('scroll', throttledHandleScroll)
    //
    //     return () => {
    //         scrollElement.removeEventListener('scroll', throttledHandleScroll)
    //     }
    // }, [getScrollElement, hasMore, onLoadMore, isScrolling])

    // /**
    //  * Загрузка дополнительных элементов, если список полностью вмещается в контейнер
    //  */
    // useEffect(() => {
    //     if (!hasMore) return
    //
    //     const scrollElement = getScrollElement()
    //     if (!scrollElement) return
    //
    //     const handleInitialLoad = () => {
    //         const isContentShorterThanContainer =
    //             scrollElement.scrollHeight <= scrollElement.clientHeight
    //
    //         if (isContentShorterThanContainer) {
    //             onLoadMore()
    //         }
    //     }
    //
    //     handleInitialLoad()
    // }, [getScrollElement, hasMore, onLoadMore, rowsCount])

    // const {virtualRows, startIndex, endIndex, allRows, totalHeight} =
    //     useMemo(() => {
    //         const getRowHeight = (index: number): number => {
    //             /*
    //             Если передан метод с явным указанием высоты строки "rowHeight" проверка кэша не происходит.
    //             Значение берется всегда из переданного параметра.
    //         */
    //             if (rowHeight) {
    //                 return rowHeight(index)
    //             }
    //             const key = getRowKey(index)
    //             if (isNumber(measurementCache[key])) {
    //                 return measurementCache[key]
    //             }
    //
    //             /*
    //            Если кэш отсутствует, используется примерный размер строки.
    //            Передается пользователем
    //        */
    //             return estimateRowHeight(index)
    //         }
    //         const rangeStart = scrollTop
    //         const rangeEnd = scrollTop + listHeight
    //
    //         let totalHeight = 0
    //         let startIndex = 0
    //         let endIndex = 0
    //         const allRows = Array(rowsCount)
    //
    //         for (let index = 0; index < rowsCount; index++) {
    //             const key = getRowKey(index)
    //             const row = {
    //                 key,
    //                 index,
    //                 height: getRowHeight(index),
    //                 offsetTop: totalHeight,
    //             }
    //
    //             totalHeight += row.height
    //             allRows[index] = row
    //
    //             if (row.offsetTop + row.height < rangeStart) {
    //                 startIndex++
    //             }
    //
    //             if (row.offsetTop + row.height < rangeEnd) {
    //                 endIndex++
    //             }
    //
    //             /*
    //            Определение начала отображаемых строк и последней строки во viewport + overscan
    //        */
    //             startIndex = Math.max(0, startIndex - overscan)
    //             endIndex = Math.min(rowsCount - 1, endIndex + overscan)
    //         }
    //         const virtualRows = allRows.slice(startIndex, endIndex + 1)
    //
    //         return {virtualRows, startIndex, endIndex, allRows, totalHeight}
    //     }, [
    //         scrollTop,
    //         listHeight,
    //         rowsCount,
    //         rowHeight,
    //         estimateRowHeight,
    //         measurementCache,
    //         overscan,
    //     ])

    const latestData = useLatest({
        getRowKey,
        getScrollElement,
        measureItems,
        scrollTo,
        handleScroll,
    })

    const measureElementInner = useCallback(
        (element, resizeObserver, entry) => {
            if (!element) return

            if (!element.isConnected) {
                return resizeObserver.unobserve(element)
            }
            const indexAttribute = element.getAttribute('data-index') || ''
            const index = parseInt(indexAttribute, 10)

            if (Number.isNaN(index)) {
                console.error(
                    'A dynamic element must contain a data-index attribute'
                )
                return
            }
            const size = measurementCacheRef.current[index]
            const {scrollTo, handleScroll} = latestData.current

            const isResize = Boolean(entry)

            resizeObserver.observe(element)

            /**
             Если ресайз не произошел и размер строки присутствует в кэше ничего не делаем.
             */
            if (!isResize && isNumber(size)) {
                return
            }
            const height =
                entry?.borderBoxSize[0]?.blockSize ??
                element.getBoundingClientRect().height

            /**
             Если размер строки не изменился оставляем его прежним
             */
            if (size === height) {
                return
            }

            hasDynamicSizeRef.current = true
            if (index < prevItemIdxRef.current) {
                scrollTo(scrollTopRef.current + height - size, false)
            }
            msDataRef.current[index] = getMeasure(index, height)
            measurementCacheRef.current[index] = height
            if (!isScrollToItemRef.current) {
                handleScroll(scrollTopRef.current, isScrollingRef.current)
            }

            prevItemIdxRef.current = index
        },
        []
    )

    const rowHeightResizeObserver = useResizeObserver((entries, observer) => {
        entries.forEach((enrty) => {
            measureElementInner(enrty.target, observer, enrty)
        })
    })

    const measureElement = useCallback(
        (element) => {
            measureElementInner(element, rowHeightResizeObserver)
        },
        [rowHeightResizeObserver]
    )

    return {
        bottomRef,
        isScrolling,
        measureElement,
        innerMargin: state.innerMargin,
        innerSize: state.innerSize,
        totalSize: state.totalSize,
        items: state.items,
        visibility: state.visibility,
        listHeight: state.listHeight,
    }
}
