import {Card} from '@components/layout'
import {Ndash} from '@components/elements'

export const FirstConnected = () => {
    return (
        <Card>
            <div
                className={'space-x-2 text-lg text-gray-600 dark:text-gray-400'}
            >
                <Ndash />
                <span>За 5 минут рассчитайте,</span>
                <strong className={'text-gray-700 dark:text-gray-300'}>
                    сколько зарабатываете прибыли на каждом товаре,
                </strong>
                <span>с учетом рекламных кампаний.</span>
            </div>
            <div
                className={'space-x-2 text-lg text-gray-600 dark:text-gray-400'}
            >
                <Ndash />
                <strong className={'text-gray-700 dark:text-gray-300'}>
                    Рассчитайте юнит-экономику,
                </strong>
                <span>чтобы определить оптимальную цену товаров.</span>
            </div>
            <div
                className={'space-x-2 text-lg text-gray-600 dark:text-gray-400'}
            >
                <Ndash />
                <span>Поставляйте товары вовремя и в нужном объеме.</span>
                <strong className={'text-gray-700 dark:text-gray-300'}>
                    Не теряйте продажи из-за out of stock.
                </strong>
            </div>
            <div
                className={'space-x-2 text-lg text-gray-600 dark:text-gray-400'}
            >
                <Ndash />
                <span>Сделайте поставки на региональные склады и</span>
                <strong className={'text-gray-700 dark:text-gray-300'}>
                    увеличьте продажи в регионах.
                </strong>
            </div>
            <div
                className={'space-x-2 text-lg text-gray-600 dark:text-gray-400'}
            >
                <Ndash />
                <span>Проведите ABC-анализ ассортимента.</span>
                <strong className={'text-gray-700 dark:text-gray-300'}>
                    Масштабируйте товары — локомотивы, избавляйтесь от
                    неликвида.
                </strong>
            </div>
            <div
                className={'space-x-2 text-lg text-gray-600 dark:text-gray-400'}
            >
                <Ndash />
                <span>Рассчитывайте</span>
                <strong className={'text-gray-700 dark:text-gray-300'}>
                    прибыль от участия в акциях,
                </strong>
                <span>чтобы не продавать в убыток</span>
            </div>
            <div
                className={'space-x-2 text-lg text-gray-600 dark:text-gray-400'}
            >
                <Ndash />
                <span>Рассчитывайте чистую прибыль по своему магазину и</span>
                <strong className={'text-gray-700 dark:text-gray-300'}>
                    держите под контролем финансы
                </strong>
            </div>
        </Card>
    )
}
