import {useState} from 'react'
import {observer} from 'mobx-react'
import {ModalConfirm, ModalSuccess} from '@components/overlays'
import {toast} from 'react-toastify'
import MarketService from '@services/MarketService/MarketService'
import {useSelector} from '@hooks'
import {useHistory} from 'react-router-dom'
import {AppPath} from '@routesEnums'

export const DisconnectDemoAcc = observer(() => {
    const history = useHistory()
    const marketStore = useSelector((store) => store.marketStore)
    const userStore = useSelector((store) => store.userStore)
    const authStore = useSelector((store) => store.authStore)

    const [unsubscribeModal, setUnsubscribeModal] = useState(false)
    const [successUnsubscribeModal, setSuccessUnsubscribeModal] =
        useState(false)
    const handleClickDisconnectDemo = () => {
        history.push(AppPath.marketplaces)
        // setUnsubscribeModal(true)
    }

    const onConfirmUnsubscribe = async () => {
        const payload = {
            account_ids: marketStore.getAllDemoAccIds,
        }
        try {
            const {data} =
                await MarketService.deleteUnsubscribeDemoAccByIds(payload)
            if (data.result) {
                await authStore.sendRefreshToken()
                // setUnsubscribeModal(false)
                // setSuccessUnsubscribeModal(true)
                // setTimeout(() => {
                //     setSuccessUnsubscribeModal(false)
                // }, 3000)
                await marketStore.getList()
            } else {
                toast(data.message || 'Ошибка отписки кабинета', {
                    type: 'error',
                })
            }
        } catch (e) {
            console.log(e)
            if (e.response.data.result === false) {
                toast(e.response.data.message || 'Ошибка отписки кабинета', {
                    type: 'error',
                })
            }
        }
    }

    return (
        <span className={'space-x-2 lg:flex lg:flex-nowrap'}>
            <span>В отчете представлены демо-данные.</span>
            <span
                onClick={handleClickDisconnectDemo}
                className={
                    'cursor-pointer truncate whitespace-normal text-blue-500 hover:opacity-80 dark:text-blue-400'
                }
            >
                Начать работать с реальными данными
            </span>
            {/*<ModalConfirm*/}
            {/*    open={unsubscribeModal}*/}
            {/*    title={`Демо-данные будут отключены`}*/}
            {/*    description={*/}
            {/*        'Вы действительно хотите отключить демонстрационный кабинет? \nДля повторного подключения обратитесь в поддержку'*/}
            {/*    }*/}
            {/*    accept={'Отключить'}*/}
            {/*    onClose={() => setUnsubscribeModal(false)}*/}
            {/*    onAccept={onConfirmUnsubscribe}*/}
            {/*/>*/}
            <ModalSuccess
                open={successUnsubscribeModal}
                onClose={() => setSuccessUnsubscribeModal(false)}
                title={`Демо-Кабинет успешно удалён`}
            />
        </span>
    )
})
