import {useMemo} from 'react'
import {observer} from 'mobx-react'

import {Card} from '@components/layout'
import {useResponsive} from '@hooks'
import {data} from '@assets/texts/servicesSlider/data'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Autoplay, Pagination} from 'swiper/modules'

const ServicesSlider = observer(() => {
    const {s, md, lg, xl} = useResponsive()

    const slidesPerView = useMemo(() => {
        if (xl) return 3
        if (lg) return 2
        return 1
    }, [lg, xl])

    const imageWidth = useMemo(() => {
        if (md) return 150
        if (s) return 200
        return 120
    }, [md, s])

    return (
        <div className={'mb-4 w-full max-w-7xl'}>
            <Swiper
                modules={[Autoplay, Pagination]}
                spaceBetween={12}
                slidesPerView={slidesPerView}
                pagination={{
                    dynamicBullets: false,
                    el: '.tariff-swiper-pagination',
                    clickable: true,
                }}
                autoplay={{
                    delay: 5000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                }}
                loop
            >
                {data.map((slide, index) => (
                    <SwiperSlide key={index}>
                        <Card
                            isFull={false}
                            innerClassName={
                                'flex items-stretch h-40 relative z-0 lg:w-full'
                            }
                        >
                            <div
                                className={
                                    'z-10 flex w-full items-center justify-between'
                                }
                            >
                                <p
                                    className={
                                        'text-md w-2/3 font-medium leading-5 text-gray-600 dark:text-gray-400 sm:text-lg'
                                    }
                                >
                                    {slide?.title}
                                </p>
                                <img
                                    width={imageWidth}
                                    className={`object-contain object-right-bottom`}
                                    src={slide?.img}
                                    alt={slide?.title}
                                />
                            </div>
                        </Card>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div
                className={
                    'tariff-swiper-pagination relative top-1 mt-2 flex justify-center space-x-2 py-2'
                }
            />
        </div>
    )
})

export default ServicesSlider
