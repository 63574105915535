import cn from 'classnames'
import {InformationCircleIcon} from '@heroicons/react/outline'
import {Card} from '@components/layout'

const CustomModuleWarn = ({children, icon, className, background}) => {
    return (
        <Card className={className} background={background}>
            <div
                className={cn(
                    'flex flex-col items-center space-y-2 text-center sm:flex-row',
                    'sm:space-x-2 sm:space-y-0 sm:text-left'
                )}
            >
                {icon ? (
                    icon
                ) : (
                    <InformationCircleIcon
                        className={'h-16 w-16 flex-shrink-0 text-gray-400'}
                    />
                )}
                {children}
            </div>
        </Card>
    )
}

export {CustomModuleWarn}
