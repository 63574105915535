import {url} from '@services/url'

export const baseURL = `${url}/gw/api/v2`
const dadataURL = `https://suggestions.dadata.ru/suggestions/api/4_1/rs`
const sellerURL = import.meta.env.VITE_APP_INFORMER_BASE_URL
const urlDisabledURL = `http://localhost:3000/public/mock/urlDisabled.json`
const errorUrl = `https://api.telegram.org/${
    import.meta.env.VITE_APP_ERROR_TOKEN
}`

export const baseConfig = {
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
}

export const dadataConfig = {
    baseURL: dadataURL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: import.meta.env.VITE_APP_DADATA_TOKEN,
    },
}
export const errorConfig = {
    baseURL: errorUrl,
    isAuth: false,
    headers: {
        'Content-Type': 'application/json',
    },
}

export const sellerConfig = {
    baseURL: sellerURL,
    isAuth: false,
    headers: {
        'Content-Type': 'application/json',
    },
}
