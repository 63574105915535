import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from 'mobx'

import MarketService from '@services/MarketService/MarketService'

import {
    marketplaceId,
    MIN_IMPORT_STATUSES,
    STATUSES_VARIANT,
} from '@utils/constants'
import {differenceInHours} from 'date-fns'
import {PollingV2} from '@utils/polling/pollingv2'
import {checkIsWb, isExpiringSoon} from '@utils/helpers'

class MarketStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            marketplaces: observable,
            credentials: observable,
            loaderAllCreds: observable,
            setLoaderAllCreds: observable,
            servicesSection: observable,

            statuses: observable,
            setStatuses: action,
            resetStatuses: action,
            removeStatus: action,

            setMarketplaces: action,
            setCredentials: action,
            reset: action,
            setServicesSection: action,
            mergeMpCabinetStatus: action,
            credentialsOptions: computed,
            credIsFull: computed,
            enabledMarkets: computed,
            isOnlyDemoAcc: computed,
            isDemoAndRealAcc: computed,
            isRealAcc: computed,
            isOnlyRealAcc: computed,
            getDemoAcc: computed,
            getAllDemoAccIds: computed,
            newStatusesImport: computed,
            getHasAdvKey: computed,
            credentialsNewImports: computed,
            isNewSuccessImport: computed,
            isNewFailedImport: computed,
            expiringKeyWb: computed,
        })
        this.importStatusesPolling = this.createImportStatusesPolling()
    }
    statuses = observable.map()

    marketplaces = [
        {
            id: 1,
            name: 'Ozon',
            label: 'Ozon',
            visible: true,
        },
        {
            id: 2,
            name: 'Wildberries',
            label: 'Wildberries',
            visible: true,
        },
        {
            id: 10,
            name: 'Yandex',
            label: (
                <span>
                    Яндекс.Маркет{' '}
                    <span className={'text-blue-500 dark:text-blue-400'}>
                        β
                    </span>
                </span>
            ),
            tooltip: 'Яндекс.Маркет работает в тестовом режиме!',
            visible: true,
        },
        {
            id: 20,
            name: 'Demo',
            label: 'Демонстрационный кабинет',
            visible: false,
        },
        {
            id: 21,
            name: 'Ethalon',
            label: 'Эталонный кабинет',
            visible: false,
        },
    ]
    credentials = []
    loaderAllCreds = true
    servicesSection = false

    reset = () => {
        this.setCredentials()
        this.resetStatuses()
    }

    setStatuses = (key, value) => {
        this.statuses.set(key, value)
    }

    resetStatuses = () => {
        this.statuses.clear()
    }

    removeStatus = (key) => {
        this.statuses.delete(key)
    }

    setMarketplaces = (array = []) => {
        this.marketplaces = array
    }

    mergeMarketplacesData = (source) => {
        this.setMarketplaces(source?.marketplaces)
    }

    setCredentials = (array = []) => {
        this.credentials = array
    }

    setLoaderAllCreds = (value = false) => {
        this.loaderAllCreds = value
    }

    setServicesSection = (value = false) => {
        this.servicesSection = value
    }

    getMarketNameById = (id) => {
        return this.marketplaces.find((market) => market.id === id)?.name
    }

    getCredentialByID = (id) => {
        return this.credentials.find((cred) => cred.id === id)
    }

    getCredentialByInstanceName = (name) => {
        return this.credentials.find(
            (cred) => cred.name.toLowerCase() === name.toLowerCase()
        )
    }

    getMarketLabelByName = (name) => {
        return this.marketplaces.find((market) => (market.name = name))?.label
    }

    getCabinetNameById = (id) => {
        return this.credentials.find((cred) => cred.id === id)?.name
    }

    getMarketByName = (marketName) => {
        return this.marketplaces.find((market) => market.name === marketName)
    }

    getMarketIdByCredentialId = (credId) => {
        return (
            this.credentials.find((cred) => cred.id === credId)
                ?.marketplaceId || 0
        )
    }

    getMarketNameByCredentialId = (credId) => {
        const marketId = this.credentials.find(
            (cred) => cred.id === credId
        )?.marketplaceId
        return this.getMarketNameById(marketId || 0)
    }

    get enabledMarkets() {
        const arr = []
        this.marketplaces.forEach((market) => {
            const marketCred = this.credentials.find(
                (cred) => cred.marketplaceId === market.id
            )
            if (!marketCred) return
            arr.push(market)
        })
        return arr
    }

    getCredentialsByMarketName = (marketName = '') => {
        if (!marketName || !this.credentials.length) return []
        return this.getCredentialsByMarketId(
            this.getMarketByName(marketName)?.id
        )
    }

    getCredentialsByMarketId = (marketId = '') => {
        if (!marketId || !this.credentials.length) return []
        return this.credentials.filter(
            (cred) => marketId === cred.marketplaceId
        )
    }

    checkDemoCredential = (id) => {
        const marketId = this.credentials.find(
            (cred) => cred.id === id
        )?.marketplaceId
        return (
            marketId === marketplaceId.Demo ||
            marketId === marketplaceId.Ethalon
        )
    }

    get credentialsOptions() {
        if (!this.credentials.length) return []
        return this.credentials.map((cred) => ({
            key: cred.id,
            name: cred.name,
            marketId: cred.marketplaceId,
            market: this.getMarketNameById(cred.marketplaceId),
        }))
    }

    getCredentialsOptions = (marketplacesId) => {
        if (!this.credentials.length) return []
        const marketplacesIdSet = new Set(marketplacesId)
        return this.credentials
            .filter((cred) => marketplacesIdSet.has(cred.marketplaceId))
            .map((cred) => ({
                key: cred.id,
                name: cred.name,
                market: this.getMarketNameById(cred.marketplaceId),
                marketId: cred.marketplaceId,
            }))
    }

    get credIsFull() {
        return !!(this.marketplaces.length && this.credentials.length)
    }

    get invalidAcc() {
        return this.credentials.filter((cred) => !cred.isValid)
    }

    get expiringKeyWb() {
        return this.credentials
            .filter((cred) => cred.authType !== 'oauth2.0')
            .filter(
                (cred) =>
                    cred.isValid &&
                    cred.credInfo?.expired &&
                    isExpiringSoon(cred.credInfo.expired)
            )
    }

    get isOnlyDemoAcc() {
        return (
            this.credentials.every(
                (cred) =>
                    cred.marketplaceId === marketplaceId.Demo ||
                    cred.marketplaceId === marketplaceId.Ethalon
            ) && this.credentials.length
        )
    }

    get isOnlyRealAcc() {
        const isDemo = !!this.credentials.find((cred) => {
            return (
                cred.marketplaceId === marketplaceId.Demo ||
                cred.marketplaceId === marketplaceId.Ethalon
            )
        })
        return !!(!isDemo && this.credentials.length)
    }

    get isDemoAndRealAcc() {
        const isOnlyDemoAcc = this.isOnlyDemoAcc
        const isOnlyRealAcc = this.isOnlyRealAcc
        return !!(!isOnlyDemoAcc && !isOnlyRealAcc && this.credentials.length)
    }

    get isRealAcc() {
        return !!this.credentials.find(
            (cred) =>
                cred.marketplaceId !== marketplaceId.Demo &&
                cred.marketplaceId !== marketplaceId.Ethalon
        )
    }

    get getDemoAcc() {
        return this.credentials.find(
            (cred) =>
                cred.marketplaceId === marketplaceId.Demo ||
                cred.marketplaceId === marketplaceId.Ethalon
        )
    }

    get getAllDemoAccIds() {
        return this.credentials
            .filter(
                (cred) =>
                    cred.marketplaceId === marketplaceId.Demo ||
                    cred.marketplaceId === marketplaceId.Ethalon
            )
            .map((cred) => cred.id)
    }

    get newStatusesImport() {
        const arr = Array.from(this.statuses, ([name, value]) => ({
            name,
            value,
        }))
        return (
            arr?.filter(({name, value}) => {
                if (value.runType === 'new') {
                    const statusArr = value?.statuses?.filter(
                        (item) => item.status < 9
                    )
                    return !!statusArr?.length
                }
                return false
            }) || []
        )
    }

    get isNewFailedImport() {
        const arr = Array.from(this.statuses, ([name, value]) => ({
            name,
            value,
        }))
        return !!arr
            .filter(({name, value}) => {
                return value.runType === 'new'
            })
            .filter(({name, value}) => {
                return value.statuses.some(
                    (item) => item.status === STATUSES_VARIANT.failed
                )
            }).length
    }

    get isNewSuccessImport() {
        const arr = Array.from(this.statuses, ([name, value]) => ({
            name,
            value,
        }))
        return !!arr
            ?.filter(({name, value}) => {
                return value.runType === 'new'
            })
            ?.some(({name, value}) => {
                return (
                    value?.statuses?.every(
                        (item) => item.status === STATUSES_VARIANT.success
                    ) && value.statuses.length >= MIN_IMPORT_STATUSES
                )
            })
    }

    get getHasAdvKey() {
        return !!this.credentials.filter((cred) => !cred.hasAdvKey).length
    }

    get credentialsNewImports() {
        const statuses = this.newStatusesImport
        return this.credentials.filter(
            (credential) =>
                !!statuses.find((status) => status.name === credential.id)
        )
    }

    get xlsImports() {
        const arr = Array.from(this.statuses, ([name, value]) => ({
            name,
            value,
        }))
        const wbImports = arr.filter(({name}) =>
            checkIsWb(this.getMarketIdByCredentialId(name))
        )
        return (
            wbImports.filter(
                ({value}) =>
                    !!value.statuses.find(
                        (item) =>
                            item.parseType === 'wb_xls' &&
                            item.status !== STATUSES_VARIANT.success &&
                            item.status !== STATUSES_VARIANT.failed
                    )
            ) || []
        )
    }

    getStatusesByCabinetId = (cabinetId) => {
        return this.statuses.get(cabinetId)
    }

    getDataSourceById = (credId) => {
        return this.credentials.find((cred) => cred.id === credId)?.dataSource
    }

    checkImportStatusNew = () => {
        const newStatuses = this.newStatusesImport
        return !!newStatuses.length
    }

    mergeMpCabinetStatus = (status) => {
        status.forEach((cabinet) => {
            if (
                JSON.stringify(cabinet) !==
                JSON.stringify(this.statuses.get(cabinet.cabinetId))
            ) {
                this.setStatuses(cabinet.cabinetId, cabinet)
            }
        })
        return this.checkImportStatusNew()
    }

    getList = async () => {
        try {
            await this.getCredentialList()
            if (!this.importStatusesPolling.isPooling) {
                await this.importStatusesPolling.startPolling()
            }
        } catch (e) {
            console.log(e)
        }
    }

    getCredentialList = async () => {
        try {
            const {data} = await MarketService.getCredentialList()
            this.setCredentials(data)
        } catch (e) {
            console.log(e)
        }
    }

    getCredentialStatuses = async () => {
        const payload = {
            cabinets: this.credentials.map((cred) => cred.id),
        }
        try {
            const {data} = await MarketService.getCredentialStatuses(payload)
            return Promise.resolve(data.status)
        } catch (e) {
            console.log(e)
            return Promise.reject(e)
        }
    }

    createImportStatusesPolling(delay = 10000) {
        return new PollingV2({
            name: 'importStatusesPolling',
            pollingFunction: this.getCredentialStatuses,
            onCheckResult: (data) => this.onResult(data),
            delay,
            isControlPolling: true,
        })
    }

    onResult = (data) => {
        return this.mergeMpCabinetStatus(data)
    }
}

export default MarketStore
