import MPSuggestion from './banners/MPSuggestion/MPSuggestion'
import {Button} from '@components/elements'
import {Link} from 'react-router-dom'
import {AppPath} from '@routesEnums'

const PromoSection = () => (
    <>
        <MPSuggestion />
    </>
)

export default PromoSection
