//libs

import {observer} from 'mobx-react'

//hooks
import {useSelector} from '@hooks'

//componentns
import {Overlay} from '@components/overlays'
import {TextArea} from '@components/forms/TextAreas'
import {Button} from '@components/elements'
import {Loader} from '@components/elements/Loader'
import {CheckIcon} from '@heroicons/react/outline'
import {Select} from '@components/forms/Selects'
import {TokenService} from '@services/TokenService'
import {TextField} from '@components/forms/TextFields'

const SupportOverlay = observer(() => {
    const supportOverlayStore = useSelector(
        (store) => store.supportOverlayStore
    )
    const {
        topic,
        setTopic,
        setTopicLabel,
        supportQuery,
        setSupportQuery,
        queryLength,
        invalidLength,
        queryStatus,
        submitForm,
    } = supportOverlayStore
    const onSubmitForm = () => {
        submitForm()
    }

    const handleChangeEmail = (e) => {
        supportOverlayStore.setEmail(e.target.value)
    }

    return (
        <Overlay
            size={'lg'}
            open={supportOverlayStore.overlayControl.isOpen}
            setOpen={supportOverlayStore.overlayControl.close}
            title={'Техническая поддержка'}
        >
            <div className={'flex h-full flex-col'}>
                <div className={'flex flex-grow flex-col space-y-4 pb-6'}>
                    <p className={'mb-4 text-base dark:text-gray-100'}>
                        По любым вопросам или ошибкам в работе сервиса вы можете
                        написать в форме ниже:
                    </p>
                    <Select
                        className={'glex-grow-0 mb-4 block w-full'}
                        options={topic.options}
                        selected={topic.selected}
                        labelInside
                        required
                        label={'Тема'}
                        onChange={(value) => {
                            setTopic(value)
                            setTopicLabel(value?.name)
                        }}
                    />
                    {!TokenService.isTokenExists() && (
                        <TextField
                            value={supportOverlayStore.email}
                            type='text'
                            label={'Email'}
                            placeholder={'hello@seller24.ru'}
                            onChange={handleChangeEmail}
                            helperText={
                                supportOverlayStore.emailError
                                    ? 'Email не должен быть пустой'
                                    : ''
                            }
                            error={supportOverlayStore.emailError}
                        />
                    )}
                    <TextArea
                        className={'mb-4 flex h-full w-full flex-grow flex-col'}
                        required
                        textAreaClassName={'h-full'}
                        label={'Сообщение в поддержку'}
                        value={supportQuery}
                        onChange={(e) => setSupportQuery(e.target.value)}
                    />
                    {queryLength > 0 && invalidLength && (
                        <div className={'mb-3 text-sm dark:text-gray-400'}>
                            Введите сообщение от 30 до 1000 символов в длину
                        </div>
                    )}
                </div>
                <div
                    className={
                        'flex flex-shrink-0 items-center justify-between border-t border-gray-300 pt-6 dark:border-gray-600'
                    }
                >
                    <div className={'flex items-center'}>
                        {queryStatus === 'pending' && (
                            <div
                                className={
                                    'flex items-center dark:text-gray-400'
                                }
                            >
                                <Loader
                                    sm
                                    overlayed={false}
                                    className={'mr-2'}
                                />
                                Идёт отправка сообщения...
                            </div>
                        )}
                        {queryStatus === 'failed' && (
                            <div
                                className={
                                    'mb-3 text-sm text-red-500 dark:text-red-400'
                                }
                            >
                                Что-то пошло не так, попробуйте ещё раз...
                            </div>
                        )}
                        {queryStatus === 'success' && (
                            <div className={'flex items-center text-green-500'}>
                                <CheckIcon className={'mr-2 h-6 w-6'} />
                                Ваше сообщение отправлено!
                            </div>
                        )}
                    </div>
                    <div className={'flex items-center'}>
                        <Button
                            onClick={supportOverlayStore.overlayControl.close}
                            color={'white'}
                        >
                            Отменить
                        </Button>
                        <Button
                            disabled={
                                queryStatus === 'pending' ||
                                !topic?.selected ||
                                queryLength === 0 ||
                                invalidLength
                            }
                            className={'ml-2'}
                            onClick={onSubmitForm}
                        >
                            Отправить
                        </Button>
                    </div>
                </div>
            </div>
        </Overlay>
    )
})

export {SupportOverlay}
