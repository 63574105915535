import {makeObservable, observable, action} from 'mobx'
import CatalogService from '@services/CatalogService/CatalogService'
import {AppPath} from '@routesEnums'
import qs from 'qs'
import {PaginationStore} from '../../../../store/PaginationStore'

class CatalogListStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            catalog: observable,
            pending: observable,
            filterOpen: observable,
            params: observable,
            filterLoader: observable,
            isTested: observable,
            testFilterResults: observable,
            appliedFilter: observable,
            selectedInstance: observable,
            search: observable,
            setCatalog: action,
            setPending: action,
            setFilterOpen: action,
            setIsTested: action,
            setFilterLoading: action,
            setTestFilterResults: action,
            setApplyFilter: action,
            resetApplyFilter: action,
            updateParams: action,
            setClientStock: action,
            setMarketplaceStock: action,
            setProductStatus: action,
            setSelectedInstance: action,
            setSearch: action,
        })
        this.params = this.parseInitialParams()
        this.paginationStore = new PaginationStore()
    }

    catalog = []
    selectedInstance = []
    search = ''
    params = {}

    pending = false
    filterOpen = false
    filterLoader = false
    isTested = false
    testFilterResults = 0
    appliedFilter = {}
    clientStockOptions = {
        options: [
            {
                name: 'Любые остатки на вашем складе',
                key: 'client_stock_all',
            },
            {
                name: 'Нет остатков',
                key: 'client_stock_zero',
            },
            {
                name: 'Есть остатки',
                key: 'client_stock_not_zero',
            },
        ],
        selected: {},
    }
    marketplaceStockOptions = {
        options: [
            {
                name: 'Любые остатки на складе площадки',
                key: 'marketplace_stock_all',
            },
            {
                name: 'Нет остатков',
                key: 'marketplace_stock_zero',
            },
            {
                name: 'Есть остатки',
                key: 'marketplace_stock_not_zero',
            },
        ],
        selected: {},
    }

    productStatusOptions = {
        options: [
            {
                name: 'Все статусы',
                key: 'status_all',
            },
            {
                name: 'Опубликован',
                key: 'status_published',
            },
            {
                name: 'Не опубликован',
                key: 'status_no_published',
            },
        ],
        selected: {},
    }

    parseInitialParams = () => {
        const search = window.location.search
        const parsed = qs.parse(search, {ignoreQueryPrefix: true})

        return {
            page: parsed.page ? Number(parsed.page) : 1,
            limit: parsed.limit ? Number(parsed.limit) : 10,
            price_greater_than: parsed.price_greater_than || null,
            price_less_than: parsed.price_less_than || null,
            marketplace_credential_ids:
                parsed.marketplace_credential_ids || null,
            search: parsed.search || null,
            marketplace_stock: parsed.marketplace_stock || null,
            product_status: parsed.product_status || null,
            client_stock: parsed.client_stock || null,
        }
    }

    getNewParams = (
        {
            page,
            limit,
            price_less_than,
            price_greater_than,
            marketplace_credential_ids,
            search,
            marketplace_stock,
            client_stock,
            product_status,
        } = {},
        params
    ) => {
        return {
            page: page || 1,
            limit: limit || params.limit,
            price_less_than:
                price_less_than !== undefined
                    ? price_less_than
                    : params.price_less_than,
            price_greater_than:
                price_greater_than !== undefined
                    ? price_greater_than
                    : params.price_greater_than,
            marketplace_credential_ids:
                marketplace_credential_ids !== undefined
                    ? marketplace_credential_ids
                    : params.marketplace_credential_ids,
            search: search !== undefined ? search : params.search,
            marketplace_stock:
                marketplace_stock !== undefined
                    ? marketplace_stock
                    : params.marketplace_stock,
            product_status:
                product_status !== undefined
                    ? product_status
                    : params.product_status,
            client_stock:
                client_stock !== undefined ? client_stock : params.client_stock,
        }
    }

    setCatalog = (array = []) => {
        this.catalog = array
    }

    setSelectedInstance = (array = []) => {
        this.selectedInstance = array
    }

    setClientStock = (value = {}) => {
        this.clientStockOptions.selected = value
    }

    setMarketplaceStock = (value = {}) => {
        this.marketplaceStockOptions.selected = value
    }

    setProductStatus = (value = {}) => {
        this.productStatusOptions.selected = value
    }

    setFilterOpen = (value = false) => {
        this.filterOpen = value
    }

    updateParams = (data = {}) => {
        this.params = this.getNewParams(data, this.params)
    }

    setPending = (value = true) => {
        this.pending = value
    }

    setSearch = (value = '') => {
        this.search = value
    }

    resetFilter = () => {
        this.updateParams({
            price_less_than: null,
            price_greater_than: null,
            marketplace_credential_ids: null,
            search: null,
            marketplace_stock: null,
            product_status: null,
            client_stock: null,
        })
        this.setSelectedInstance()
        this.setSearch()
        this.setClientStock()
        this.setMarketplaceStock()
        this.setProductStatus()
        this.resetApplyFilter()
        this.setIsTested(false)
    }

    setIsTested = (value = false) => {
        this.isTested = value
    }

    setFilterLoading = (value = false) => {
        this.filterLoader = value
    }

    setTestFilterResults = (value = 0) => {
        this.testFilterResults = value
    }

    setApplyFilter = (obj) => {
        this.appliedFilter = Object.assign({}, this.appliedFilter, obj)
    }

    resetApplyFilter = () => {
        this.appliedFilter = {}
    }

    applyFilter = () => {
        this.updateParams(this.appliedFilter)
    }

    mergeCatalogData = (sourceArr) => {
        const catalog = sourceArr.map((item, index) => {
            return {
                id: item?.id,
                account: {
                    marketplaceName: item?.marketplaceName,
                    credentialsInstanceName: item?.credentialsInstanceName,
                },
                sku: {
                    sku: item?.sku,
                    productId: item?.productId,
                    offerId: item?.offerId,
                },
                product: {
                    name: item?.name,
                    imageThumb: item?.imageThumb,
                    link: AppPath.catalogId(item?.id),
                },
                price: item?.price,
                marketplaceStock: item?.marketplaceStock,
                clientStock: item?.clientStock,
                status: item?.status,
            }
        })
        this.setCatalog(catalog)
    }

    mergeCatalog = (source) => {
        this.mergeCatalogData(source?.wares)
        this.paginationStore.setPagination({
            page: Number(source?.page),
            total: Number(source?.total),
            totalPages: Number(source?.totalPages),
            limit: this.paginationStore.pagination.limit || 10,
        })
    }

    requestCatalog = async (params) => {
        this.updateParams(params)
        this.setPending(true)
        try {
            const {data} = await CatalogService.getCatalog(this.params)
            this.mergeCatalog(data)
            this.setPending(false)
        } catch (error) {
            console.log(error)
            this.setPending(false)
        }
    }

    testFilter = async () => {
        this.setIsTested(true)
        this.setFilterLoading(true)
        const body = {
            ...this.params,
            ...this.appliedFilter,
            limit: 1,
            page: 1,
        }
        try {
            const {data} = await CatalogService.getCatalog(body)
            this.setTestFilterResults(data.total)
        } catch (e) {
            console.log(e)
        } finally {
            this.setFilterLoading(false)
        }
    }
}

export default CatalogListStore
