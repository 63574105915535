import PropTypes from 'prop-types'
import Aliexpress from '@assets/icons/markets/Aliexpress.svg?url'
import Ozon from '@assets/icons/markets/Ozon.svg?url'
import Wildberries from '@assets/icons/markets/Wildberries.svg?url'
import Yandex from '@assets/icons/markets/Yandex.svg?url'
import SberMegaMarket from '@assets/icons/markets/SberMegaMarket.svg?url'
import Stocks from '@assets/icons/markets/Stock.svg?url'
import Seller24 from '@assets/icons/markets/Seller24.svg?url'
import Default from '@assets/icons/markets/Default.svg?url'

const MARKET_ICONS = {
    ozon: Ozon,
    wildberries: Wildberries,
    sberbank: SberMegaMarket,
    aliexpress: Aliexpress,
    yandex: Yandex,
    stocks: Stocks,
    seller24: Seller24,
}

const MarketIcon = ({
    name = 'seller24',
    size = 40,
    iconClassName = '',
    className = '',
}) => {
    const Icon = MARKET_ICONS[name.toLowerCase()]

    return (
        <div className={className} style={{width: size}}>
            <img
                src={Icon || Default}
                className={iconClassName}
                width={size}
                height={size}
                alt={''}
            />
        </div>
    )
}

MarketIcon.propTypes = {
    name: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    iconClassName: PropTypes.string,
    className: PropTypes.string,
}

export {MarketIcon}
