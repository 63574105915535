import {ShieldCheckIcon} from '@heroicons/react/outline'
import {Ndash} from '@components/elements'
import {
    PARTNERSHIP_TITLE,
    PARTNERSHIP_TOOLTIP,
} from '@views/marketplaces/pages/Marketplaces/constants'
import {SettingsHelp} from '@components/applicationUi'

export const Partnership = ({marketName}) => {
    return (
        <span
            className={
                'mb-4 flex items-center justify-center gap-1 text-sm text-gray-800 dark:text-gray-200'
            }
        >
            <ShieldCheckIcon
                className={
                    'h-6 w-6 flex-shrink-0 text-gray-800 dark:text-gray-200'
                }
            />
            <span className={'space-x-1 text-gray-800 dark:text-gray-200'}>
                <span>Seller24</span>
                <Ndash />
                <span>{PARTNERSHIP_TITLE[marketName.toLowerCase()]}</span>
            </span>
            <SettingsHelp
                content={PARTNERSHIP_TOOLTIP[marketName.toLowerCase()]}
                size={'w-5 h-5'}
            />
        </span>
    )
}
