import {ApiFactory} from '@services/ApiFactory'
import {AttrsMatchingUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'

const attrsMatchingInstance = ApiFactory.createApi(baseConfig)

class AttrsMatchingService {
    static getMarketplaces = async () => {
        return await attrsMatchingInstance.get(AttrsMatchingUrl.marketplace)
    }

    static getMarketplaceTopCats = async (marketId) => {
        return await attrsMatchingInstance.get(
            AttrsMatchingUrl.marketplaceTopCats(marketId)
        )
    }

    static getMarketplaceCats = async (marketId, catId) => {
        return await attrsMatchingInstance.get(
            AttrsMatchingUrl.marketplaceCats(marketId, catId)
        )
    }

    static getMarketAttrDict = async (marketId, catId, attrId, query) => {
        return await attrsMatchingInstance.get(
            AttrsMatchingUrl.marketplaceAttrDict(marketId, catId, attrId),
            query
        )
    }

    static getMasterTopCats = async () => {
        return await attrsMatchingInstance.get(AttrsMatchingUrl.masterTopCats)
    }

    static getMasterCats = async (id) => {
        return await attrsMatchingInstance.get(AttrsMatchingUrl.masterCats(id))
    }

    static getMasterAttrDict = async (id, query) => {
        return await attrsMatchingInstance.get(
            AttrsMatchingUrl.masterAttrDict(id),
            query
        )
    }

    static getMasterAttrItemLinks = async (id) => {
        return await attrsMatchingInstance.get(
            AttrsMatchingUrl.masterAttrItemLinks(id)
        )
    }

    static getFeedSources = async () => {
        return await attrsMatchingInstance.get(AttrsMatchingUrl.feeds)
    }

    static getFeedTopCats = async (id) => {
        return await attrsMatchingInstance.get(AttrsMatchingUrl.feedTopCats(id))
    }

    static getFeedCats = async (feedId, catId) => {
        return await attrsMatchingInstance.get(
            AttrsMatchingUrl.feedCats(feedId, catId)
        )
    }

    static getFeedAttrDict = async (feedId, attrId, query) => {
        return await attrsMatchingInstance.get(
            AttrsMatchingUrl.feedAttrDict(feedId, attrId),
            query
        )
    }

    static postAttrsMatching = async (body) => {
        return await attrsMatchingInstance.post(
            AttrsMatchingUrl.itemsAssign,
            body
        )
    }

    static putModifyLinks = async (body) => {
        return await attrsMatchingInstance.put(
            AttrsMatchingUrl.modifyLinks,
            body
        )
    }
}

export default AttrsMatchingService
