import {Overlay} from '@components/overlays'
import {TaxCardInfoLink} from '@components/applicationUi'

export const TaxOverlayInstructions = ({open, setOpen}) => {
    return (
        <Overlay
            open={open}
            setOpen={setOpen}
            size={'lg'}
            title={'Как корректно настроить расчет налогов'}
        >
            <TaxCardInfoLink />
        </Overlay>
    )
}
