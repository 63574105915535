import {makeObservable, observable, action, computed} from 'mobx'
import CatalogService from '@services/CatalogService/CatalogService'

import {FileDownload} from '@utils/download/download'
import {FileStore} from '../../../../../store/FileStore'
// import {getDateLabel} from '@utils/timestamps'

const STATUS_POLLING_INTERVAL_MS = 500

class StockXlsStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            credentials: computed,
            checkedCredentials: computed,
            lastTemplateTimeString: computed,

            availableMarkets: observable,
            file: observable,
            credentialList: observable,
            downloadReadyCredentials: observable,
            needGeneration: observable,
            templateProcessing: observable,
            lastTemplateTime: observable,
            batchUpdateProcessing: observable,
            resultsList: observable,

            setInitialCredentialList: action,
            setCredentialChecked: action,
            setDownloadReadyCredentials: action,
            setNeedGeneration: action,
            setLastTemplateTime: action,
            setTemplateProcessing: action,
            setBatchUpdateProcessing: action,
            mergeResultData: action,
            addFile: action,
            deleteFile: action,
        })
    }

    availableMarkets = ['Ozon', 'Wildberries', 'Aliexpress', 'Yandex']
    credentialList = []
    downloadReadyCredentials = []

    needGeneration = false
    templateStatusPollingInterval = undefined
    templateProcessing = false
    lastTemplateTime = ''
    batchUpdateStatusPollingInterval = undefined
    batchUpdateProcessing = false

    resultsList = []
    file = undefined

    get credentials() {
        const credentials = this.rootStore.marketStore.credentialsOptions || []
        const filtered = credentials.filter((credential) => {
            return this.availableMarkets.includes(credential.market)
        })

        return filtered
    }

    get checkedCredentials() {
        let credentialIds = []
        for (let i = 0; i < this.credentialList.length; i++) {
            if (this.credentialList[i].checked)
                credentialIds.push(this.credentialList[i].id)
        }
        return credentialIds
    }

    get lastTemplateTimeString() {
        return this.lastTemplateTime
            ? this.getDateLabel(this.lastTemplateTime, true, true, true)
            : ''
    }

    init = (onSuccess, onFail) => {
        this.setInitialCredentialList()
        this.requestTemplateStatus((data) => {
            if (data.status) {
                if (data.status === 'processing' || data.status === 'started')
                    this.startTemplateStatusPolling((data) => {
                        if (data.status) {
                            if (
                                data.status === 'finished' ||
                                data.status === 'failed' ||
                                data.status === 'unknown'
                            )
                                this.stopTemplateStatusPolling()
                        }
                    })
            }
        })
        this.requestBatchUpdateStatus((data) => {
            if (data.status) {
                if (data.status === 'processing' || data.status === 'started')
                    this.startBatchUpdateStatusPolling((data) => {
                        if (
                            data.status &&
                            data.status !== 'processing' &&
                            data.status !== 'started'
                        ) {
                            this.stopBatchUpdateStatusPolling()
                            if (data.id) this.getResultById(data.id)
                            if (data.status === 'finished') {
                                onSuccess()
                            } else if (data.status === 'failed') {
                                onFail()
                            }
                        }
                    })
            }
        })
    }

    uploadBatchUpdateFile(onSuccess, onFail) {
        const payload = {}
        if (this.file.ref) {
            payload.file = this.file.ref
        }
        this.postBatchUpdateFile(payload, (data) => {
            this.startBatchUpdateStatusPolling((data) => {
                if (
                    data.status &&
                    data.status !== 'processing' &&
                    data.status !== 'started'
                ) {
                    this.stopBatchUpdateStatusPolling()
                    this.deleteFile()
                    if (data.id) this.getResultById(data.id)
                    if (data.status === 'finished') {
                        onSuccess()
                    } else if (data.status === 'failed') {
                        onFail()
                    }
                }
            })
        })
    }

    setInitialCredentialList = () => {
        const credentialList = []
        for (let i = 0; i < this.credentials.length; i++) {
            const credential = {
                id: this.credentials[i].key,
                name: this.credentials[i].name,
                market: this.credentials[i].market,
                checked: false,
            }
            credentialList.push(credential)
        }

        // console.log('setInitialCredentialList:')
        // console.log(this.credentials)
        this.credentialList = credentialList
    }

    toggleCredential = (index) => {
        this.setCredentialChecked(index, !this.credentialList[index].checked)
    }

    setCredentialChecked = (index, value = true) => {
        this.credentialList[index].checked = value
    }

    onCredentialClick = (index) => {
        this.toggleCredential(index)
        this.checkIfGenerationNeeded()
    }

    setAllCredentialsUnchecked = () => {
        for (let i = 0; i < this.credentialList.length; i++) {
            this.setCredentialChecked(i, false)
        }
    }

    setDownloadReadyCredentials = (array) => {
        this.downloadReadyCredentials = array
    }

    setCheckedCredentialsFromSource = (indexArr) => {
        let checkedArr = []
        this.setAllCredentialsUnchecked()
        for (let i = 0; i < indexArr.length; i++) {
            for (let j = 0; j < this.credentialList.length; j++) {
                if (this.credentialList[j].id === indexArr[i]) {
                    this.setCredentialChecked(j)
                    checkedArr.push(indexArr[i])
                }
            }
        }
        this.setDownloadReadyCredentials(checkedArr)
    }

    setNeedGeneration = (value = true) => {
        this.needGeneration = value
    }

    checkIfGenerationNeeded = () => {
        const isEqual =
            this.checkedCredentials.length ===
                this.downloadReadyCredentials.length &&
            this.checkedCredentials.every(
                (val, index) => val === this.downloadReadyCredentials[index]
            )
        if (!isEqual || !this.checkedCredentials.length) {
            this.setNeedGeneration()
        } else {
            this.setNeedGeneration(false)
        }
    }

    generateTemplate = async () => {
        const payload = {
            marketplace_credential_ids: this.checkedCredentials,
        }

        try {
            const {data} =
                await CatalogService.generateBatchUpdateTemplate(payload)

            this.setNeedGeneration(false)
            this.startTemplateStatusPolling((data) => {
                if (data.status) {
                    if (data.status === 'finished')
                        this.stopTemplateStatusPolling()
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    startTemplateStatusPolling = (callback) => {
        if (this.templateStatusPollingInterval !== undefined)
            clearInterval(this.templateStatusPollingInterval)
        this.setTemplateProcessing()
        this.templateStatusPollingInterval = setInterval(
            () => this.requestTemplateStatus(callback),
            STATUS_POLLING_INTERVAL_MS
        )
    }

    stopTemplateStatusPolling = () => {
        clearInterval(this.templateStatusPollingInterval)
        this.templateStatusPollingInterval = undefined
        this.setTemplateProcessing(false)
    }

    setTemplateProcessing = (value = true) => {
        this.templateProcessing = value
    }

    downloadTemplate = async () => {
        const body = {
            marketplace_credential_ids: this.checkedCredentials,
        }
        const filename = `prices_and_stocks_${new Date().toString()}.xlsx`

        try {
            const {data} = await CatalogService.getBatchUpdateTemplate(body)
            FileDownload(data, filename)
        } catch (error) {
            console.log(error)
        }
    }

    setLastTemplateTime = (value) => {
        this.lastTemplateTime = value
    }

    requestTemplateStatus = async (callback) => {
        try {
            const {data} = await CatalogService.getBatchUpdateTemplateStatus()

            callback(data)
            if (data.marketplaceCredentialIds)
                this.setCheckedCredentialsFromSource(
                    data.marketplaceCredentialIds
                )
            this.checkIfGenerationNeeded()

            if (data.status && data.status === 'finished' && data.generatedAt) {
                this.setLastTemplateTime(data.generatedAt)
            } else {
                this.setLastTemplateTime('')
            }

            // console.log('requestTemplateStatus:')
            // console.log(data)
        } catch (error) {
            console.log(error)
        }
    }

    requestBatchUpdateStatus = async (callback) => {
        try {
            const {data} = await CatalogService.getBatchUpdateStatus()
            callback(data)

            // console.log('requestBatchUpdateStatus:')
            // console.log(data)
        } catch (error) {
            console.log(error)
        }
    }

    postBatchUpdateFile = async (payload, callback) => {
        try {
            const {data} = await CatalogService.postBatchUpdateFile({
                file: payload.file,
            })
            callback(data)

            // console.log('requestBatchUpdateStatus:')
            // console.log(data)
        } catch (error) {
            console.log(error)
        }
    }

    startBatchUpdateStatusPolling = (callback) => {
        if (this.batchUpdateStatusPollingInterval !== undefined)
            clearInterval(this.batchUpdateStatusPollingInterval)
        this.setBatchUpdateProcessing(true)
        this.batchUpdateStatusPollingInterval = setInterval(
            () => this.requestBatchUpdateStatus(callback),
            STATUS_POLLING_INTERVAL_MS
        )
    }

    stopBatchUpdateStatusPolling = () => {
        clearInterval(this.batchUpdateStatusPollingInterval)
        this.setBatchUpdateProcessing(false)
        this.batchUpdateStatusPollingInterval = undefined
    }

    setBatchUpdateProcessing = (value = true) => {
        this.batchUpdateProcessing = value
    }

    mergeResultData = (source) => {
        let result = {
            id: source.id,
            importDate: source.finishedAt,
            accounts: source.marketplaceCredentialIds.map((id) => {
                const cred = this.rootStore.marketStore.getCredentialByID(id)
                const market = this.rootStore.marketStore.getMarketNameById(
                    cred?.marketplaceId
                )
                const name = this.rootStore.marketStore.getCabinetNameById(id)
                return {
                    name,
                    market,
                }
            }),
            source: {
                name: source.feedUrl || source.filename,
                type: source.updateSource,
            },
            successCount: source.productsSuccess,
            errorCount: source.productsErrors,
        }
        this.resultsList.unshift(result)
    }

    getResultById = async (taskId) => {
        const body = {type: 'xlsx'}

        try {
            const {data} = await CatalogService.getBatchUpdateResultsList(body)

            if (data.results) {
                const result = data.results.find(
                    (result) => result.id === taskId
                )
                this.mergeResultData(result)
            }

            // console.log('getResultById:')
            // console.log(data)
        } catch (error) {
            console.log(error)
        }
    }

    getDateLabel = (date, yeared = false, timed = false, dayed = false) => {
        // return getDateLabel(new Date(date), yeared, timed, dayed)
        return date
    }

    addFileToImport = (file, formatCb) => {
        if (this.getFileNameExt(file.name).toLowerCase() !== 'xlsx') {
            if (typeof formatCb === 'function') formatCb()
            return
        }
        this.addFile('', file)
    }

    getFileNameExt = (filename) => {
        return filename.split('.').pop()
    }

    addFile(fileName = '', _file = undefined) {
        let file = new FileStore()
        file.setFileName(
            typeof _file !== 'undefined' ? _file.name : fileName || ''
        )
        file.setReference(_file)
        this.file = file
    }

    deleteFile = () => {
        this.file = undefined
    }

    downloadBatchUpdateValidationErrorsFile = async (
        resultId,
        originalFilename
    ) => {
        const filename = `${
            originalFilename
                ? originalFilename.split('.')[0]
                : `prices_and_stocks_${resultId}`
        }_errors.xlsx`
        try {
            const {data} =
                await CatalogService.getBatchUpdateValidationErrors(resultId)
            FileDownload(data, filename)
        } catch (e) {
            console.log(e)
        }
    }
}

export default StockXlsStore
