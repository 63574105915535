import ReactDOM from 'react-dom'
import 'react-tippy/dist/tippy.css'
import '@styles/index.css'
import App from './App'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'mobx-react'
import reportWebVitals from './reportWebVitals'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import S24rootStore from './store/RootStore'
import {ErrorBoundary, Error404} from '@components/errors'
import {configure} from 'mobx'
import {SkeletonTheme} from '@components/elements'
import {YMInitializer} from 'react-yandex-metrika'
import YandexAnalytics from '@utils/yandexAnalytics'
import {CookiesProvider} from 'react-cookie'
import {ConfirmCookies} from '@views/layouts/ConfirmCookies'

configure({
    enforceActions: 'never',
})

export const rootStore = new S24rootStore()

GoogleTagManager.initializeGtm()

const render = (Component) => {
    return ReactDOM.render(
        <Provider {...rootStore}>
            <BrowserRouter>
                <ErrorBoundary component={<Error404 />}>
                    {YandexAnalytics.checkIsProd() && (
                        <YMInitializer
                            accounts={[54947668]}
                            options={{webvisor: true}}
                            version='2'
                        />
                    )}
                    <SkeletonTheme>
                        <CookiesProvider>
                            <ConfirmCookies>
                                <Component />
                            </ConfirmCookies>
                        </CookiesProvider>
                    </SkeletonTheme>
                </ErrorBoundary>
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    )
}

render(App)

reportWebVitals()
