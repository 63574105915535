import {forwardRef} from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'

export const NameField = forwardRef(({name, className, onClick}, ref) => {
    return (
        <span
            ref={ref}
            className={classNames(
                className,
                'flex items-center justify-between space-x-2 whitespace-normal text-xs',
                {'dark:text-gray-300': typeof onClick !== 'function'},
                {
                    'cursor-pointer text-blue-400 underline hover:opacity-90 dark:text-blue-400':
                        typeof onClick === 'function',
                }
            )}
            onClick={onClick ? onClick : undefined}
        >
            {name ? name : 'Нет названия'}
        </span>
    )
})

NameField.displayName = 'NameField'
