import classNames from 'classnames'

export const ProgressBar = ({
    color = 'bg-blue-500 dark:bg-blue-400',
    bgColor = 'bg-gray-200 dark:bg-gray-500',
    value = 0,
    className,
}) => {
    return (
        <>
            <div
                className={classNames(
                    'h-3 w-full overflow-hidden rounded-full',
                    bgColor,
                    className
                )}
            >
                {value ? (
                    <div
                        className={classNames(
                            'flex h-3 items-center justify-center rounded-full text-gray-300 shadow-md dark:text-gray-700',
                            color
                        )}
                        style={{width: `${value}%`}}
                    >
                        <span className={'py-0.5 text-[10px]'}>{value}%</span>
                    </div>
                ) : (
                    <div className={'ml-2 flex h-3 items-center text-gray-800'}>
                        <span className={'py-0.5 text-[10px]'}>{value}%</span>
                    </div>
                )}
            </div>
        </>
    )
}
