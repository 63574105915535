import {forwardRef, useRef} from 'react'
import cn from 'classnames'
import {nanoid} from 'nanoid'

export const TextArea = forwardRef(
    (
        {
            defaultValue,
            className,
            labelClassName,
            textAreaClassName,
            helperClassName,
            helperText,
            rows,
            error,
            label,
            ...rest
        },
        ref
    ) => {
        const id = useRef(nanoid(5))
        return (
            <div className={className}>
                <label
                    htmlFor={id.current}
                    className='mb-1 block text-sm font-medium text-gray-700 dark:text-gray-300'
                >
                    {label}
                </label>
                <textarea
                    {...rest}
                    ref={ref}
                    rows={rows || 4}
                    id={id.current}
                    className={cn(
                        textAreaClassName,
                        'block w-full rounded-md border-gray-300 shadow-sm',
                        'focus:border-blue-500 sm:text-sm',
                        'dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600',
                        {
                            'border-red-500 dark:border-red-300': error,
                        }
                    )}
                    defaultValue={defaultValue}
                />
                {!!helperText && (
                    <p
                        className={cn(
                            'mt-1 text-sm text-gray-500 dark:text-gray-400',
                            {
                                'text-red-500 dark:text-red-300': error,
                            },
                            helperClassName
                        )}
                    >
                        {helperText}
                    </p>
                )}
            </div>
        )
    }
)
