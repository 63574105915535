import {forwardRef} from 'react'
import classNames from 'classnames'

export const AvailableTitle = forwardRef(({title, className}, ref) => {
    return (
        <h3
            ref={ref}
            className={classNames(
                className,
                'truncate text-base font-medium text-gray-800 dark:text-gray-200 sm:mb-4 sm:text-xl'
            )}
        >
            {title}
        </h3>
    )
})

AvailableTitle.displayName = 'AvailableTitle'
