import {
    LockedLimitTooltipContent,
    LockedTooltipContent,
} from '@components/elements'
import {TooltipContentWrapper} from '@components/applicationUi/Tooltip'

export const BaseLockedTooltipContent = ({
    isBlocked,
    isTrial,
    exceedProducts,
    exceedCabinets,
}) => {
    return (
        <TooltipContentWrapper>
            {isBlocked && <LockedTooltipContent />}
            {isTrial && (
                <LockedTooltipContent
                    title={
                        'Для доступа к полному функционалу выберите и оплатите тариф.'
                    }
                />
            )}
            {exceedProducts && <LockedLimitTooltipContent isProducts />}
            {exceedCabinets && <LockedLimitTooltipContent />}
        </TooltipContentWrapper>
    )
}
