export const TOAST_ONBOARDING_MAP = {
    market: (
        <div className={'flex flex-col space-y-4'}>
            <p>
                Приветствуем вас на платформе Seller24! Вы можете пользоваться
                отчетами с реальными данными ваших кабинетов бесплатно на
                протяжении 3-х дней, чтобы быстро оценить текущее состояние
                вашего бизнеса на маркетплейсах и найти его точки роста.
            </p>
            <p>
                Чтобы начать полноценно работать с платформой, вам нужно
                подключить свои кабинеты на маркетплейсах. Нажмите “подключить”
                в карточке вашего маркетплейса и следуйте инструкциям.
            </p>
        </div>
    ),
    marketAdded: (
        <div className={'flex flex-col space-y-4'}>
            <p>
                Кабинет подключен! Данные будут подгружаться 5-10 минут, а пока
                вы можете ознакомиться с небольшим обучающим роликом по главному
                отчету каждого селлера - отчету по маржинальной прибыли.
                Рекомендуем начать работу именно с него.
            </p>
            <a
                href={'https://www.youtube.com/watch?v=GE8G8h7DLQY'}
                target={'_blank'}
                className={
                    'cursor-pointer text-blue-500 underline hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-200'
                }
            >
                Смотреть видео
            </a>
        </div>
    ),
    importError: (
        <div className={'flex flex-col space-y-4'}>
            <p>Кажется, что-то пошло не так, и импорт завершился неудачно :(</p>
            <p>
                Попробуйте удалить кабинет в статусе "Не подключен", проверьте,
                все ли верно вы сделали по инструкции, и подключить кабинет
                снова. Если опять не получится - напишите в наш чат поддержки
                (кнопка в меню слева), мы поможем!
            </p>
        </div>
    ),
    importSuccess: (
        <div className={'flex flex-col space-y-4'}>
            <p>
                Импорт успешно завершен! Чтобы вам проще было разобраться в
                платформе, приглашаем вас на наш бесплатный интенсив в
                телеграме: на нем вы узнаете, как с помощью наших отчетов
                повышать прибыль с ваших продаж, считать юнит-экономику,
                повышать индекс локализации и многое другое!
            </p>
            <p>
                Присоединяйтесь:{' '}
                <a
                    className={
                        'cursor-pointer whitespace-pre text-sm font-medium text-blue-500 hover:text-blue-400 dark:text-blue-300 dark:hover:text-blue-200'
                    }
                    target={'_blank'}
                    href={`https://t.me/s24_intensiv_bot`}
                >
                    Интенсив от Seller24
                </a>
            </p>
        </div>
    ),
    coastAdded: (
        <div className={'flex flex-col space-y-4'}>
            <p>
                Поздравляем! Теперь вы полностью готовы к работе с платформой. В
                каждом отчете есть кнопка “как работать с разделом”, где мы
                подробно рассказываем, как извлекать пользу из этих отчетов.
            </p>
            <p>
                А также, мы предлагаем провести аудит вашего магазина, на
                котором наши аналитики с опытом работы на маркетплейсах
                проанализируют ваш магазин и дадут свои рекомендации по главным
                точкам роста. Первый аудит для вас сделаем бесплатно 😊
            </p>
        </div>
    ),
}
