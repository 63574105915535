import {QuestionMarkCircleIcon} from '@heroicons/react/outline'

import {observer} from 'mobx-react'
import {openChat} from '@utils/helpers'

const SupportMenu = observer(() => {
    // const supportOverlayStore = useSelector(
    //     (store) => store.supportOverlayStore
    // )

    const handleClickSupport = () => {
        openChat()
    }

    return (
        <>
            <button onClick={handleClickSupport}>
                <QuestionMarkCircleIcon
                    className={'h-6 w-6 text-gray-400 hover:text-gray-500'}
                />
            </button>
            {/*<SupportOverlay />*/}
        </>
    )
})

export default SupportMenu
