import {ApiFactory} from '@services/ApiFactory'
import {CatsMatchingUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'

const catsMatchingInstance = ApiFactory.createApi(baseConfig)

class CatsMatchingService {
    static getMasterUnlinkedCats = async () => {
        return await catsMatchingInstance.get(
            CatsMatchingUrl.masterUnlinkedCatsV2
        )
    }
    static postMasterUnlinkedCats = async (payload) => {
        return await catsMatchingInstance.post(
            CatsMatchingUrl.masterUnlinkedCatsV2,
            payload
        )
    }
    static getMasterUnlinkedHeader = async () => {
        return await catsMatchingInstance.get(
            CatsMatchingUrl.masterUnlinkedHeader
        )
    }

    static getMasterTopCats = async (payload) => {
        return await catsMatchingInstance.post(
            CatsMatchingUrl.masterTopCats,
            payload
        )
    }

    static getMasterCatId = async (payload, id) => {
        return await catsMatchingInstance.post(
            CatsMatchingUrl.masterCatId(id),
            payload
        )
    }

    static postMarketplaces = async (payload) => {
        return await catsMatchingInstance.post(CatsMatchingUrl.market, payload)
    }
    static getMarketplaces = async () => {
        return await catsMatchingInstance.get(CatsMatchingUrl.market)
    }

    static postMarketTopCats = async (payload, marketId) => {
        return await catsMatchingInstance.post(
            CatsMatchingUrl.marketTopCats(marketId),
            payload
        )
    }

    static postMarketCatId = async (payload, marketId, catId) => {
        return await catsMatchingInstance.post(
            CatsMatchingUrl.marketCatId(marketId, catId),
            payload
        )
    }

    static postCatsAssign = async (body) => {
        return await catsMatchingInstance.post(CatsMatchingUrl.catsAssign, body)
    }
}

export default CatsMatchingService
