import Screen1Light from './images/screen-1_light.jpg'
import Screen2Light from './images/screen-2_light.jpg'
import Screen3Light from './images/screen-3_light.jpg'
import Screen4Light from './images/screen-4_light.jpg'
import Screen4_1Light from './images/screen-4.1_light.jpg'
import Screen5Light from './images/screen-5_light.jpg'
import Screen6Light from './images/screen-6_light.jpg'
import Screen7Light from './images/screen-7_light.jpg'
import Screen8Light from './images/screen-8_light.jpg'
import Screen9Light from './images/screen-9_light.jpg'
import Screen10Light from './images/screen-10_light.jpg'
import {ImagesPreview, RutubePlayer} from '@components/elements'

export const ImportWbInfoLink = ({isDark}) => {
    return (
        <ImagesPreview>
            <div className={'w-full space-y-4 text-sm'}>
                <RutubePlayer
                    src={
                        'https://rutube.ru/video/87c80e67dc6a0eeded0ab9ddcb33a04f/'
                    }
                />
                <p className={'dark:text-gray-200'}>
                    Раздел импорта еженедельных отчетов Wildberries предназначен
                    для более полного отображения данных о продажах ( выручка,
                    комиссия, логистика и т.д), не дожидаясь публикации этих
                    отчетов в Api со стороны маркетплейса. Такой метод загрузки
                    данных является наиболее точным, поскольку в Api
                    отображаются не все статьи затрат. От текущей даты получить
                    статистику через Excel можно за предыдущие 90 дней. Если в
                    системе будет отсутствовать какой-либо отчет, источником
                    данных будет служить Api.
                </p>
                <p className={'dark:text-gray-200'}>
                    <p>
                        <strong>Терминология:</strong>
                    </p>
                    <p>
                        Листинг - общий список еженедельных фин.отчетов,
                        представлен в xls формате Детализация- еженедельный
                        фин.отчет, скачивается в ZIP формате. За одну неделю
                        бывает две детализации.
                    </p>
                </p>
                <p className={'dark:text-gray-200'}>
                    <p>
                        <strong>Этапы работы с разделом:</strong>
                    </p>
                    <ul
                        className={'ml-4 mt-2 list-decimal space-y-2'}
                        role='list'
                    >
                        <li>Добавить API ключ на платформу Seller24</li>
                        <li>Дождаться завершения импорта</li>
                        <li>
                            В выпадающем списке выбрать кабинет, по которому
                            хотите произвести загрузку
                        </li>
                        <li>Нажать “Загрузить фин.отчеты Excel”</li>
                        <li>Привязать юр.лицо к кабинету</li>
                        <li>Загрузить листинг фин.отчетов</li>
                        <li>
                            Загрузить детализации, не более 10 файлов за одну
                            загрузку
                        </li>
                        <li>
                            Закрыть окно загрузки и убедиться, что в разделе
                            “Импорт отчетов WB” сформировался список отчетов и
                            все детализации загружены
                        </li>
                        <li>
                            Запустить пересчет данных через эксель, выбрав
                            нужный период
                        </li>
                        <li>Дождаться завершения импорта</li>
                    </ul>
                </p>
                <p className={'dark:text-gray-200'}>
                    <strong>
                        Если Вы только что подключили Api- ключ к платформе
                        Seller24 важно дождаться завершения основного импорта.
                        Проверить можно в разделе “Подключения”. Если ключ Api
                        не подключен, воспользоваться только ручной загрузкой
                        детализации не выйдет.
                    </strong>
                </p>
                <p className={'dark:text-gray-200'}>
                    <strong>Пример: импорт еще не завершился,</strong>{' '}
                    приступать к загрузке Excel нельзя
                </p>
                <p className={'space-y-4 dark:text-gray-200'}>
                    <div className={'flex items-center justify-center'}>
                        <img
                            className={'max-w-[400px]'}
                            src={isDark ? Screen1Light : Screen1Light}
                            alt={''}
                        />
                    </div>
                </p>
                <p className={'dark:text-gray-200'}>
                    <strong>Пример: импорт завершен</strong> и можно приступать
                    к загрузке Excel
                </p>
                <p className={'space-y-4 dark:text-gray-200'}>
                    <div className={'flex items-center justify-center'}>
                        <img
                            className={'max-w-[400px]'}
                            src={isDark ? Screen2Light : Screen2Light}
                            alt={''}
                        />
                    </div>
                </p>
                <p className={'dark:text-gray-200'}>
                    Для начала работы с разделом выберите кабинет и нажмите
                    кнопку “Загрузите Фин.отчеты Excel”
                </p>
                <p className={'space-y-4 dark:text-gray-200'}>
                    <div className={'flex items-center justify-center'}>
                        <img
                            className={'max-w-[400px]'}
                            src={isDark ? Screen3Light : Screen3Light}
                            alt={''}
                        />
                    </div>
                </p>
                <p className={'dark:text-gray-200'}>
                    При первой загрузке, чтобы исключить возможность ошибочного
                    добавления отчета от другого кабинета, необходимо привязать
                    выбранный кабинет к юр.лицу.{' '}
                    <strong>Скопируйте название юр.лица из ЛК ВБ</strong> ( в
                    правом верхнем углу) без дополнительных пробелов и символов{' '}
                    <strong>или из листинга фин.отчетов,</strong> выделив мышкой
                    текстовую часть, а не полностью ячейку и вставьте его в поле
                    “Юр.лицо”.
                </p>
                <p className={'space-y-4 dark:text-gray-200'}>
                    <div className={'flex items-center justify-center'}>
                        <img
                            className={'max-w-[400px]'}
                            src={isDark ? Screen4_1Light : Screen4_1Light}
                            alt={''}
                        />
                    </div>
                </p>
                <p className={'dark:text-gray-200'}>
                    Вставьте название юр.лица в поле
                </p>
                <p className={'space-y-4 dark:text-gray-200'}>
                    <div className={'flex items-center justify-center'}>
                        <img
                            className={'max-w-[400px]'}
                            src={isDark ? Screen4Light : Screen4Light}
                            alt={''}
                        />
                    </div>
                </p>
                <p className={'dark:text-gray-200'}>
                    Если юр.лицо, отраженное в еженедельных финансовых отчетах
                    не совпадает, с тем, что Вы указываете в платформе, система
                    выдаст оповещение об ошибке. :
                </p>
                <p className={'space-y-4 dark:text-gray-200'}>
                    <div className={'flex items-center justify-center'}>
                        <img
                            className={'max-w-[400px]'}
                            src={isDark ? Screen5Light : Screen5Light}
                            alt={''}
                        />
                    </div>
                </p>
                <p className={'mt-4 dark:text-gray-200'}>
                    Подобное оповещение возникнет, если Вы будете загружать не
                    свой фин.отчет или файл с измененным названием. Наименование
                    файла должно строго оставаться таким, какое оно было при
                    скачивании из ЛК ВБ.
                </p>
                <p className={'space-y-4 dark:text-gray-200'}>
                    <div className={'flex items-center justify-center'}>
                        <img
                            className={'max-w-[400px]'}
                            src={isDark ? Screen6Light : Screen6Light}
                            alt={''}
                        />
                    </div>
                </p>
                <p className={'dark:text-gray-200'}>
                    После того, как Вы привязали юр.лицо к кабинету, загрузили
                    листинг и фин.отчеты, необходимо пересчитать данные.
                    Выберите приоритетный источник данных и нажмите кнопку
                    “Запустить пересчет данных”
                </p>
                <p className={'space-y-4 dark:text-gray-200'}>
                    <div className={'flex items-center justify-center'}>
                        <img
                            className={'max-w-[400px]'}
                            src={isDark ? Screen7Light : Screen7Light}
                            alt={''}
                        />
                    </div>
                </p>
                <p className={'dark:text-gray-200'}>
                    Если все сделано верно, во второй половине экрана появится
                    листинг( список) фин.отчетов, загруженных в систему.
                </p>
                <p className={'dark:text-gray-200'}>
                    <strong>Важно отметить,</strong> что каждую неделю, после
                    того, как Wildberries опубликует новые фин. отчеты, листинг
                    необходимо загружать повторно, чтобы новые номера
                    отобразились в системе. Загрузку файлов за прошлую неделю
                    лучше всего осуществлять в любой день, кроме понедельника -
                    это связано с техническими особенностями обработки
                    информации о заказах, которую мы получаем от Wildberries
                    через Api и сопоставляем с данными из Excel.
                </p>
                <p className={'dark:text-gray-200'}>
                    <strong>Все загружено верно.</strong> Два зеленых кружка
                    говорят о том, что: отчеты загружены и данные совпадают с
                    Api:
                </p>
                <p className={'space-y-4 dark:text-gray-200'}>
                    <div className={'flex items-center justify-center'}>
                        <img
                            className={'max-w-[400px]'}
                            src={isDark ? Screen8Light : Screen8Light}
                            alt={''}
                        />
                    </div>
                </p>
                <p className={'dark:text-gray-200'}>
                    <strong>Не достает какого-либо фин. отчета.</strong> Серый
                    кружок говорит о том, что: необходимо дозагрузить фин.отчет
                    за указанный период, т.к. по нему нет данных. Если за неделю
                    было два фин. отчета, то для того, чтобы понять, какой
                    именно отсутствует в системе достаточно нажать на синюю
                    стрелочка рядом с номерами отчетов:
                </p>
                <p className={'space-y-4 dark:text-gray-200'}>
                    <div className={'flex items-center justify-center'}>
                        <img
                            className={'max-w-[400px]'}
                            src={isDark ? Screen9Light : Screen9Light}
                            alt={''}
                        />
                    </div>
                </p>
                <p className={'dark:text-gray-200'}>
                    <p>
                        <strong>
                            Данные из фин.отчета не совпадают с данными из Api.
                        </strong>
                    </p>
                    <p>
                        Если данные, полученные через Api отличаются от данных
                        загруженных через Excel, выводим уведомление около
                        желтого треугольника. Рекомендуем продолжать загружать
                        фин.отчеты по последующим неделям для повышения точности
                        данных.
                    </p>
                </p>
                <p className={'space-y-4 dark:text-gray-200'}>
                    <div className={'flex items-center justify-center'}>
                        <img
                            className={'max-w-[400px]'}
                            src={isDark ? Screen10Light : Screen10Light}
                            alt={''}
                        />
                    </div>
                </p>
                <p className={'dark:text-gray-200'}>
                    Сверить загруженную информацию обо всех начислениях из фин.
                    отчетов можно в разделе “Сводные финансы”.
                </p>
                <p className={'dark:text-gray-200'}>
                    <p>
                        <strong>Данные не сходятся</strong>
                    </p>
                    <p>
                        В случае каких-либо расхождений сообщите о проблеме
                        своему менеджеру или напишите в тех.поддержку. Мы
                        проверим информацию и свяжемся с Вами после получения
                        результата от сверки.
                    </p>
                </p>
                <p className={'dark:text-gray-200'}>
                    <p>
                        <strong>Причины расхождений:</strong>
                    </p>
                    <ul className={'ml-4 mt-2 list-disc space-y-2'} role='list'>
                        <li>В ЛК WB были удалены артикулы или баркода</li>
                        <li>Импорт из эксель не успел пройти</li>
                    </ul>
                </p>
            </div>
        </ImagesPreview>
    )
}
