import {useMemo, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {SelectorIcon} from '@heroicons/react/solid'
import classNames from 'classnames'
import {usePopper} from 'react-popper'
import cn from 'classnames'
import SelectBody from '@components/forms/Selects/Select/SelectBody'
import {SelectPortal} from '@components/forms/Selects/Select/SelectPortal'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'

const Select = ({
    label,
    className,
    selected,
    renderName,
    portal = false,
    selectedNode,
    selectClassName,
    placeholder,
    options,
    disabled,
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    onClick = () => {},
    children,
    helperText,
    error,
    isLoadingData = false,
    isNextLoadData = false,
    fetchSearch = false,
    defaultSearch = true,
    onFetchSearch,
    defaultAbsence = true,
    optionLabelKey = 'name',
    emptySearchTitle,
    size = 'base',
}) => {
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                },
            },
        ],
    })

    useMemo(() => {
        if (error && helperText) {
            GoogleTagManager.dataLayer({
                event: 'mainEvent',
                eventCategory: 'Message - Front',
                eventAction: 'message',
                eventLabel: helperText,
                // email: userData.email,
                // user_id: userData.user_id,
            })
        }
    }, [error, helperText])

    const selectBodyProps = {
        setPopperElement,
        referenceElement,
        styles,
        attributes,
        options,
        size,
        portal,
        fetchSearch,
        onFetchSearch,
        isLoadingData,
        isNextLoadData,
        optionLabelKey,
        defaultSearch,
        emptySearchTitle,
        childElement: children,
    }

    // const selectedOptionsName = useMemo(() => {
    //     return (
    //         selectedOptions
    //             .map((option) => {
    //                 if (typeof option === 'object') return option.name
    //                 return option
    //             })
    //             .join(', ') || ''
    //     )
    // }, [selectedOptions])

    return (
        <Listbox value={selected} onChange={onChange} disabled={disabled}>
            {({open}) => (
                <div className={cn(className, {'w-full': defaultAbsence})}>
                    {label?.length && (
                        <Listbox.Label
                            className={cn(
                                'mb-1 block text-sm font-medium text-gray-700 dark:text-gray-200',
                                {
                                    'opacity-50': disabled,
                                }
                            )}
                        >
                            {label}
                        </Listbox.Label>
                    )}
                    <div className='relative w-full'>
                        <Listbox.Button
                            ref={setReferenceElement}
                            className={classNames(
                                selectClassName,
                                'relative flex w-full cursor-pointer justify-between rounded-md border border-gray-300 bg-white text-left shadow-sm transition-all focus:outline-none',
                                'dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200',
                                {
                                    'min-h-[38px]': defaultAbsence,
                                    'border-red-500 dark:border-red-300': error,
                                    'opacity-50': disabled,
                                    'dark:hover:bg-gray-600': !disabled,
                                    'py-1.5 pl-3 pr-2.5 text-xs':
                                        size === 'sm' && defaultAbsence,
                                    'py-2 pl-4 pr-3 text-sm font-medium leading-4':
                                        size === 'md' && defaultAbsence,
                                    'py-3 pl-5 pr-4 text-sm font-medium':
                                        size === 'base' && defaultAbsence,
                                    'py-3 pl-5 pr-4 text-base font-medium':
                                        size === 'lg' && defaultAbsence,
                                    'py-3 pl-7 pr-6 text-base font-medium':
                                        size === 'xl' && defaultAbsence,
                                }
                            )}
                            onFocus={onFocus}
                            onClick={onClick}
                            onBlur={onBlur}
                            disabled={disabled}
                        >
                            {selectedNode || (
                                <span
                                    className={cn('block truncate', {
                                        'text-gray-300':
                                            placeholder && !selected?.name,
                                    })}
                                >
                                    {renderName && selected
                                        ? renderName(selected)
                                        : selected?.name || placeholder}
                                </span>
                            )}
                            <span className='pointer-events-none ml-2 flex items-center'>
                                <SelectorIcon
                                    className='h-5 w-5 text-gray-400 dark:text-gray-200'
                                    aria-hidden='true'
                                />
                            </span>
                        </Listbox.Button>
                        {!!helperText && (
                            <p
                                className={cn(
                                    'mt-2 text-sm text-gray-500 dark:text-gray-400',
                                    {
                                        'text-red-500 dark:text-red-300': error,
                                    }
                                )}
                            >
                                {helperText}
                            </p>
                        )}

                        <Transition
                            show={open}
                            as={'div'}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            {portal ? (
                                <SelectPortal {...selectBodyProps} />
                            ) : (
                                <SelectBody {...selectBodyProps} />
                            )}
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    )
}

export {Select}
