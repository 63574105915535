import {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XIcon} from '@heroicons/react/outline'
import {Seller24Icon} from '@components/elements/Icon/Icon'
import NavigationRow from '@components/navigation/sidebar/components/NavigationRow'

const SidebarMobile = ({navigation, setSidebarOpen, sidebarOpen}) => {
    return (
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
                as='div'
                className='fixed inset-0 z-40 flex md:hidden'
                onClose={setSidebarOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter='transition-opacity ease-linear duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='transition-opacity ease-linear duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter='transition ease-in-out duration-300 transform'
                    enterFrom='-translate-x-full'
                    enterTo='translate-x-0'
                    leave='transition ease-in-out duration-300 transform'
                    leaveFrom='translate-x-0'
                    leaveTo='-translate-x-full'
                >
                    <div className='relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pb-4 pt-5 dark:bg-gray-700'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-in-out duration-300'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='ease-in-out duration-300'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='absolute right-0 top-0 -mr-12 pt-2'>
                                <button
                                    type='button'
                                    className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none'
                                    onClick={() => setSidebarOpen(false)}
                                >
                                    <span className='sr-only'>
                                        Close sidebar
                                    </span>
                                    <XIcon
                                        className='h-6 w-6 text-white'
                                        aria-hidden='true'
                                    />
                                </button>
                            </div>
                        </Transition.Child>
                        <div className='flex flex-shrink-0 items-center px-4'>
                            <Seller24Icon className='h-7 w-auto fill-current text-white' />
                        </div>
                        <div className='mt-8 h-0 flex-1 overflow-y-auto'>
                            <nav className='space-y-1 px-2'>
                                {Object.keys(navigation).map((key, index) => (
                                    <React.Fragment key={key}>
                                        {navigation[key].map((row) => (
                                            <NavigationRow
                                                key={row.name}
                                                row={row}
                                                onClick={() => {
                                                    row?.onClick?.()
                                                    setSidebarOpen(false)
                                                }}
                                            />
                                        ))}
                                        {index <
                                            Object.keys(navigation).length -
                                                1 && (
                                            <div
                                                key={index}
                                                className={'w-full p-2'}
                                            >
                                                <div
                                                    className={
                                                        'border-b border-gray-900'
                                                    }
                                                />
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}
                            </nav>
                        </div>
                    </div>
                </Transition.Child>
                <div className='w-14 flex-shrink-0' aria-hidden='true'>
                    {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export {SidebarMobile}
