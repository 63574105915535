import {forwardRef} from 'react'
import InputMask from 'react-input-mask'
import {TextField} from '@components/forms/TextFields'

const TextFieldMask = forwardRef(
    (
        {
            mask,
            onChange,
            onPaste,
            onMouseDown,
            onFocus,
            onBlur,
            value,
            disabled,
            readOnly,
            ...props
        },
        ref
    ) => {
        return (
            <InputMask
                mask={mask}
                maskChar={null}
                formatChars={{
                    9: /[0-9]/i,
                    a: /[А-Яа-яA-Za-z]/i,
                    '*': /[А-Яа-яA-Za-z0-9]/i,
                }}
                onChange={onChange}
                onPaste={onPaste}
                onMouseDown={onMouseDown}
                onFocus={onFocus}
                onBlur={onBlur}
                value={value}
                disabled={disabled}
                readOnly={readOnly}
                {...props}
            >
                {(inputProps) => {
                    return (
                        <TextField
                            ref={ref}
                            {...inputProps}
                            onChange={onChange}
                            onPaste={onPaste}
                            onMouseDown={onMouseDown}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            value={value}
                            disabled={disabled}
                            readOnly={readOnly}
                        />
                    )
                }}
            </InputMask>
        )
    }
)

export {TextFieldMask}
