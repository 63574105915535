import {Portal} from '@components/elements'
import {OverlayBody} from '@components/overlays/slideOvers/Overlay/OverlayBody'
import {OverlayContext} from '@components/overlays/slideOvers/Overlay/OverlayContext'
import {useScrollLock} from '@hooks'

export const Overlay = ({children, ...props}) => {
    useScrollLock(props.open)
    return (
        <Portal>
            <OverlayContext.Provider
                value={{open: props.open, onClose: props.onClose, ...props}}
            >
                <OverlayBody {...props}>{children}</OverlayBody>
            </OverlayContext.Provider>
        </Portal>
    )
}
