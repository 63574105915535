import TagManager from 'react-gtm-module'
import {TokenService} from '@services/TokenService'

class GoogleTagManager {
    static gtmId = ''

    static checkIsProd = () => {
        if (import.meta.env.MODE === 'production') {
            const parts = window.location.host.split('.')
            if (parts[0] === 'app') {
                GoogleTagManager.gtmId = import.meta.env.VITE_APP_GTM_APP_KEY
                return true
            }
            if (parts[0] === 'dev') {
                GoogleTagManager.gtmId = import.meta.env.VITE_APP_GTM_DEMO_KEY
                return true
            }
        }
        GoogleTagManager.gtmId = import.meta.env.VITE_APP_GTM_DEMO_KEY
        return false
    }

    static initializeGtm = () => {
        if (GoogleTagManager.checkIsProd()) {
            TagManager.initialize({gtmId: GoogleTagManager.gtmId})
        }
    }

    static dataLayer = (event) => {
        if (TokenService.isEmulationExists()) return
        if (GoogleTagManager.checkIsProd()) {
            window.dataLayer && window.dataLayer.push(event)
        }
    }
}

export default GoogleTagManager
