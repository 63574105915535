import {useEffect, useMemo} from 'react'
import {observer} from 'mobx-react'
import {CheckIcon, ExclamationIcon, RefreshIcon} from '@heroicons/react/outline'
import classNames from 'classnames'
import {Accordion, Button} from '@components/elements'
import {Loader} from '@components/elements/Loader'
import {STATUSES_FILE_MAP, xlsStatus} from '@utils/constants'

export const CheckFileStatuses = observer(
    ({uploadFileStore, updateHistory, downloadFileStore}) => {
        useEffect(() => {
            uploadFileStore.uploadStatusPolling.startPolling(
                uploadFileStore.uploadTaskUuid
            )
            return () => {
                uploadFileStore.uploadStatusPolling.stopPolling()
            }
        }, [])

        const status = useMemo(() => {
            const {statistics, uploadMessage} = uploadFileStore.uploadStatus
            const errorText = []
            if (uploadFileStore.uploadStatus.status === xlsStatus.failed) {
                errorText.push('Файл загружен с ошибкой')
            }
            if (statistics?.errorsText?.length) {
                errorText.push(...statistics?.errorsText)
            }
            return {
                errors: statistics?.errors || 0,
                updated: statistics?.updated || 0,
                isErrorParse:
                    uploadFileStore.uploadStatus.status === xlsStatus.failed ||
                    errorText.length,
                uploadMessage: uploadMessage,
                errorTextStr: errorText.join('. '),
                errorText: errorText,
            }
        }, [uploadFileStore.uploadStatus])

        const handleClickDownload = () => {
            downloadFileStore.getDownload(uploadFileStore.uploadTaskUuid)
        }

        const handleClickToFileDrop = () => {
            uploadFileStore.statusLoaderStore.setLoading(true)
            uploadFileStore.setStatuses(STATUSES_FILE_MAP.fileDrop)
            uploadFileStore.setUploadTaskUuid('')
            if (typeof updateHistory === 'function') {
                updateHistory()
            }
        }

        return (
            <div className={'flex flex-col space-y-4'}>
                <div className={'flex items-center space-x-4'}>
                    {uploadFileStore.statusLoaderStore.loading && (
                        <RefreshIcon
                            className={
                                'h-10 w-10 flex-shrink-0 animate-spin text-blue-500'
                            }
                        />
                    )}
                    {!uploadFileStore.statusLoaderStore.loading &&
                    !status.isErrorParse &&
                    !status.errors ? (
                        <CheckIcon
                            className={'h-10 w-10 flex-shrink-0 text-green-500'}
                        />
                    ) : (
                        ''
                    )}
                    {!uploadFileStore.statusLoaderStore.loading &&
                    status.isErrorParse ? (
                        <ExclamationIcon
                            className={
                                'h-10 w-10 flex-shrink-0 text-yellow-500 dark:text-yellow-400'
                            }
                        />
                    ) : (
                        ''
                    )}
                    <div className={'flex flex-col'}>
                        {/*{!status.isErrorParse && (*/}
                        <div className={'flex space-x-2'}>
                            <span>
                                {uploadFileStore.statusLoaderStore.loading
                                    ? 'Данные загружаются.'
                                    : 'Загрузка данных завершена.'}
                            </span>
                            <span>{`Обновлено товаров: ${status.updated}.`}</span>
                            <span
                                className={classNames({
                                    'text-red-800 dark:text-red-400':
                                        status.errors,
                                })}
                            >{`Ошибок: ${status.errors}.`}</span>
                        </div>
                        {/*)}*/}
                        {/*{status.isErrorParse && status.errorTextStr}*/}
                        {!!status.errors && downloadFileStore ? (
                            <div className={'flex space-x-2'}>
                                <span>
                                    Товары, по которым возникли ошибки, указаны{' '}
                                    <span
                                        className={
                                            'cursor-pointer text-gray-700 underline hover:opacity-75 dark:text-gray-100'
                                        }
                                        onClick={handleClickDownload}
                                    >
                                        {downloadFileStore
                                            .downloadFileLoaderStore.loading ? (
                                            <Loader sm />
                                        ) : (
                                            'в файле'
                                        )}
                                    </span>
                                </span>
                            </div>
                        ) : null}
                        {status.uploadMessage && status.isErrorParse ? (
                            <div className={'flex flex-col space-y-4'}>
                                <span>{`Описание: ${status.uploadMessage}`}</span>
                                <Accordion triggerContent={'Подробнее'}>
                                    <div className={'flex flex-col'}>
                                        <ul role='list'>
                                            {status.errorText.map(
                                                (text, idx) => {
                                                    return (
                                                        <li key={idx}>
                                                            {text}
                                                        </li>
                                                    )
                                                }
                                            )}
                                        </ul>
                                    </div>
                                </Accordion>
                            </div>
                        ) : null}
                    </div>
                </div>
                <Button
                    className={'w-full xl:w-1/2'}
                    onClick={handleClickToFileDrop}
                >
                    Загрузить новый файл
                </Button>
            </div>
        )
    }
)
