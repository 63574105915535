import {Card, ContainerFullOnMobile} from '@components/layout'
import {useMemo} from 'react'
import {LockOpenIcon} from '@heroicons/react/outline'
import {Button, ImagesPreview} from '@components/elements'
import FeedbackService from '@services/FeedbackService/FeedbackService'
import {observer} from 'mobx-react'
import {toast} from 'react-toastify'
import {SUCCESS_SUPPORT_MESSAGE_SEND} from '@utils/constants'
import {Loader} from '@components/elements/Loader'
import {useHistory} from 'react-router-dom'
import {AppPath} from '@routesEnums'
import {useSelector} from '@hooks'

export const PathBlockedStub = observer(({pathData, loading}) => {
    const history = useHistory()
    const themeStore = useSelector((store) => store.themeStore)
    const userStore = useSelector((store) => store.userStore)
    const requestCall = async () => {
        const payload = {
            to: 'support@seller24.ru',
            request_type: 'request_manager_call',
            title: `Пользователь запросил звонок менеджера из платформы`,
            fields: [
                {
                    title: 'phone',
                    value: userStore.userData?.phone_number?.replace(
                        /[ \-()]/g,
                        ''
                    ),
                },
                {
                    title: 'email',
                    value: userStore.userData?.email || '',
                },
            ],
        }
        try {
            await FeedbackService.postMessage(payload)
            toast(SUCCESS_SUPPORT_MESSAGE_SEND, {
                type: 'success',
                autoClose: 10000,
            })
        } catch (e) {
            console.log(e)
            toast('Заявка на звонок отправлена с ошибкой', {type: 'error'})
        }
    }

    const imageSrc = useMemo(() => {
        if (typeof pathData?.img === 'string') {
            return pathData.img.includes('https')
                ? pathData.img
                : `https://${pathData.img}`
        }
        if (!pathData?.img?.dark && !pathData?.img?.light) return ''
        if (themeStore.isDarkTheme && pathData?.img?.dark)
            return pathData.img.dark
        return pathData.img.light
    }, [pathData?.img, themeStore.isDarkTheme])

    return (
        <ContainerFullOnMobile className='space-y-6 py-6'>
            <Card>
                <div
                    className={
                        'relative mx-auto flex h-full min-h-[300px] max-w-screen-2xl flex-col justify-between gap-6'
                    }
                >
                    {loading ? (
                        <div
                            className={
                                'flex h-[300px] items-center justify-center'
                            }
                        >
                            <Loader overlayed={false} />
                        </div>
                    ) : (
                        <ImagesPreview>
                            <div className={'flex h-full flex-col gap-4'}>
                                <h2 className='mb-2 text-center text-2xl font-medium text-gray-800 dark:text-gray-200'>
                                    {pathData.title}
                                </h2>
                                <div
                                    className={
                                        'grid grid-cols-1 gap-6 py-4 lg:grid-cols-2'
                                    }
                                >
                                    <div
                                        className={
                                            'flex flex-col justify-between gap-4'
                                        }
                                    >
                                        <div className={'flex flex-col gap-2'}>
                                            {pathData.lockedItems.length
                                                ? pathData.lockedItems.map(
                                                      (item, idx) => {
                                                          return (
                                                              <div
                                                                  key={idx}
                                                                  className={
                                                                      'flex items-center gap-2'
                                                                  }
                                                              >
                                                                  <LockOpenIcon
                                                                      className={
                                                                          'h-5 w-5 flex-shrink-0 text-gray-600 dark:text-gray-400'
                                                                      }
                                                                  />
                                                                  <span
                                                                      className={
                                                                          'text-gray-600 dark:text-gray-400'
                                                                      }
                                                                  >
                                                                      {item}
                                                                  </span>
                                                              </div>
                                                          )
                                                      }
                                                  )
                                                : null}
                                        </div>
                                        <div className={'flex flex-col gap-4'}>
                                            <Button
                                                className={'w-full'}
                                                onClick={() =>
                                                    history.push(
                                                        AppPath.accountTariff
                                                    )
                                                }
                                            >
                                                Перейти к выбору тарифа
                                            </Button>
                                            <Button
                                                className={'w-full'}
                                                color={'white'}
                                                onClick={requestCall}
                                            >
                                                Запросить демо у менеджера
                                            </Button>
                                        </div>
                                    </div>

                                    {imageSrc && (
                                        <div
                                            className={
                                                'hidden cursor-pointer overflow-hidden rounded-md lg:block lg:flex-1'
                                            }
                                        >
                                            <img
                                                className={
                                                    'h-full w-full object-contain object-right-top'
                                                }
                                                src={imageSrc}
                                                alt={''}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ImagesPreview>
                    )}
                </div>
            </Card>
        </ContainerFullOnMobile>
    )
})

PathBlockedStub.displayName = 'PathBlockedStub'
