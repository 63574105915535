import {ApiFactory} from '@services/ApiFactory'
import {AdminUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'

const adminStatisticsInstance = ApiFactory.createApi(baseConfig)

class AdminServices {
    static getStatisticsReviews = async (query) => {
        return await adminStatisticsInstance.get(
            AdminUrl.statisticsReviews,
            query
        )
    }
    static getStatisticsMonitoring = async (query) => {
        return await adminStatisticsInstance.get(
            AdminUrl.statisticsMonitoring,
            query
        )
    }
    static getUsers = async (page, query) => {
        return await adminStatisticsInstance.get(AdminUrl.users(page), query)
    }

    static getUsersLogin = async (userUuid) => {
        return await adminStatisticsInstance.post(AdminUrl.usersLogin(userUuid))
    }

    static getUsersLogout = async () => {
        return await adminStatisticsInstance.get(AdminUrl.usersLogout)
    }

    static postUsersSetTariff = async (payload) => {
        return await adminStatisticsInstance.post(AdminUrl.setTariff, payload)
    }

    static getManagers = async (query) => {
        return await adminStatisticsInstance.get(AdminUrl.managers, query)
    }

    static postUsersCreate = async (payload) => {
        return await adminStatisticsInstance.post(AdminUrl.usersCreate, payload)
    }
    static getMpAccount = async (query) => {
        return await adminStatisticsInstance.get(AdminUrl.mpAccount, query)
    }
    static getTariffs = async (query) => {
        return await adminStatisticsInstance.get(AdminUrl.tariffs, query)
    }

    static postSubscribeToDemo = async (payload) => {
        return await adminStatisticsInstance.post(
            AdminUrl.subscribeToDemo,
            payload
        )
    }

    static postEmailConfirm = async (payload) => {
        return await adminStatisticsInstance.post(
            AdminUrl.emailConfirm,
            payload
        )
    }

    static postPhoneConfirm = async (payload) => {
        return await adminStatisticsInstance.post(
            AdminUrl.phoneConfirm,
            payload
        )
    }

    static deletePhoneConfirm = async (uuid) => {
        return await adminStatisticsInstance.delete(
            AdminUrl.deleteCostsConfirm(uuid)
        )
    }

    static postUserEdit = async (payload, uuid) => {
        return await adminStatisticsInstance.post(
            AdminUrl.userEdit(uuid),
            payload
        )
    }
}

export default AdminServices
