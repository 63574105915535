import {useEffect} from 'react'
import {observer} from 'mobx-react'
import {ONBOARD_STEP} from '@utils/constants'
import {useNotificationsContext} from '@components/overlays/notifications'
import {firstStepsPlatform} from '@utils/selfMessages'

export const ImportMarketOnboard = observer(({onboardingStepStore}) => {
    const marketStore = onboardingStepStore.marketStore
    const notificationsStore = useNotificationsContext()
    // const onboardingImportError = async () => {
    //     onboardingStepStore.setIsConnectedCabinet(false)
    //     try {
    //         await onboardingStepStore.putOnboardSetStep(
    //             ONBOARD_STEP.mpImportComplete,
    //             false
    //         )
    //         toast(TOAST_ONBOARDING_MAP.importError, {
    //             type: 'error',
    //             autoClose: 60000,
    //         })
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    const onboardingImportSuccess = async () => {
        try {
            await onboardingStepStore.putOnboardSetStep(
                ONBOARD_STEP.mpImportComplete,
                true
            )
        } catch (e) {
            console.log(e)
        }
    }

    const fetchImportSuccess = async () => {
        await onboardingImportSuccess()
        await notificationsStore.postNotificationsSelf(
            firstStepsPlatform,
            'Первые шаги в платформе'
        )
        await notificationsStore.getNotifications(1, true)
        await onboardingStepStore.unsubscribeDemoAcc()
    }

    useEffect(() => {
        if (
            !marketStore.importStatusesPolling.isPooling &&
            !marketStore.isNewSuccessImport &&
            marketStore.isRealAcc
        ) {
            marketStore.importStatusesPolling.startPolling()
        }
    }, [marketStore.isNewSuccessImport, marketStore.isRealAcc])

    useEffect(() => {
        if (onboardingStepStore.step === ONBOARD_STEP.completed) return
        if (
            marketStore.statuses.size &&
            !marketStore.isNewFailedImport &&
            marketStore.isNewSuccessImport
        ) {
            fetchImportSuccess()
        }
        // if (marketStore.isNewFailedImport) {
        //     onboardingImportError()
        // }
    }, [
        marketStore.statuses.size,
        marketStore.isNewSuccessImport,
        marketStore.isNewFailedImport,
        onboardingStepStore.step,
    ])
    //
    // const getContent = () => {
    //     if (
    //         !marketStore.statuses.size ||
    //         (marketStore.statuses.size && marketStore.newStatusesImport.length)
    //     ) {
    //         return (
    //             <>
    //                 <div className={'flex items-center space-x-4'}>
    //                     <span
    //                         className={
    //                             'font-medium text-gray-800 dark:text-gray-200'
    //                         }
    //                     >
    //                         Подгружаем данные по вашим кабинетам
    //                     </span>
    //                     <Loader sm overlayed={false} />
    //                 </div>
    //             </>
    //         )
    //     }
    //     return (
    //         <span className={'font-medium text-gray-800 dark:text-gray-200'}>
    //             Данные по кабинетам загружены
    //         </span>
    //     )
    // }
    //
    // function handleClick() {
    //     toast(TOAST_ONBOARDING_MAP.marketAdded, {
    //         type: 'success',
    //         autoClose: 60000,
    //     })
    // }

    return <></>
})

ImportMarketOnboard.displayName = 'ImportMarketOnboard'
