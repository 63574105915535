import {useMemo} from 'react'
import {observer} from 'mobx-react'
import {FileDropper} from '@components/elements'
import {toast} from 'react-toastify'
import UpdateProcess from '@views/products/Stock/StockXls/FileUpload/UpdateProcess'
import {ModalCaller, ModalConfirm} from '@components/overlays'
import {CheckFileStatuses} from '../CheckFileStatuses'
import {STATUSES_FILE_MAP} from '@utils/constants'
import {FileImport} from '../FileImport'

export const FileUpload = observer(
    ({
        uploadFileStore,
        updateHistory,
        date,
        downloadFileStore,
        checkSelectedDemoAcc,
    }) => {
        const handleLoad = (files) => {
            for (let i = 0; i < files.length; i++) {
                let newFile = files[i]
                uploadFileStore.addFileToImport(newFile, () => {
                    toast(`Файл "${newFile.name}" не в формате XLSX!`, {
                        type: 'error',
                    })
                })
            }
        }

        const onUpload = async () => {
            await uploadFileStore.postFile(date)
        }

        const handleDeleteFile = () => {
            uploadFileStore.setStatuses(STATUSES_FILE_MAP.fileDrop)
            uploadFileStore.deleteFile()
        }

        const confirmDelete = () => {
            new ModalCaller({
                bodyClass: 'pb-0 mb-0',
                children: (
                    <ModalConfirm
                        isModal={false}
                        title={`Вы действительно хотите удалить файл?`}
                        description={'Это действие необратимо!'}
                        onAccept={handleDeleteFile}
                    />
                ),
            })
        }
        const renderDropFileStep = useMemo(() => {
            switch (uploadFileStore.statuses) {
                case STATUSES_FILE_MAP.fileAdded: {
                    return (
                        <FileImport
                            checkSelectedDemoAcc={checkSelectedDemoAcc}
                            file={uploadFileStore.file}
                            onDelete={confirmDelete}
                            onUpload={onUpload}
                        />
                    )
                }
                case STATUSES_FILE_MAP.process: {
                    return <UpdateProcess titleSize={'text-md'} />
                }
                case STATUSES_FILE_MAP.checkStatus: {
                    return (
                        <CheckFileStatuses
                            uploadFileStore={uploadFileStore}
                            downloadFileStore={downloadFileStore}
                            updateHistory={updateHistory}
                        />
                    )
                }
                default: {
                    return (
                        <div className={'mb-10 max-w-[730px]'}>
                            <FileDropper
                                dropzoneText={
                                    'Перетащите сюда заполненный XLSX файл'
                                }
                                accept={[
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                ]}
                                onChange={handleLoad}
                            />
                        </div>
                    )
                }
            }
        }, [uploadFileStore.statuses])
        return (
            <div className={'flex flex-col space-y-4'}>
                {renderDropFileStep}
            </div>
        )
    }
)
