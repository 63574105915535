import {DownloadIcon} from '@heroicons/react/outline'
import {useDropzone} from 'react-dropzone'
import cn from 'classnames'

const FileDropper = ({
    onChange = () => {},
    onBlur = () => {},
    acceptedFiles = ['image/*', 'application/*'],
    dropzoneText = '',
    Icon = (
        <DownloadIcon
            className={'h-14 w-14 text-gray-400 dark:text-gray-400'}
        />
    ),
    name = '',
    className = '',
    maxFiles = 10,
}) => {
    const {getRootProps, getInputProps} = useDropzone({
        accept: acceptedFiles,
        onDrop: onChange,
        maxFiles,
    })

    return (
        <div
            {...getRootProps()}
            className={cn(
                className,
                'relative flex w-full cursor-pointer flex-col items-center justify-center overflow-hidden rounded-lg border-2 border-dashed border-gray-300 p-10 shadow hover:shadow-lg dark:border-gray-600'
            )}
        >
            {Icon}
            <input {...getInputProps()} name={name} onBlur={onBlur} />
            <span className={'my-6 text-gray-600 dark:text-gray-400'}>
                {dropzoneText}
            </span>
        </div>
    )
}

export {FileDropper}
