import {Select} from '@components/forms/Selects'
import {InfoField} from '@components/applicationUi'

export const SettingsSelect = ({
    label,
    options,
    selected,
    onChange,
    helpContent,
    disabled,
    placeholder,
    renderName,
    children,
}) => {
    return (
        <InfoField label={label} helpContent={helpContent}>
            <Select
                portal
                disabled={disabled}
                placeholder={placeholder}
                options={options}
                selected={selected}
                onChange={onChange}
                renderName={renderName}
            >
                {children}
            </Select>
        </InfoField>
    )
}
