import AuthService from '@services/AuthService/AuthService'
import {TokenService} from '@services/TokenService'
import {AccessControlService} from '@utils/access/AccessControlService'
import jwtDecode from 'jwt-decode'
import {AppPath, AUTH_URLS} from '@routesEnums'
import {makeAutoObservable, runInAction} from 'mobx'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import {ResetPasswordStore} from '@views/auth/ResetPassword/store/ResetPasswordStore'
import {getErrorAuthFields} from '@views/auth/utils'
import {toast} from 'react-toastify'
import {getStandName} from '@utils/helpers'
import {gtmAuthCompleted} from '@utils/googleUtils/utils'
import {ThroughFiltersStore} from '@store/ThroughFiltersStore'
import {sha256} from 'js-sha256'
import {CallConfirmModalService} from '@utils/callConfirmModalService'

class AuthStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.reset()
        this.checkTokenStorage()
        makeAutoObservable(this, {
            history: false,
            checkTokenStorage: false,
            setHistory: false,
        })
    }

    history = {}

    errors = {message: '', fields: {}}
    inProcess = false
    refreshLoader = false
    loginLoader = false
    isEmulations = false

    reset = () => {
        this.stores = {
            resetPasswordStore: new ResetPasswordStore(this.rootStore),
        }
    }

    setHistory = (history) => {
        this.history = history
    }

    setInProcess = (value = false) => {
        this.inProcess = value
    }

    setRefreshLoader = (value = false) => {
        this.refreshLoader = value
    }

    setLoginLoader = (value = false) => {
        this.loginLoader = value
    }

    setIsEmulations = (value = false) => {
        this.isEmulations = value
    }

    setErrors = (value = {message: '', fields: {}}) => {
        this.errors = value
    }
    addErrors = (key, value) => {
        this.errors[key] = value
    }

    checkTokenStorage = () => {
        if (TokenService.isTokenExistsSessionStorage()) {
            TokenService.setStorage()
        }
    }

    refreshToErrorByDataAuth = async (isRedirectToSignIn) => {
        try {
            const {data} = await this.sendRefreshToken()
            await this.rootStore.userStore.requestUserData()
        } catch (e) {
            await this.logout(false, isRedirectToSignIn)
        }
    }

    setAuthDataFromStorages = async (isRedirectToSignIn) => {
        const accessToken = TokenService.getToken()
        const refreshToken = TokenService.getRefreshedToken()
        if (TokenService.isEmulationExists()) {
            this.setIsEmulations(true)
        }

        if (accessToken && refreshToken) {
            this.rootStore.userStore.setUnverifiedUserData(
                jwtDecode(accessToken)
            )
            try {
                const {data} = await this.getValidateToken(accessToken)
                runInAction(() => {
                    this.rootStore.userStore.setUnverifiedUserData()
                    this.setTokens({accessToken, refreshToken})
                    this.rootStore.userStore.requestUserData()
                })
            } catch (e) {
                await this.refreshToErrorByDataAuth(isRedirectToSignIn)
            }
        } else this.logout(false, isRedirectToSignIn)
    }

    login = async (globalLoader, isRedirect = true, path) => {
        if (globalLoader) this.setLoginLoader(true)
        try {
            await this.rootStore.userStore.requestUserData()
            if (isRedirect) {
                this.history.push(
                    path ? path : this.rootStore.userStore.homePage
                )
            }
        } catch (e) {
            console.log(e)
            toast('Произошли проблемы с авторизацией', {type: 'error'})
        } finally {
            this.setLoginLoader(false)
        }
    }

    logout = async (isReload = true, isRedirectToSignIn = true) => {
        TokenService.removeToken()
        TokenService.removeRefreshedToken()
        TokenService.removeIsEmulation()
        CallConfirmModalService.resetOpenedCall()
        this.setIsEmulations(false)
        await this.getLogout()
        this.history.push({
            pathname: AUTH_URLS.includes(this.history.location.pathname)
                ? this.history.location.pathname
                : AppPath.signIn,
            search: this.history.location.search,
        })
        if (isReload) {
            Object.values(this.rootStore).forEach((store) => {
                store.reset && store.reset()
            })
        }
    }

    externalLogout = async () => {
        try {
            TokenService.removeIsEmulation()
            runInAction(() => {
                this.setLoginLoader(true)
                this.rootStore.marketStore.setLoaderAllCreds(true)
                this.rootStore.userStore.resetParentWithEmulationUserData()
            })
            const {data} = await this.rootStore.adminUsersStore.getUsersLogout()
            this.setIsEmulations(false)
            await this.rootStore.authStore.appendToken(data, true, true, false)
            ThroughFiltersStore.resetFilters()
        } catch (e) {
            console.log(e)
        } finally {
            runInAction(() => {
                this.setLoginLoader(false)
                this.rootStore.marketStore.setLoaderAllCreds(false)
            })
        }
    }

    setTokens = ({accessToken, refreshToken}) => {
        TokenService.setToken(accessToken)
        TokenService.setRefreshedToken(refreshToken)
        AccessControlService.setToken(accessToken)
        this.rootStore.userStore.mergeUserData(jwtDecode(accessToken))
    }

    appendExternalUserToken = async (data) => {
        try {
            TokenService.setIsEmulation()
            runInAction(() => {
                this.rootStore.userStore.saveUserData()
                this.setIsEmulations(true)
                this.setTokens({
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken,
                })
            })
            await this.login()
        } catch (e) {
            console.log(e)
        }
    }

    appendToken = async (
        data,
        remember,
        globalLoader,
        isRedirect = true,
        path
    ) => {
        try {
            TokenService.setStorage(!!remember)
            this.setTokens({
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
            })
            await this.login(globalLoader, isRedirect, path)
        } catch (e) {
            console.log(e)
        }
    }

    sendRefreshToken = async () => {
        if (!TokenService.isRefreshedTokenExists()) return this.logout()
        const payload = {
            refresh_token: TokenService.getRefreshedToken(),
        }
        this.setRefreshLoader(true)
        try {
            const {data} = await AuthService.postRefreshToken(payload)
            const {accessToken, refreshToken} = data
            this.setTokens({accessToken, refreshToken})
            return Promise.resolve({data})
        } catch (e) {
            console.log(e)
            await this.logout()
            return Promise.reject(e)
        } finally {
            this.setRefreshLoader(false)
        }
    }

    getValidateToken = async (token) => {
        const {user_uuid} = jwtDecode(token)
        const payload = {
            auth_token: token,
        }
        try {
            const {data} = await AuthService.getValidateToken(
                payload,
                user_uuid
            )
            if (data.result) {
                return Promise.resolve({data})
            }
            return Promise.reject()
        } catch (e) {
            console.log(e)
            return Promise.reject(e)
        }
    }

    postLogin = async ({remember, phone, ...rest}) => {
        const payload = {
            ...rest,
            phone_number: phone,
            remember_me: true,
        }
        try {
            runInAction(() => {
                this.setErrors()
                this.setInProcess(true)
            })
            const {data} = await AuthService.postLogin(payload)
            await this.appendToken(data, true)
            const userData = jwtDecode(data.accessToken)
            if (getStandName() !== 'dev') {
                const hash = sha256.hmac
                    .update(
                        import.meta.env.VITE_APP_CARROT_TOKEN,
                        userData.user_uuid
                    )
                    .hex()
                window.carrotquest.auth(userData.user_uuid, hash)
            }
            gtmAuthCompleted(this.rootStore.userStore)
        } catch (e) {
            console.log(e)
            if (e.response.data.result === false) {
                const errors = {
                    message: e.response.data.message,
                    fields: getErrorAuthFields(e.response.data.formError || {}),
                }
                return this.setErrors(errors)
            }
            this.addErrors(
                'message',
                'Что-то пошло не так... Попробуйте снова или обратитесь в поддержку'
            )
        } finally {
            this.setInProcess()
        }
    }

    postSignUp = async (payload) => {
        try {
            const {data} = await AuthService.postSignUp(payload)
            return Promise.resolve(data)
        } catch (e) {
            return Promise.reject(e)
        }
    }

    getConfirmEmail = async (token) => {
        try {
            const {data} = await AuthService.getConfirmEmail(token)
            if (data.result) {
                await this.appendToken(data, true)
            }
        } catch (e) {
            console.log(e)
        }
    }

    getLogout = async () => {
        try {
            const {data} = await AuthService.getLogout()
            if (data.result) {
                this.rootStore.userStore.resetParentWithEmulationUserData()
                ThroughFiltersStore.resetFilters()
                GoogleTagManager.dataLayer({
                    user_id: '',
                    email: '',
                })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export default AuthStore
