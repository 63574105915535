import {forwardRef} from 'react'
import {NavLink} from 'react-router-dom'
import {MarketIcon, TooltipLite} from '@components/elements'
import {LockClosedIcon} from '@heroicons/react/outline'
import classNames from 'classnames'

const NavigationNavLink = forwardRef(({row, onClick}, ref) => {
    if (row.blocked) {
        return (
            <div
                ref={ref}
                className={
                    'group flex cursor-default items-center px-2 py-2 text-sm font-medium text-gray-500'
                }
            >
                <Content row={row} icon={row.icon} />
            </div>
        )
    }

    if (row.button) {
        return (
            <div
                id={row.id}
                ref={ref}
                onClick={onClick}
                className={classNames(
                    'group flex cursor-pointer items-center rounded-md px-2 py-2 text-sm font-medium',
                    'bg-carrotQuestBtn text-white dark:text-gray-200',
                    'hover:opacity-90'
                )}
            >
                <Content row={row} icon={row.icon} />
            </div>
        )
    }

    return (
        <NavLink
            ref={ref}
            to={{
                pathname: row.href,
                //search: checkParams('godmode') ? '?godmode' : ''
            }}
            className={
                'group flex items-center rounded-md px-2 py-2 text-sm font-medium text-gray-300 hover:bg-gray-600 dark:hover:bg-gray-800'
            }
            activeClassName={'bg-gray-700 dark:bg-gray-600 text-white'}
            onClick={onClick}
        >
            <Content row={row} icon={row.icon} />
        </NavLink>
    )
})

const Content = ({row}) => {
    return (
        <div className={'flex items-center space-x-4'}>
            <row.icon
                className={classNames(
                    'mr-3 h-6 w-6 flex-shrink-0 stroke-current',
                    {
                        'text-white': !row.blocked,
                        'text-gray-500': row.blocked,
                    }
                )}
                aria-hidden='true'
            />
            {row.name}
            {row.marketName && <MarketIcon name={row.marketName} size={20} />}
            {row.blocked && (
                <LockClosedIcon className='h-5 w-5 flex-shrink-0' />
            )}
        </div>
    )
}

const NavigationRow = ({row, onClick}) => {
    const {href, external, visible, tooltip} = row

    if (!visible) return null

    if (external) {
        return (
            <a
                href={href}
                target='_blank'
                className={
                    'group flex items-center rounded-md px-2 py-2 text-sm font-medium text-gray-300 hover:bg-gray-600 dark:hover:bg-gray-800'
                }
            >
                <Content row={row} />
            </a>
        )
    }

    if (tooltip) {
        return (
            <TooltipLite
                offset={[0, 20]}
                isArrow={false}
                portalContainerId={'overlays'}
                content={tooltip}
            >
                {(ref) => (
                    <NavigationNavLink ref={ref} row={row} onClick={onClick} />
                )}
            </TooltipLite>
        )
    }

    return <NavigationNavLink row={row} onClick={onClick} />
}

export default NavigationRow
