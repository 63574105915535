import jwtDecode from 'jwt-decode'
import {blockedTariff, USER_ROLE} from '@utils/constants'
import {stripTrailingSlash} from '@utils/helpers'

const blockedUrls = {
    dashboard: true,
    profit: true,
    finance: true,
    product_analytics: true,
    deliveries_regions: true,
    notification_telegram: true,
    promotion: true,
    'help-list': true,
}

export class AccessControlService {
    static tokenData = {}

    static setToken(token) {
        AccessControlService.tokenData = jwtDecode(token)
    }

    static isRouteAllowed(routePath) {
        let routeAllowed = true

        const accessControlKey = stripTrailingSlash(routePath).replace('/', '')
        if (
            blockedTariff[AccessControlService.tokenData?.tariff?.slug] &&
            blockedUrls[accessControlKey]
        ) {
            routeAllowed = !AccessControlService.tokenData?.parent_user_uuid
                ? !blockedUrls[accessControlKey]
                : true
        }

        return routeAllowed
    }

    static isAdminRole() {
        return !(
            !AccessControlService.tokenData.role ||
            AccessControlService.tokenData.role === USER_ROLE.user
        )
    }

    static getLimitByName(limitName, defaultValue) {
        return AccessControlService.tokenData[limitName] || defaultValue
    }

    static isTestUser() {
        return AccessControlService.tokenData?.is_test_user
    }
}
