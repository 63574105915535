import {useEffect} from 'react'
import {observer} from 'mobx-react'
import {FileHistoryItem} from './FileHistoryItem'
import {Button} from '@components/elements'

export const FileHistory = observer(({historyStore, options, children}) => {
    const {pagination} = historyStore.paginationStore

    useEffect(() => {
        historyStore.getHistory()
    }, [])

    const handleClickHistoryNext = () => {
        historyStore.getHistory({limit: pagination.limit + 10})
    }
    const handleClickHistoryUpdate = () => {
        historyStore.getHistory({limit: 10})
    }
    return (
        <div className={'flex flex-col space-y-4 py-6 pl-6'}>
            <h2
                className={
                    'text-2xl font-medium text-gray-800 dark:text-gray-200'
                }
            >
                История загрузок
            </h2>
            {!historyStore.historyList.length ? (
                <div className={'flex flex-col space-y-4'}>
                    <div
                        className={
                            'flex justify-center text-xl font-medium text-gray-600 dark:text-gray-400'
                        }
                    >
                        <span>История отсутствует</span>
                    </div>
                </div>
            ) : (
                ''
            )}
            {historyStore.historyList.map((item, id) => {
                if (typeof children === 'function') return children(item, id)
                return (
                    <FileHistoryItem
                        key={id}
                        history={item}
                        downloadXls={historyStore.getDownloadXls}
                        options={options}
                    />
                )
            })}
            {historyStore.historyList.length ? (
                <Button
                    disabled={pagination.limit > pagination.total}
                    onClick={handleClickHistoryNext}
                >
                    Показать еще
                </Button>
            ) : null}
            <Button onClick={handleClickHistoryUpdate}>Обновить список</Button>
        </div>
    )
})
