import {action, makeObservable, observable} from 'mobx'
import FeedbackService from '@services/FeedbackService/FeedbackService'

import MailClient from '@assets/mails/academy-client.html?raw'

import Registration from '@assets/images/academy/features/registration.svg'
import Delivery from '@assets/images/academy/features/delivery.svg'
import Calculations from '@assets/images/academy/features/calculations.svg'
import Magnifier from '@assets/images/academy/features/magnifier.svg'
import Card from '@assets/images/academy/features/card.svg'
import Ruble from '@assets/images/academy/features/ruble.svg'
import Review from '@assets/images/academy/features/review.svg'
import Editing from '@assets/images/academy/features/editing.svg'

import Ahmetzyanov from '@assets/images/academy/experts/Ahmetzyanov.jpg'
import Oblomskij from '@assets/images/academy/experts/Oblomskij.png'
import Vlasova from '@assets/images/academy/experts/Vlasova.png'
import Gabov from '@assets/images/academy/experts/Gabov.png'
import Podluckaya from '@assets/images/academy/experts/Podluckaya.png'
import Erohina from '@assets/images/academy/experts/Erohina.jpg'
import Tarasian from '@assets/images/academy/experts/Tarasian.png'
import Mayakova from '@assets/images/academy/experts/Mayakova.png'
import Klyusa from '@assets/images/academy/experts/Klyusa.png'
import Grigoriev from '@assets/images/academy/experts/Grigoriev.png'

class AcademyStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            videos: observable,
            features: observable,
            experts: observable,
            tariffs: observable,
            reviews: observable,
            mailInProcess: observable,
            mailIsError: observable,
            mailIsSuccess: observable,
            setMailInProcess: action,
            setMailIsError: action,
            setMailIsSuccess: action,
        })
    }

    videos = [
        'https://www.youtube.com/embed/kheehUIu9FM',
        'https://www.youtube.com/embed/TMJg9wyM-dc',
        'https://www.youtube.com/embed/LGF7uSKAxQ8',
        'https://www.youtube.com/embed/aAG5rA8fiUs',
    ]

    features = [
        {
            name: 'Регистрировать магазины на Ozon и Wildberries и управлять ими ',
            icon: Registration,
        },
        {
            name: 'Выбирать выгодную логистическую схему для отгрузки товаров, подключать склады, делать поставки',
            icon: Delivery,
        },
        {
            name: 'Рассчитывать unit-экономику для определения рентабельности продаж на маркетплейсах',
            icon: Calculations,
        },
        {
            name: 'Анализировать товарные ниши и уровень конкуренции в них для оценки потенциала вывода нового товара',
            icon: Magnifier,
        },
        {
            name: 'Составлять продающие SEO-описания карточек товаров для попадания в ТОП выдачи Ozon и Wildberries',
            icon: Card,
        },
        {
            name: 'Работать с ценами, настраивать бесплатные и платные рекламные кампании',
            icon: Ruble,
        },
        {
            name: 'Работать с отзывами, повышать лояльность покупателей',
            icon: Review,
        },
        {
            name: 'Работать с контентом, создавать RICH-контент, инфографику для карточек и др.',
            icon: Editing,
        },
    ]

    experts = [
        {
            name: 'Виталий Клюса',
            role: 'Руководитель отдела продвижения и развития селлеров Seller24',
            imageUrl: Klyusa,
            description: `
            <ul class="list-disc pl-5 space-y-2">
                <li>Эксперт в запуске и продвижении клиентских магазинов на Ozon и Wildberries</li>
                <li>Увеличил доходы 30+ магазинов с 0 до 1 500 000 ₽</li>
                <li>Вывел на оборот 20+ млн. ₽ более 10 брендов</li>
                <li>Успешно запустил и прокачал линейку собственных брендов и товаров на маркетплейсах</li>
                <li>Вывел более 100 товаров в ТОП на площадках</li>
            </ul>`,
        },
        // {
        //     name: 'Алексей Ручкин',
        //     role: 'CEO Seller24',
        //     imageUrl: Ruchkin,
        //     description: `
        //     <ul class="list-disc pl-5 space-y-2">
        //         <li>Предприниматель и топ-менеджер с двадцатилетним стажем</li>
        //         <li>Управлял собственным бизнесом – руководил веб-студией и был дистрибьютором аксессуаров для гаджетов</li>
        //         <li>С 2011 года развивал электронную коммерцию в компаниях "Прагматик-Экспресс", "Бутик.ру", "МирМагнитов", "Адамас" и "МВидео"</li>
        //         <li>В 2019 году возглавил цифровой маркетинг ритейлера "ЛЕНТА"</li>
        //         <li>в 2020 году - торговый дом гражданского оружия концерна "Калашников"</li>
        //     </ul>`,
        // },
        {
            name: 'Ренат Ахметзянов',
            role: 'Руководитель службы поддержки клиентов Ozon, Wildberries, Яндекс.Маркет, СберМегаМаркет, AliExpress',
            imageUrl: Ahmetzyanov,
            description: `
            <ul class="list-disc pl-5 space-y-2">
                <li>Успешный менеджер маркетплейсов, колоссально увеличивающий продажи клиентов.</li>
            </ul>
            <p>Среди его достижений – увеличение доходов:</p>
            <ul class="list-disc pl-5 space-y-2">
                <li>крупного бренда спортивной одежды и инвентаря с 200 000 до 1 500 000 ₽</li>
                <li>нового бренда кухонной посуды – с нуля до 500 000 ₽</li>
                <li>нового бренда интернет-магазина электроники - с нуля до 5 000 000 ₽</li>
            </ul>`,
        },
        {
            name: 'Борис Обломский',
            role: 'Директор по продукту компании Seller24',
            imageUrl: Oblomskij,
            description: `
            <ul class="list-disc pl-5 space-y-2">
                <li>2012-2021 интернет-магазин prodacha.ru, директор</li>
                <li>С нуля запустил интернет-магазин. Увеличил выручку компании в 9 раз.</li>
                <li>Организовал контроль качества выполнения заказов и довел NPS до 79%, реальный рейтинг 4.8* на Яндекс.Маркете</li>
                <li>Разработал собственную CRM для ведения заказов на базе 1С и систему сквозной аналитики продаж.</li>
                <li>Проекты интеграций сайта, CRM и других информационных систем</li>
            </ul>`,
        },
        {
            name: 'Анастасия Тарасьян',
            role: 'Руководитель Академии Seller24',
            imageUrl: Tarasian,
            // description: `
            // <ul class="list-disc pl-5 space-y-2">
            //     <li>Создала вместе с командой более 10 тыс. единиц RICH-контента и инфографики для селлеров на маркетплейсах.</li>
            //     <li>Руководила созданием контента для брендов A-сегмента: Panasonic, JBL, Nintendo, Redmond, Xiaomi, Thomson, Whirlpool, Greenworks и др.</li>
            //     <li>Разработала RICH-контент для таких гигантов, как AliExress (+Tmall), “М.Видео” и “Эльдорадо”. </li>
            // </ul>`,
        },
        {
            name: 'Маргарита Маякова',
            role: 'Арт-директор с 14-летним опытом в индустрии веб-дизайна',
            imageUrl: Mayakova,
            description: `
            <ul class="list-disc pl-5 space-y-2">
                <li>Создала вместе с командой более 10 тыс. единиц RICH-контента и инфографики для селлеров на маркетплейсах.</li>
                <li>Руководила созданием контента для брендов A-сегмента: Panasonic, JBL, Nintendo, Redmond, Xiaomi, Thomson, Whirlpool, Greenworks и др.</li>
                <li>Разработала RICH-контент для таких гигантов, как AliExress (+Tmall), “М.Видео” и “Эльдорадо”. </li>
            </ul>`,
        },
        {
            name: 'Александр Габов',
            role: 'Советник по маркетплейсам в Seller24',
            imageUrl: Gabov,
            description: `
            <ul class="list-disc pl-5 space-y-2">
                <li>Вывел новый бренд на маркетплейсы. Среднемесячный оборот более 2 000 000 ₽.</li>
                <li>Увеличил оборот нового бренда товаров для дома с 0 до 1 500 000 ₽.</li>
                <li>Возглавлял отделы закупок в крупных Российских компаниях.</li>
            </ul>`,
        },
        {
            name: 'Юлия Подлуцкая',
            role: 'Руководитель службы персонала Seller24',
            imageUrl: Podluckaya,
            description: `
            <p>За 20 лет работы в HR:</p>
            <ul class="list-disc pl-5 space-y-2">
                <li>руководила службой персонала в крупной консалтинговой компании SRG-group</li>
                <li>возглавляла проекты по подбору уникальных специалистов в области IT, телеком и медиа для таких компаний, как "ИСПА-инжиниринг", SONY, Microsoft, Sennheiser, телеканал Disney и другие.</li>
                <li>формировала команды топ- менеджеров и проекты по массовому подбору персонала</li>
            </ul>`,
        },
        {
            name: 'Мария Власова',
            role: 'Менеджер отдела продвижения и развития селлеров Seller24',
            imageUrl: Vlasova,
            description: `
            <ul class="list-disc pl-5 space-y-2">
                <li>Успешно запустила клиентские магазины на Ozon и Wildberries</li>
                <li>Увеличила доходы магазинов с 0 до 500 000 ₽</li>
                <li>Запустила собственные магазины на маркетплейсах</li>
                <li>Вывела товары на площадках в ТОП</li>
            </ul>`,
        },

        {
            name: 'Ирина Ерохина',
            role: 'Заместитель руководителя отдела обучения Seller24',
            imageUrl: Erohina,
            description: `
            <ul class="list-disc pl-5 space-y-2">
                <li>Разработчик-методолог продуктов по обучению</li>
                <li>Опыт личных продаж - более 5 лет</li>
                <li>Тренер по продажам, по soft-skills и управленческим навыкам в мебельной компании "Мария" и в компании по производству дверей "Ferroni"</li>
                <li>Управленческий опыт - более 1 года, сотрудников в подчинении - более 50 человек</li>
            </ul>`,
        },
        {
            name: 'Яков Григорьев',
            role: 'Эксперт в сфере e-commerce, вывел на маркетплейсы около 100 компаний',
            imageUrl: Grigoriev,
            description: `
            <ul class="list-disc pl-5 space-y-2">
                <li>Увеличил оборот компании, продающей товары повседневного спроса, на маркетплейсах до 57 млн рублей в месяц;</li>
                <li>Достиг высоких результатов в кратчайшие сроки: с момента запуска магазина на «СберМегаМаркет» до получения выручки в размере 4,6 млн рублей в месяц прошло всего полгода;</li>
                <li>Вывел магазин на маркетплейсы и помог достичь показателя рентабельности в 30%, оборота 2 млн рублей в месяц. Выстроил все процессы в направлении e-commerce.</li>
            </ul>`,
        },
    ]

    tariffs = [
        {
            title: 'САМОСТОЯТЕЛЬНО',
            oldPrice: '30.000 рублей',
            price: '27.000 рублей',
            features: [
                {
                    name: '8 модулей курса в личном кабинете 1 год',
                    included: true,
                },
                {
                    name: ' Тестовые кабинеты на Ozon и WB 1 месяц',
                    included: true,
                },
                {
                    name: 'Доступ к закрытому чату потока',
                    included: true,
                },
                {
                    name: 'Практические задания для закрепления навыков',
                    included: true,
                },
                {
                    name: 'Итоговое тестирование',
                    included: true,
                },
                {
                    name: 'Сертификат',
                    included: true,
                },
                {
                    name: 'Доступ к платформе Seller24 для анализа продаж ДЕМО',
                    included: true,
                },
                {
                    name: 'Онлайн-встречи с экспертами 1 месяц',
                    included: false,
                },
                {
                    name: 'Индивидуальный разбор домашних заданий с обратной связью от экспертов',
                    included: false,
                },
                {
                    name: 'Поддержка кураторов и экспертов 3 месяца',
                    included: false,
                },
                {
                    name: 'Доступ к платформе MPSTATS 1 месяц',
                    included: false,
                },
                {
                    name: '3 индивидуальные консультации с экспертами',
                    included: false,
                },
                {
                    name: 'Оффлайн - встреча с экспертами в офисе Seller24',
                    included: false,
                },
                {
                    name: 'Курс "Soft-skills для руководителей и специалистов',
                    included: false,
                },
            ],
            link: 'https://seller24.ru/sam_seller',
        },
        {
            title: 'ВСЁ ВКЛЮЧЕНО',
            oldPrice: '60.000 рублей',
            price: '54.000 рублей',
            features: [
                {
                    name: '8 модулей курса в личном кабинете 1 год',
                    included: true,
                },
                {
                    name: ' Тестовые кабинеты на Ozon и WB 1 месяц',
                    included: true,
                },
                {
                    name: 'Доступ к закрытому чату потока',
                    included: true,
                },
                {
                    name: 'Практические задания для закрепления навыков',
                    included: true,
                },
                {
                    name: 'Итоговое тестирование',
                    included: true,
                },
                {
                    name: 'Сертификат',
                    included: true,
                },
                {
                    name: 'Доступ к платформе Seller24 для анализа продаж ДЕМО',
                    included: true,
                },
                {
                    name: 'Онлайн-встречи с экспертами 1 месяц',
                    included: true,
                },
                {
                    name: 'Индивидуальный разбор домашних заданий с обратной связью от экспертов',
                    included: true,
                },
                {
                    name: 'Поддержка кураторов и экспертов 3 месяца',
                    included: true,
                },
                {
                    name: 'Доступ к платформе MPSTATS 1 месяц',
                    included: true,
                },
                {
                    name: '3 индивидуальные консультации с экспертами',
                    included: false,
                },
                {
                    name: 'Оффлайн - встреча с экспертами в офисе Seller24',
                    included: false,
                },
                {
                    name: 'Курс "Soft-skills для руководителей и специалистов',
                    included: false,
                },
            ],
            link: 'https://seller24.ru/vkl_seller',
        },
        {
            title: 'ПРЕМИУМ',
            oldPrice: '120.000 рублей',
            price: '108.000 рублей',
            features: [
                {
                    name: '8 модулей курса в личном кабинете 1 год',
                    included: true,
                },
                {
                    name: ' Тестовые кабинеты на Ozon и WB 1 месяц',
                    included: true,
                },
                {
                    name: 'Доступ к закрытому чату потока',
                    included: true,
                },
                {
                    name: 'Практические задания для закрепления навыков',
                    included: true,
                },
                {
                    name: 'Итоговое тестирование',
                    included: true,
                },
                {
                    name: 'Сертификат',
                    included: true,
                },
                {
                    name: 'Доступ к платформе Seller24 для анализа продаж ДЕМО',
                    included: true,
                },
                {
                    name: 'Онлайн-встречи с экспертами 1 месяц',
                    included: true,
                },
                {
                    name: 'Индивидуальный разбор домашних заданий с обратной связью от экспертов',
                    included: true,
                },
                {
                    name: 'Поддержка кураторов и экспертов 3 месяца',
                    included: true,
                },
                {
                    name: 'Доступ к платформе MPSTATS 1 месяц',
                    included: true,
                },
                {
                    name: '3 индивидуальные консультации с экспертами',
                    included: true,
                },
                {
                    name: 'Оффлайн - встреча с экспертами в офисе Seller24',
                    included: true,
                },
                {
                    name: 'Курс "Soft-skills для руководителей и специалистов',
                    included: true,
                },
            ],
            link: 'https://seller24.ru/premium_seller',
        },
    ]

    reviews = [
        {
            id: 1,
            rating: 5,
            content: `
            <p>Здравствуйте! Я уже давно задумывалась о том, чтобы пойти в ногу со временем, мечтала работать на себя, в своё удовольствие. По совету подруги, выбрала курс "Менеджер маркетплейса Ozon" в Академии Seller24 и ни разу не пожалела! Грамотная подача материала, позитивный настрой и мотивация на протяжении всего обучения от спикеров и организаторов. После каждого вебинара необходимо выполнить домашнее задание, после которого всё "складывается по полочкам".<br />
            Информация становится понятна даже тем, кто до курса был далек от маркетплейсов. Понравилось то, что во время вебинара спикерам можно задавать все интересующие вопросы по теме. На курсе я научилась настраивать инструменты продвижения и рекламу товаров. А запись вебинаров сохраняется на протяжении года, можно пересмотреть материал, если подзабыл.<br />
            Уверена, что полученные знания помогут мне выйти на Ozon и стать профессионалом с отличным заработком!</p>
          `,
            author: 'Александра Л.',
            authorInfo: 'г. Москва, выпускница Поток 1',
        },
        {
            id: 2,
            rating: 4,
            content: `
            <p>Все отлично! Понравился подход к обучению, объяснили экспертную информацию простым, доступным языком Решила обучиться данной профессии с целью освоить, как устроены маркетплейсы внутри, так как являюсь активным покупателем, стало интересно.<br />
            Понятно, что за маркетплейсами будущее, теперь я смогу вести аккаунты продавцов и иметь дополнительный заработок, как и хотела! И, скорее всего, сама выйду как продавец, так как научилась всем этапам управления кабинетом, контентом и инструментами продвижения.<br />
            Очень рада, что была с вами, огромная благодарность за ваш курс!</p>
          `,
            author: 'Алёна К.',
            authorInfo: 'Московская область, выпускница Поток 2',
        },
        {
            id: 3,
            rating: 5,
            content: `
            <p>Работаю в банковской сфере. Потратил десяток часов на изучение вебинаров и слитых курсах, проанализировал полученную информацию. Увы, она оказалась поверхностной. Решил пойти на обучение и тщательно выбирал продукт. Выбрал Seller 24, т.к. подкупил статус компании, ее практическая деятельность, положительные отзывы и сертификация от Маркетплейсов.<br />
            Мотивация была получить знания для открытия собственного магазина и получить больше информации о профессии, как результат - открыл магазин уже во время обучения, а сейчас работаю над продажами. Понял, как найти поставщика и просчитать unit экономику на берегу. Рубрика с анализом нишей просто золото.<br />
            Теперь не боюсь допустить ошибку по не опытности. Знаю как и какими сервисами пользоваться, какие есть риски, как их обойти. Понимаю, что от меня ждет покупатель на площадке. Какие действия предпринимать на результат. Очень ценно было домашнее задание, без него информация осталось бы информацией, а тут она перетекла в навык. Однозначно рекомендую!<br />
            Отдельное спасибо за персональные ответы на вопросы, помогли.</p>
          `,
            author: 'Артур Б.',
            authorInfo: 'г. Долгопрудный, выпускник Поток 2',
        },
        {
            id: 4,
            rating: 4,
            content: `
            <p>Интересно было обучение, которое заточено на практику, поэтому выбирал компанию, которая имеет практическую деятельность на маркетплейсах. Проходил его, чтобы получить базовые знания о маркетплейсах, научиться работать с личным кабинетом и понимать тонкости и нюансы работы.<br />
            Обучение понравилось, все цели закрыты! Понравился подробный разбор всех элементов личного кабинета, аналитика, которую можно проводить очень мощно была подана. Также понравилось объяснение тонкостей работы с карточками товаров, SEO, необходимость инфографики и как это должно выглядеть. Получил множество актуальной информации по сервисам для работы, а также на какие показатели карточек товаров нужно обращать особое внимание.<br />
            Курс рекомендую, спасибо лекторам и всей команде за обучение!</p>
          `,
            author: 'Антон А.',
            authorInfo: 'г. Москва, выпускник Поток 2',
        },
    ]

    mailInProcess = false
    mailIsError = false
    mailIsSuccess = false

    setMailInProcess = (value) => {
        this.mailInProcess = value
    }
    setMailIsError = (value) => {
        this.mailIsError = value
    }
    setMailIsSuccess = (value) => {
        this.mailIsSuccess = value
    }

    sendMail = async (payload) => {
        try {
            this.setMailIsError(false)
            this.setMailInProcess(true)

            const dataInner = {
                to: 'a.tarasyan@seller24.ru',
                request_type: 'request_service_academy',
                title: 'Заявка академия Seller24',
                fields: [
                    {
                        title: 'phone',
                        value: payload.phone,
                    },
                    {
                        title: 'email',
                        value: payload.email,
                    },
                ],
            }

            const dataClient = {
                to: payload.email,
                title: '👉 [Спасибо за заявку] на консультацию по курсу Старт продаж на маркетплейсах Ozon и Wildberries',
                fields: [
                    {
                        title: 'message',
                        value: MailClient,
                    },
                ],
            }

            await FeedbackService.postMessage(dataInner)
            await FeedbackService.postMessage(dataClient)

            this.setMailIsSuccess(true)
        } catch (e) {
            this.setMailIsError(true)
        } finally {
            this.setMailInProcess(false)
        }
    }
}

export default AcademyStore
