import ReactDOM from 'react-dom'
import {usePortal} from '@hooks'
import {TooltipBody} from './TooltipBody'

const TooltipPortal = ({portalContainerId, ...props}) => {
    const target = usePortal(portalContainerId)
    return ReactDOM.createPortal(<TooltipBody {...props} />, target)
}

export {TooltipPortal}
