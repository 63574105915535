import {ExternalLinkIcon} from '@heroicons/react/outline'
import classNames from 'classnames'
import {ProductIcon} from '@components/elements/Icon/Icon'

import {NameField} from '@components/applicationUi/NameCell/NameField'
import {NameTooltip} from '@components/applicationUi/NameCell/NameTooltip'

export const NameCell = ({
    className,
    img,
    url,
    name,
    onClick,
    children,
    rootProps = {},
}) => {
    return (
        <div className={'w-full'} {...rootProps}>
            <div className={'flex items-center gap-4'}>
                {img && (
                    <div
                        className={
                            'flex h-10 w-10 flex-shrink-0 items-center overflow-hidden'
                        }
                    >
                        {img ? (
                            <img
                                className={'w-full object-contain'}
                                src={img}
                                alt={name}
                            />
                        ) : (
                            <ProductIcon className={'w-[40px]'} />
                        )}
                    </div>
                )}
                {name ? (
                    <NameField
                        name={name}
                        className={className}
                        onClick={onClick}
                    />
                ) : (
                    <NameTooltip
                        name={name}
                        className={className}
                        onClick={onClick}
                    />
                )}

                {children}
            </div>
            {url && (
                <a
                    className={
                        'cursor-pointer text-center text-blue-500 hover:text-blue-700'
                    }
                    target={'_blank'}
                    href={url}
                >
                    <ExternalLinkIcon className={'h-5 w-5'} />
                </a>
            )}
        </div>
    )
}
