import {toUpperCaseFirstChar} from '@utils/helpers'
import {WithTooltipWrapper} from '@components/applicationUi'
import {ExclamationIcon, InformationCircleIcon} from '@heroicons/react/outline'
import {useMemo} from 'react'
import {Dot} from '@components/elements/Dot'
import {
    HIDDEN_ICON_MAP,
    IMPORT_WB_TOOLTIP_CONTENT,
} from '@views/marketplaces/pages/ImportWb/constants'

export const ImportWbExtensionItem = ({name, color, options}) => {
    const Component = IMPORT_WB_TOOLTIP_CONTENT[color]

    const icon = useMemo(() => {
        switch (color) {
            case 'green':
                return (
                    <InformationCircleIcon className='h-4 w-4 flex-shrink-0 text-gray-600' />
                )
            default:
                return (
                    <ExclamationIcon className='h-4 w-4 flex-shrink-0 text-yellow-400' />
                )
        }
    }, [color])

    return (
        <div className={'flex items-center gap-2'}>
            <Dot color={color} size={'small'} />
            <span>{toUpperCaseFirstChar(name)}</span>
            {!HIDDEN_ICON_MAP[`${name}_${color}`] ? (
                <WithTooltipWrapper
                    tooltip={<Component name={name} {...options} />}
                >
                    {icon}
                </WithTooltipWrapper>
            ) : null}
        </div>
    )
}
