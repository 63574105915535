import {Amount, Ndash} from '@components/elements'
import {SIGNS_KEY} from '@components/elements/Amount/convertAmount'
import {XIcon} from '@heroicons/react/outline'
import {ModalCaller, ModalConfirm} from '@components/overlays'

export const TaxHistory = ({history, onDelete, getTypeName}) => {
    const handleClickDelete = (item) => () => {
        new ModalCaller({
            bodyClass: 'pb-0 mb-0',
            children: (
                <ModalConfirm
                    isModal={false}
                    title={'Вы уверены, что хотите удалить ставку налога?'}
                    description={'Это действие необратимо!'}
                    onAccept={handleDeleteHistoryItem(item)}
                />
            ),
        })
    }

    const handleDeleteHistoryItem = (item) => () => {
        onDelete(item)
    }

    return (
        <div className={'grid grid-cols-2 gap-4'}>
            {history.map((item) => {
                return (
                    <div
                        key={item.id}
                        className={
                            'flex items-center gap-4 rounded-md border border-gray-200 p-4 text-sm text-gray-600 dark:border-gray-600 dark:text-gray-400'
                        }
                    >
                        <p>
                            <span>{`${item.actualAt}:`}</span>
                            <div className={'inline-flex space-x-2'}>
                                <span>ставка</span>
                                <Ndash />
                                <span>
                                    <Amount
                                        value={item.value}
                                        currency={SIGNS_KEY.PER}
                                        decimalLength={1}
                                    />
                                </span>
                            </div>
                            <div className={'flex gap-2'}>
                                <span>система</span>
                                <Ndash />
                                <span>{getTypeName(item.mpTaxId)}</span>
                            </div>
                            {item.useSpp && (
                                <span className={'flex gap-2'}>
                                    с учетом СПП
                                </span>
                            )}
                            {item.useNds && (
                                <span
                                    className={'flex gap-2'}
                                >{`с учетом НДС ${item.ndsValue}%`}</span>
                            )}
                            {item.ndsSpecial && (
                                <span className={'flex gap-2'}>
                                    с учетом особого режима НДс
                                </span>
                            )}
                        </p>

                        <div
                            className={'cursor-pointer'}
                            onClick={handleClickDelete(item)}
                        >
                            <XIcon
                                className={
                                    'h-4 w-4 text-red-500 hover:opacity-80 dark:text-red-400'
                                }
                            />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
