import classNames from 'classnames'
import {Amount, TooltipLite} from '@components/elements'
import {SIGNS_KEY} from '@components/elements/Amount/convertAmount'
import {ExclamationIcon, QuestionMarkCircleIcon} from '@heroicons/react/outline'

export const RateField = ({
    value,
    rate,
    isClick = true,
    className,
    decimalLength = 2,
    rateCurrency = SIGNS_KEY.PER,
    rateDecimalLength = 2,
    valueCurrency = SIGNS_KEY.RUR,
    onClick,
}) => {
    return (
        <div className={classNames(className, 'flex flex-col')}>
            <div
                className={classNames('flex justify-end', {
                    'cursor-pointer underline': onClick,
                })}
                onClick={onClick}
            >
                <Amount
                    value={value}
                    decimalLength={decimalLength}
                    currency={valueCurrency}
                />
            </div>
            {rate && (
                <div
                    className={classNames('flex justify-end text-xs', {
                        'cursor-pointer underline': onClick,
                    })}
                    onClick={onClick}
                >
                    <Amount
                        value={rate}
                        currency={rateCurrency}
                        decimalLength={rateDecimalLength}
                    />
                </div>
            )}
        </div>
    )
}
