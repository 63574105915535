import {observer} from 'mobx-react'
import {AvailableCard} from './AvailableCard'
import {useSelector} from '@hooks'
import {
    OzonConnector,
    SberbankConnector,
    WildberriesConnector,
    YandexConnector,
} from '../Connectors'
import {AccessControlService} from '@utils/access/AccessControlService'
import {Title} from '@components/elements'
import {GreetingVideoCard} from '@views/marketplaces/pages/Marketplaces/components/Available/GreetingVideoCard'

export const Available = observer(() => {
    const {marketplaces, credentials} = useSelector(
        (store) => store.marketStore
    )
    const userStore = useSelector((store) => store.userStore)

    const getConnector = (marketName, onCancel, onSuccess, onError) => {
        switch (marketName.toLowerCase()) {
            case 'ozon':
                return (
                    <OzonConnector
                        onCancel={onCancel}
                        onSuccess={onSuccess}
                        onError={onError}
                    />
                )
            case 'wildberries':
                return (
                    <WildberriesConnector
                        onCancel={onCancel}
                        onSuccess={onSuccess}
                        onError={onError}
                    />
                )
            case 'yandex':
                return (
                    <YandexConnector
                        onCancel={onCancel}
                        onSuccess={onSuccess}
                        onError={onError}
                    />
                )
            case 'sberbank':
                return (
                    <SberbankConnector
                        onCancel={onCancel}
                        onSuccess={onSuccess}
                        onError={onError}
                    />
                )
            default:
                return undefined
        }
    }

    return (
        <>
            <Title title={'Подключите ваши кабинеты и найдите точки роста'} />
            <div className={'mb-8 grid gap-4 sm:grid-cols-2 xl:grid-cols-5'}>
                {marketplaces
                    .filter((item) => item.visible)
                    .map((item) => (
                        <AvailableCard
                            key={item?.id}
                            marketName={item?.name}
                            label={item?.label}
                            tooltip={item.tooltip}
                            disabled={item.disabled}
                            connectorsLabel={item.connectorsLabel}
                            connector={(onCancel, onSuccess, onError) =>
                                getConnector(
                                    item?.name,
                                    onCancel,
                                    onSuccess,
                                    onError
                                )
                            }
                            connectionAllowed={
                                credentials.length < userStore.limitCabinets
                            }
                        />
                    ))}
                <div className={'col-span-1 grid xl:col-span-2'}>
                    <GreetingVideoCard />
                </div>
            </div>
        </>
    )
})

Available.displayName = 'Available'
