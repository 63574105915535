//libs

//components
import {Badge} from '@components/elements'

//icons
import {XCircleIcon} from '@heroicons/react/outline'

const Tag = ({children, onCancel, ...rest}) => {
    return (
        <>
            <Badge {...rest}>
                {children}
                <XCircleIcon
                    className={'-mr-1 ml-2 h-4 w-4 cursor-pointer'}
                    onClick={onCancel}
                />
            </Badge>
        </>
    )
}

export {Tag}
