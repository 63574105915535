import {observer} from 'mobx-react'
import {HeaderTitle} from '@components/navigation'
import SupportMenu from '@components/navigation/header/SupportMenu'
import {Button} from '@components/elements'
import {useSelector} from '@hooks'
import {useHistory} from 'react-router-dom'
import {AppPath} from '@routesEnums'

export const ErrorAuthHeader = observer(({title, onLogout}) => {
    const authStore = useSelector((store) => store.authStore)
    const history = useHistory()
    const onClickLogout = () => {
        onLogout()
        authStore.logout()
        history.push(AppPath.signIn)
        document.location.reload()
    }
    return (
        <div className={'sticky top-0 z-10'}>
            <div className='flex h-16 flex-shrink-0 bg-white shadow dark:border-b dark:border-gray-600 dark:bg-gray-800 dark:shadow-none'>
                <div className='flex flex-1 justify-between px-4'>
                    <div className='flex flex-1'>
                        {!!title?.length && <HeaderTitle title={title} />}
                    </div>
                    <div className='ml-4 flex items-center space-x-5 md:ml-6'>
                        <div className={'flex items-center space-x-1'}>
                            <SupportMenu />
                        </div>
                        <Button
                            className={'min-w-[100px]'}
                            onClick={onClickLogout}
                        >
                            Выйти
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
})
