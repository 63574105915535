import {action, makeObservable, observable} from 'mobx'

export class SelectedStore {
    constructor(options = [], selected) {
        this.options = options
        makeObservable(this, {
            selected: observable,
            options: observable,

            setSelectedItem: action,
            setOptionById: action,
            setOptions: action,
            reset: action,
        })
        this.setSelectedItem(selected)
    }

    selected = null
    options = []

    setSelectedItem = (value = null) => {
        this.selected = value
    }

    setOptions = (array = []) => {
        this.options = array
    }

    addOptions = (array = []) => {
        this.options.push(...array)
    }

    setOptionById = (id) => {
        this.selected =
            this.options.find((item) => item.id === id) || this.options[0]
    }

    reset = () => {
        this.selected = null
    }
}
