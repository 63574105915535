import {ModalSuccess} from '@components/overlays'

import {clickToUrl} from '@utils/helpers'
import {observer} from 'mobx-react'
import {useSelector} from '@hooks'
import ProfileService from '@services/ProfileService/ProfileService'

export const ModalConfirmEmail = observer(({email, open, onClose}) => {
    const profileStore = useSelector(
        (store) => store.accountStore.stores.profileStore
    )
    const onConfirmRepeatEmail = async () => {
        try {
            await ProfileService.postConfirmEmailRepeat({email})
        } catch (e) {
            console.log(e)
        }
    }
    const handleSuccess = () => {
        onConfirmRepeatEmail()
        let parsEmail = email.substring(email.indexOf('@') + 1)
        switch (parsEmail) {
            case 'ya.ru':
            case 'yandex.ru': {
                clickToUrl('https://mail.yandex.ru', true)
                break
            }
            case 'gmail.com': {
                clickToUrl('https://mail.google.com', true)
                break
            }
            case 'mail.ru': {
                clickToUrl('https://e.mail.ru', true)
                break
            }
            default: {
                clickToUrl('https://mail.yandex.ru', true)
            }
        }
        onClose()
    }

    return (
        <ModalSuccess
            open={open}
            onClose={onClose}
            onSuccessClose={handleSuccess}
            closeButton={'Подтвердить почту'}
            description={
                'Мы отправили вам письмо с ссылкой подтверждения регистрации. Перейдите по ссылке для завершения регистрации.'
            }
            title={`Для завершения регистрации необходимо подтвердить почту`}
        />
    )
})
