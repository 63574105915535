import {observer} from 'mobx-react'
import {Card} from '@components/layout'
import {useEffect} from 'react'
import cn from 'classnames'
import {useAnalyticEventsHook, useSelector} from '@hooks'

const IntegrationsContent = observer(() => {
    const {pageView} = useAnalyticEventsHook()

    useEffect(() => {
        pageView()
    }, [])
    return (
        <Card className='relative'>
            <div className='relative'>
                <div
                    className={cn(
                        'prose prose-lg prose-blue max-w-full py-3 text-gray-600',
                        'dark:text-gray-400'
                    )}
                >
                    <p className='mt-8 text-xl leading-8 text-gray-500 dark:text-gray-200'>
                        У нашей платформы есть внутренний API для
                        взаимодействия, который можно использовать для
                        интеграции с любыми внешними системами:
                    </p>
                    <ul role='list'>
                        <li>ERP</li>
                        <li>SAP</li>
                        <li>1С</li>
                        <li>1С-Битрикс</li>
                        <li>Мой Склад</li>
                        <li>Любые PIM-системы</li>
                        <li>Любые другие CMS-системы</li>
                    </ul>
                    <p className='mt-8 text-xl leading-8 text-gray-500 dark:text-gray-200'>
                        Выдача ключей API для интеграции с нашей платформой
                        сейчас происходит в ручном режиме через менеджеров
                        технической поддержки.
                    </p>
                    <p className='mt-8 text-xl leading-8 text-gray-500 dark:text-gray-200'>
                        Для обсуждения вопросов интеграции свяжитесь с нами по
                        электронной почте:{' '}
                        <a
                            href={'mailto:support@seller24.ru'}
                            target={'_blank'}
                        >
                            support@seller24.ru
                        </a>
                    </p>
                </div>
            </div>
        </Card>
    )
})

export default IntegrationsContent
