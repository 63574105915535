import {ApiFactory} from '@services/ApiFactory'
import {baseConfig} from '@services/apiConfigs'
import {TelegramUrl} from '@routesEnums'

const telegramInstance = ApiFactory.createApi(baseConfig)

class TelegramService {
    static getTgUrl = async () => {
        return await telegramInstance.get(TelegramUrl.telegramUrl)
    }
    static getTgQr = async () => {
        return await telegramInstance.get(TelegramUrl.telegramQr)
    }
    static getTgAccount = async () => {
        return await telegramInstance.get(TelegramUrl.telegramAccount)
    }
    static getTgSettings = async () => {
        return await telegramInstance.get(TelegramUrl.telegramSettings)
    }
    static getTgNotificationTypes = async () => {
        return await telegramInstance.get(TelegramUrl.telegramNotificationTypes)
    }
    static postTgToken = async () => {
        return await telegramInstance.post(TelegramUrl.telegramToken)
    }
    static patchTgNotificationSlug = async (slug, query) => {
        const config = {
            params: query,
        }
        return await telegramInstance.patch(
            TelegramUrl.telegramNotificationSlug(slug),
            undefined,
            config
        )
    }
    static patchTgCommonNotifications = async (query) => {
        const config = {
            params: query,
        }
        return await telegramInstance.patch(
            TelegramUrl.telegramCommonNotifications,
            undefined,
            config
        )
    }
    static patchTgNotificationMpChange = async (query) => {
        const config = {
            params: query,
        }
        return await telegramInstance.patch(
            TelegramUrl.telegramNotificationMpChange,
            undefined,
            config
        )
    }
    static patchTgSwitchTimeSet = async (query) => {
        const config = {
            params: query,
        }
        return await telegramInstance.patch(
            TelegramUrl.telegramSwitchTimeSet,
            undefined,
            config
        )
    }
    static patchTgTimeSet = async (query) => {
        const config = {
            params: query,
        }
        return await telegramInstance.patch(
            TelegramUrl.telegramTimeSet,
            undefined,
            config
        )
    }
    static deleteTgAccount = async (query) => {
        const config = {
            params: query,
        }
        return await telegramInstance.delete(
            TelegramUrl.telegramAccount,
            undefined,
            config
        )
    }
}

export default TelegramService
