import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {TaxServices} from '@services/TaxServices'
import {marketplaceId} from '@utils/constants'
import {SelectedStore} from './SelectStore'
import {format} from 'date-fns'

export class TaxStore {
    constructor(selectedCabinet, cabinetsOptions) {
        makeAutoObservable(this)

        this.disposer = reaction(
            () => this.cabinet,
            (cabinet) => {
                this.getTax(cabinet.key)
            }
        )
        this.initialState(selectedCabinet, cabinetsOptions)
    }

    dict = new SelectedStore()
    taxes = []
    cabinet = {}
    date = new Date()
    tax = 0
    useNds = true
    ndsSpecial = false
    nds = 0

    // currentTax = {
    //     value: 0,
    //     name: '',
    // }

    useSpp = false

    setCabinet = (value) => {
        this.cabinet = value
    }

    setDate = (value) => {
        this.date = value
    }

    setTax = (value) => {
        this.tax = value
    }

    setNds = (value) => {
        this.nds = value
    }

    getTypeName = (id) => {
        return this.dict.options.find((item) => item.id === id).name
    }

    setUseSpp = (value) => {
        this.useSpp = value
    }

    setUseNds = (value) => {
        this.useNds = value
    }

    setNdsSpecial = (value) => {
        this.ndsSpecial = value
    }

    get isWb() {
        return this.cabinet.marketId === marketplaceId.Wildberries
    }

    initialState = async (selectedCabinet, cabinetsOptions) => {
        await this.getTaxDict()
        if (selectedCabinet || cabinetsOptions.length === 1) {
            this.cabinet = selectedCabinet || cabinetsOptions[0]
            // this.getTax(this.cabinet.key)
        }
    }

    getTaxDict = async () => {
        try {
            const {data} = await TaxServices.getTaxDict()
            this.dict.setOptions(data.result)
        } catch (e) {
            console.log(e)
        }
    }

    getTax = async (id) => {
        try {
            const {data} = await TaxServices.getTax(id)
            runInAction(() => {
                this.taxes = data.result
                const currentTax = data.result?.[0]
                this.tax = currentTax?.value || 0
                this.useSpp = currentTax?.useSpp || false
                this.ndsSpecial = currentTax?.ndsSpecial || false
                this.nds = currentTax?.ndsValue || false
                this.useNds = currentTax?.useNds || false
                // this.currentTax.value = this.tax
                if (currentTax?.mpTaxId) {
                    this.dict.setOptionById(currentTax.mpTaxId)
                    // this.currentTax.name = this.dict.selected.name
                }
                if (!currentTax?.value) {
                    this.date = new Date(2020, 0, 1)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    postTax = async () => {
        if (!this.cabinet.key) return
        const payload = {
            actual_at: format(this.date, 'yyyy-MM-dd'),
            mp_tax_id: this.dict.selected.id,
            use_spp: this.useSpp,
            value: this.tax,
            nds_special: this.ndsSpecial,
            nds_value: this.useNds ? this.nds : 0,
            use_nds: this.useNds,
        }
        try {
            const {data} = await TaxServices.postTax(this.cabinet.key, payload)
            return Promise.resolve(data)
        } catch (e) {
            console.log(e)
            return Promise.reject(e)
        }
    }

    deleteTax = async (id) => {
        try {
            const {data} = await TaxServices.deleteTax(id)
            return Promise.resolve(data)
        } catch (e) {
            console.log(e)
            return Promise.reject(e)
        }
    }
}
