import {observer} from 'mobx-react'
import classNames from 'classnames'
import {Link} from 'react-router-dom'
import {AppPath} from '@routesEnums'

export const ExceedLimitCabinets = observer(() => {
    return (
        <span className={classNames('space-x-1')}>
            <span>
                Внимание! Вы превысили лимит текущего тарифа по количеству
                кабинетов. Пожалуйста,
            </span>
            <Link
                to={AppPath.accountTariff}
                className={
                    'cursor-pointer truncate whitespace-normal text-blue-500 hover:opacity-80 dark:text-blue-400'
                }
            >
                смените тариф
            </Link>
            <span>
                или отключите лишние кабинеты, чтобы работать с отчетами.
            </span>
        </span>
    )
})

ExceedLimitCabinets.displayName = 'ExceedLimitCabinets'
