import {useEffect, useRef} from 'react'
import {KeyIcon} from '@heroicons/react/outline'
import {AppPath} from '@routesEnums'
import {useAnalyticEventsHook, useSelector} from '@hooks'
import {NotificationPage} from '@components/applicationUi'
import {observer} from 'mobx-react'
import {ErrorAuthHeader} from '@components/navigation'
import {TokenService} from '@services/TokenService'
import {getStandName} from '@utils/helpers'

export const Error404 = observer(() => {
    const errorStore = useSelector((store) => store.errorStore)

    const supportOverlayStore = useSelector(
        (store) => store.supportOverlayStore
    )
    const {messageFront} = useAnalyticEventsHook()

    useEffect(() => {
        messageFront('APP CRASH')
        if (getStandName() === 'prod') {
            errorStore.sendError()
        }
    }, [])

    const handleClickLint = () => {
        errorStore.resetError()
        setTimeout(() => {
            supportOverlayStore.overlayControl.open()
        }, 0)
    }
    const logout = () => {
        errorStore.resetError(false)
    }
    return (
        <>
            <ErrorAuthHeader title={'Ошибка'} onLogout={logout} />
            <NotificationPage
                className={'h-screen'}
                title={'Произошла ошибка'}
                description={
                    'Мы уже пытаемся ее устранить. Обновите страницу или обратитесь в поддержку'
                }
                icon={<KeyIcon className='mx-auto h-20 w-20 text-red-600' />}
                link={
                    TokenService.isTokenExists()
                        ? AppPath.marketplaces
                        : AppPath.signIn
                }
                linkLabel={'Обратиться в поддержку'}
                linkOnClick={handleClickLint}
            />
        </>
    )
})

Error404.displayName = 'Error404'
