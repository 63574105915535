import {Button, ProgressBar} from '@components/elements'
import classNames from 'classnames'
import {QuestionMarkCircleIcon, XIcon} from '@heroicons/react/outline'
import {observer} from 'mobx-react'

const Label = observer(({children, className, onClick}) => {
    return (
        <div className={classNames('flex space-x-2', className)}>
            {children}
            {typeof onClick === 'function' ? (
                <span className={'cursor-pointer'} onClick={onClick}>
                    <QuestionMarkCircleIcon
                        className={
                            'h-6 w-6 text-gray-300 hover:opacity-80 dark:text-gray-500'
                        }
                    />
                </span>
            ) : null}
        </div>
    )
})

const Progress = ({value, className}) => {
    return <ProgressBar className={className} value={value} />
}

const ButtonAction = ({children, ...props}) => {
    return <Button {...props}>{children}</Button>
}

const BarContainer = ({className, children}) => {
    return <div className={classNames(className, 'flex')}>{children}</div>
}

export const OnboardingWrapper = ({children, className, onClose}) => {
    return (
        <div
            className={classNames(
                className,
                'space-y-0.5- relative flex flex-1 flex-col justify-between px-4'
            )}
        >
            <span className={'text-xs text-gray-600 dark:text-gray-400'}>
                Следующий шаг
            </span>
            {children}
            <XIcon
                onClick={onClose}
                className={
                    'absolute right-4 top-0 h-4 w-4 cursor-pointer text-gray-600 hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-500'
                }
            />
        </div>
    )
}

Label.displayName = 'Label'

OnboardingWrapper.Label = Label
OnboardingWrapper.Progress = Progress
OnboardingWrapper.ButtonAction = ButtonAction
OnboardingWrapper.BarContainer = BarContainer
