import {useState} from 'react'
import {Button} from '@components/elements'
import {DocumentTextIcon, TrashIcon} from '@heroicons/react/outline'
import {ModalConfirm} from '@components/overlays'
import {ModalControl} from '../../../../store/ModalControl'
import {observer} from 'mobx-react'

export const FilesImport = observer(
    ({files, onDelete, onUpload, checkSelectedDemoAcc = () => {}}) => {
        const [modalControl] = useState(() => new ModalControl())

        const handleClickImport = () => {
            if (checkSelectedDemoAcc()) {
                return modalControl.open()
            }
            onUpload()
        }

        return (
            <>
                <div className={'mb-10 flex flex-col'}>
                    <h3
                        className={
                            'mb-4 font-medium text-gray-700 dark:text-gray-300'
                        }
                    >
                        Ваши файлы для импорта
                    </h3>
                    {files.map((file, idx) => {
                        return (
                            <div key={idx} className={'mb-4 flex'}>
                                <DocumentTextIcon
                                    className={
                                        'mr-3 h-5 w-5 text-blue-500 dark:text-blue-300'
                                    }
                                />
                                <span
                                    className={
                                        'text-blue-500 dark:text-blue-300'
                                    }
                                >
                                    {file.fileName}
                                </span>
                                <TrashIcon
                                    className={
                                        'ml-4 h-5 w-5 cursor-pointer text-red-500 hover:opacity-80 dark:text-red-400'
                                    }
                                    onClick={onDelete(file, idx)}
                                />
                            </div>
                        )
                    })}
                    <div className={'flex items-center'}>
                        <Button onClick={handleClickImport}>
                            Импортировать
                        </Button>
                        <Button
                            className={'ml-4'}
                            color={'white'}
                            onClick={onDelete()}
                        >
                            Очистить
                        </Button>
                    </div>
                </div>
                <ModalConfirm
                    open={modalControl.isOpen}
                    onClose={modalControl.close}
                    title={
                        'К сожалению, вы не можете обновлять себестоимость для демо-товаров. Загрузите себестоимость для товаров из ваших кабинетов'
                    }
                    acceptDisabled
                    accept={'Сохранить'}
                    onAccept={() => {}}
                />
            </>
        )
    }
)

FilesImport.displayName = 'FilesImport'
