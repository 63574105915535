import {useRef, useState} from 'react'
import {observer} from 'mobx-react'
import {usePopper} from 'react-popper'

import {Popover, Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/solid'

import {Calendar} from 'react-date-range'
import {format} from 'date-fns'
import {ru} from 'date-fns/locale'

import classNames from 'classnames'
import 'react-date-range/dist/styles.css'
import './themes/lightTheme.scss'
import './themes/darkTheme.scss'
import {Portal} from '@components/elements'

export const DatePicker = observer(
    ({
        className,
        label,
        date,
        disabled,
        onChange,
        error,
        helperText,
        minDate,
        maxDate,
        scrollEnabled = true,
    }) => {
        const [referenceElement, setReferenceElement] = useState(null)
        const [popperElement, setPopperElement] = useState(null)
        const {styles, attributes} = usePopper(
            referenceElement,
            popperElement,
            {}
        )
        const closeRef = useRef(null)

        const getLabel = () => {
            if (!date)
                return <span className={'text-gray-300'}>Выберите дату</span>

            return format(date, 'dd MMM yyyy', {
                locale: ru,
            })
        }

        const handleChange = (date) => {
            onChange(date)
            closeRef.current.click()
        }

        return (
            <Popover>
                {({open}) => (
                    <>
                        {!!label && (
                            <label
                                className={classNames(
                                    'mb-1 block text-sm font-medium text-gray-700 dark:text-gray-300',
                                    {
                                        'opacity-50': disabled,
                                    }
                                )}
                            >
                                {label}
                            </label>
                        )}
                        <Popover.Button
                            ref={setReferenceElement}
                            disabled={disabled}
                            className={classNames(
                                className,
                                'group flex w-full items-center justify-between rounded-md border border-gray-300 bg-white px-2.5 py-3 text-sm font-medium transition-all',
                                'hover:text-opacity-100 focus:outline-none',
                                'dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600',
                                {
                                    'text-opacity-90': open,
                                    'opacity-50': disabled,
                                    'border-red-500 dark:border-red-300': error,
                                }
                            )}
                        >
                            <span className={'block truncate'}>
                                {getLabel()}
                            </span>
                            {!disabled && (
                                <ChevronDownIcon
                                    className={`${
                                        open ? '' : 'text-opacity-70'
                                    } ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                                    aria-hidden='true'
                                />
                            )}
                        </Popover.Button>
                        {!!helperText && (
                            <p
                                className={classNames(
                                    'mt-1 text-sm text-gray-500 dark:text-gray-400',
                                    {
                                        'text-red-500 dark:text-red-300': error,
                                    }
                                )}
                            >
                                {helperText}
                            </p>
                        )}

                        <Transition
                            show={open}
                            as={'div'}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <Portal>
                                <Popover.Panel
                                    className='absolute left-1/2 z-50 mt-3 flex max-w-[22rem] -translate-x-1/2 transform flex-col overflow-hidden rounded-xl border border-gray-200 shadow-xl dark:border-gray-700'
                                    ref={setPopperElement}
                                    style={styles.popper}
                                    {...attributes.popper}
                                >
                                    {({close}) => (
                                        <>
                                            <Calendar
                                                date={date}
                                                onChange={handleChange}
                                                minDate={minDate}
                                                maxDate={maxDate}
                                                months={1}
                                                direction='vertical'
                                                scroll={{
                                                    enabled: scrollEnabled,
                                                }}
                                                locale={ru}
                                                weekdayDisplayFormat={'EEEEEE'}
                                                dateDisplayFormat={'d MMM yyyy'}
                                            />
                                            <div
                                                ref={closeRef}
                                                hidden
                                                onClick={close}
                                            />
                                        </>
                                    )}
                                </Popover.Panel>
                            </Portal>
                        </Transition>
                    </>
                )}
            </Popover>
        )
    }
)
