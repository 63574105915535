import {updateCancelToken} from '@services/apiService'
import {baseConfig} from '@services/apiConfigs'
import {MpImportUrl} from '@routesEnums'
import {ApiFactory} from '@services/ApiFactory'

const mpImportInstance = ApiFactory.createApi(baseConfig)

class MpImportService {
    static getMpImportStatus = async (query, source) => {
        const newConfig = {
            cancelToken: updateCancelToken(source),
        }
        return await mpImportInstance.get(
            MpImportUrl.mpImportStatus,
            query,
            newConfig
        )
    }

    static postMpImportStart = async (payload) => {
        return await mpImportInstance.post(MpImportUrl.mpImportStart, payload)
    }

    static postMpImportStop = async (payload) => {
        return await mpImportInstance.post(MpImportUrl.mpImportStop, payload)
    }
}

export default MpImportService
