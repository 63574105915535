import {useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {TextField} from '@components/forms/TextFields'
import {InfoField} from '@components/applicationUi'

export const SettingsInput = observer(
    ({label, daysValue, onChange, helpContent}) => {
        const [value, setValue] = useState(0)

        useEffect(() => {
            if (!value) setValue(daysValue)
        }, [daysValue])

        const handleChangeDay = (e) => {
            const value = e.target.value.replace(/[^0-9]/g, '')
            setValue(value)
        }

        const handleBlurDays = () => {
            if (daysValue === value) return
            onChange(value)
        }

        return (
            <InfoField label={label} helpContent={helpContent}>
                <div className={'flex items-center space-x-2'}>
                    <TextField
                        className={'w-20'}
                        inputClassName={'text-center py-1 px-1'}
                        value={value}
                        onChange={handleChangeDay}
                        onBlur={handleBlurDays}
                    />
                    <span>дней</span>
                </div>
            </InfoField>
        )
    }
)

SettingsInput.displayName = 'SettingsInput'
