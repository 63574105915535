import {observer} from 'mobx-react'

import {useRouteMatch, useHistory} from 'react-router-dom'
import {AppPath} from '@routesEnums'
import {EmailConfirm} from '@views/confirm/EmailConfirm'
import {ProvidersConfirm} from '@views/confirm/ProvidersConfirm'
import {ResetPasswordConfirm} from '@views/confirm/ResetPasswordConfirm'
import {ChangeEmailConfirm} from '@views/confirm/ChangeEmailConfirm'

const Confirm = observer((props) => {
    const history = useHistory()
    const match = useRouteMatch()
    const {confirm} = match.params

    const renderPage = () => {
        switch (confirm.toLowerCase()) {
            case 'email': {
                return <EmailConfirm />
            }
            case 'confirm_change_email':
            case 'change_email': {
                return <ChangeEmailConfirm />
            }
            case 'yandex':
            case 'vk':
            case 'mailru': {
                return <ProvidersConfirm providerName={confirm} />
            }
            case 'reset': {
                return <ResetPasswordConfirm />
            }
            default: {
                history.push(AppPath.signIn)
                return null
            }
        }
    }

    return (
        <div className='flex h-screen bg-white dark:bg-gray-800'>
            {renderPage()}
        </div>
    )
})

export default Confirm
