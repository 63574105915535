import {observer} from 'mobx-react'
import {ToggleLabeled} from '@components/forms'
import {InfoField} from '@components/applicationUi'

export const SettingsToggle = observer(
    ({label, checked, onChange, helpContent, labelRight, labelLeft}) => {
        return (
            <InfoField label={label} helpContent={helpContent}>
                <ToggleLabeled
                    checked={checked}
                    onChange={onChange}
                    labelRight={labelRight}
                    labelLeft={labelLeft}
                />
            </InfoField>
        )
    }
)
