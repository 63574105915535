import {ApiFactory} from '@services/ApiFactory'
import {CategoriesUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'

const categoriesInstance = ApiFactory.createApi(baseConfig)

class ProductCategoriesService {
    static getFeedCats = async (payload, uuid) => {
        return await categoriesInstance.post(
            CategoriesUrl.feedCats(uuid),
            payload
        )
    }

    static getFeedCatsChild = async (payload, uuid, catId) => {
        return await categoriesInstance.post(
            CategoriesUrl.feedCatsChild(uuid, catId),
            payload
        )
    }

    static getMasterCats = async () => {
        return await categoriesInstance.get(CategoriesUrl.masterCats)
    }

    static getMasterCatsChild = async (catId) => {
        return await categoriesInstance.get(
            CategoriesUrl.masterCatsChild(catId)
        )
    }

    static getCatsAttr = async (uuid, catId) => {
        return await categoriesInstance.get(
            CategoriesUrl.catsAttrs(uuid, catId)
        )
    }

    static getFeedSource = async (payload) => {
        return await categoriesInstance.post(CategoriesUrl.feedSource, payload)
    }

    static getProducts = async (payload) => {
        return await categoriesInstance.post(CategoriesUrl.getProduct, payload)
    }

    static putAssignCats = async (payload) => {
        return await categoriesInstance.put(CategoriesUrl.assign, payload)
    }
}

export default ProductCategoriesService
