import {useMemo, useRef, useState} from 'react'
import {observer} from 'mobx-react'
import {Popover, Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/solid'

import {DateRange, DefinedRange, Calendar} from 'react-date-range'
import {addMonths, format} from 'date-fns'
import {ru} from 'date-fns/locale'
import {customInputRanges, customStaticRanges} from './customRanges'

import 'react-date-range/dist/styles.css'
import './themes/lightTheme.scss'
import './themes/darkTheme.scss'
import classNames from 'classnames'

//hooks
import {useSelector, useResponsive} from '@hooks'
import {usePopper} from 'react-popper'
import {Button, Portal} from '@components/elements'
import {deepEqual} from '@utils/helpers'

const getFirstWeekDay = () => {
    const curr = new Date()
    const first = curr.getDate() - curr.getDay() + 1

    return new Date(curr.setDate(first))
}

const getFirstYearDay = () => {
    return new Date(new Date().getFullYear(), 0, 1)
}

const getMonthAgo = () => {
    const curr = new Date()
    const monthAgo = curr.setDate(curr.getDate() - 29)
    return new Date(monthAgo)
}

const getCurrentMonthAgo = (monthsAgo) => {
    const curr = new Date()
    const monthAgo = curr.setMonth(curr.getMonth() - monthsAgo)
    return new Date(monthAgo)
}

const getCurrentDaysAgo = (daysAgo) => {
    const curr = new Date()
    const monthAgo = curr.setDate(curr.getDate() - daysAgo)
    return new Date(monthAgo)
}

const getDayAgo = () => {
    const curr = new Date()
    const monthAgo = curr.setDate(curr.getDate() - 1)
    return new Date(monthAgo)
}

const DateRangePicker = observer((props) => {
    const {
        onChange,
        range,
        maxDate,
        minDate,
        className,
        label,
        onClose,
        disabled,
        error,
        helperText,
        popperOptions = {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 10],
                    },
                },
            ],
        },
        months = 1,
    } = props
    const [dateRange, setDateRange] = useState(range)
    const [prevDateRange, setPrevDateRange] = useState(null)
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const {styles, attributes} = usePopper(
        referenceElement,
        popperElement,
        popperOptions
    )
    const {isDarkTheme} = useSelector((store) => store.themeStore)
    const closeRef = useRef(null)

    const {sm} = useResponsive()

    const isDirty = useMemo(() => {
        if (!prevDateRange) return false
        return deepEqual(dateRange, prevDateRange)
    }, [prevDateRange, dateRange])

    const getLabel = () => {
        if (!range?.startDate || !range?.endDate)
            return (
                <span className={'text-gray-300'}>
                    {label || 'Выберите период'}
                </span>
            )

        return `${format(range?.startDate, 'dd MMM yyyy', {
            locale: ru,
        })} - ${format(range?.endDate, 'dd MMM yyyy', {
            locale: ru,
        })}`
    }

    const handleClickSaveDataRange = () => {
        onChange(dateRange)
        closeRef.current.click()
        setPrevDateRange(null)
    }

    const handleChangeRange = (item) => {
        setPrevDateRange(dateRange)
        setDateRange({
            startDate: new Date(item.selected.startDate),
            endDate: new Date(item.selected.endDate.setHours(23, 59, 59, 999)),
        })
    }
    return (
        <>
            <Popover>
                {({open}) => (
                    <>
                        {!!label && (
                            <label
                                className={classNames(
                                    'mb-1 block text-sm font-medium text-gray-700 dark:text-gray-300',
                                    {
                                        'opacity-50': disabled,
                                    }
                                )}
                            >
                                {label}
                            </label>
                        )}
                        <Popover.Button
                            ref={setReferenceElement}
                            disabled={disabled}
                            className={classNames(
                                className,
                                'group flex min-h-[38px] w-full items-center justify-between rounded-md border border-gray-300 bg-white py-3 pl-5 pr-4 text-sm font-medium transition-all',
                                'hover:text-opacity-100 focus:outline-none',
                                'dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600',
                                {
                                    'text-opacity-90': open,
                                    'opacity-50': disabled,
                                    'dark:hover:bg-gray-600': !disabled,
                                }
                            )}
                        >
                            <span className={'block truncate'}>
                                {getLabel()}
                            </span>
                            {!disabled && (
                                <ChevronDownIcon
                                    className={`${
                                        open ? '' : 'text-opacity-70'
                                    } ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                                    aria-hidden='true'
                                />
                            )}
                        </Popover.Button>
                        {!!helperText && (
                            <p
                                className={classNames(
                                    'mt-1 text-sm text-gray-500 dark:text-gray-400',
                                    {
                                        'text-red-500 dark:text-red-300': error,
                                    }
                                )}
                            >
                                {helperText}
                            </p>
                        )}
                        <Transition
                            show={open}
                            as={'div'}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <Portal>
                                <Popover.Panel
                                    className={classNames(
                                        'absolute z-50 flex-col overflow-hidden rounded-xl border border-gray-200 shadow-xl dark:border-gray-700 lg:max-w-3xl',
                                        {
                                            'dark-calendar': isDarkTheme,
                                        }
                                    )}
                                    ref={setPopperElement}
                                    style={styles.popper}
                                    {...attributes.popper}
                                >
                                    {({close}) => (
                                        <>
                                            <div className={'flex'}>
                                                {sm && (
                                                    <DefinedRange
                                                        onChange={
                                                            handleChangeRange
                                                        }
                                                        locale={ru}
                                                        ranges={[
                                                            Object.assign(
                                                                {},
                                                                {
                                                                    key: 'selected',
                                                                },
                                                                dateRange
                                                            ),
                                                        ]}
                                                        staticRanges={
                                                            customStaticRanges
                                                        }
                                                        inputRanges={
                                                            customInputRanges
                                                        }
                                                    />
                                                )}
                                                <DateRange
                                                    onChange={handleChangeRange}
                                                    months={months}
                                                    minDate={minDate}
                                                    maxDate={maxDate}
                                                    direction='vertical'
                                                    scroll={{enabled: true}}
                                                    ranges={[
                                                        Object.assign(
                                                            {},
                                                            {key: 'selected'},
                                                            dateRange
                                                        ),
                                                    ]}
                                                    locale={ru}
                                                    weekdayDisplayFormat={
                                                        'EEEEEE'
                                                    }
                                                    dateDisplayFormat={
                                                        'd MMM yyyy'
                                                    }
                                                />
                                            </div>
                                            {isDirty && (
                                                <Button
                                                    className={'w-full'}
                                                    onClick={
                                                        handleClickSaveDataRange
                                                    }
                                                >
                                                    Сохранить
                                                </Button>
                                            )}
                                            <div
                                                ref={closeRef}
                                                hidden
                                                onClick={close}
                                            />
                                        </>
                                    )}
                                </Popover.Panel>
                            </Portal>
                        </Transition>
                    </>
                )}
            </Popover>
        </>
    )
})

export {
    DateRangePicker,
    DateRange,
    DefinedRange,
    Calendar,
    getFirstWeekDay,
    getFirstYearDay,
    getMonthAgo,
    getCurrentMonthAgo,
    getCurrentDaysAgo,
    getDayAgo,
}
