import {ApiFactory} from '@services/ApiFactory'
import {baseConfig} from '@services/apiConfigs'
import {ImportWbUrl} from '@routesEnums'

const importWbInstance = ApiFactory.createApi(baseConfig)

export class ImportWbService {
    static postWbDetailed = async (payload) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        return await importWbInstance.post(
            ImportWbUrl.wbDetailed,
            payload,
            config
        )
    }
    static postRecalc = async (payload) => {
        return await importWbInstance.post(
            ImportWbUrl.wbDetailedRecalc,
            payload
        )
    }
    static postImportWbList = async (payload) => {
        return await importWbInstance.post(ImportWbUrl.wbDetailedList, payload)
    }
    static patchSource = async (payload) => {
        return await importWbInstance.patch(
            ImportWbUrl.wbDetailedSource,
            payload
        )
    }
}
