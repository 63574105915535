import {baseConfig} from '@services/apiConfigs'
import {TaxUrl} from '@routesEnums'
import {ApiFactory} from '@services/ApiFactory'

const taxInstance = ApiFactory.createApi(baseConfig)

export class TaxServices {
    static getTaxDict = async () => {
        return await taxInstance.get(TaxUrl.taxDict)
    }

    static getTax = async (id) => {
        return await taxInstance.get(TaxUrl.tax(id))
    }

    static postTax = async (id, payload) => {
        return await taxInstance.post(TaxUrl.tax(id), payload)
    }

    static deleteTax = async (id) => {
        return await taxInstance.delete(TaxUrl.tax(id))
    }
}
