import {Listbox} from '@headlessui/react'
import cn from 'classnames'
import classNames from 'classnames'
import {CheckBox} from '@components/forms'

export const MultiSelectBody = ({
    setPopperElement,
    referenceElement,
    styles,
    attributes,
    options,
    childElement,
    handleSelect,
    isSelected,
    size,
    portal,
    zIndex = 999999,
}) => {
    return (
        <Listbox.Options
            className={cn(
                'absolute z-10 mt-1 max-h-60 w-full bg-white shadow-lg',
                'overflow-auto rounded-md py-1 text-base ring-1 ring-black ring-opacity-5',
                'focus:outline-none sm:text-sm',
                'dark:bg-gray-700',
                {
                    'w-full': !portal,
                }
            )}
            ref={setPopperElement}
            style={{
                ...styles.popper,
                zIndex,
                width:
                    portal && referenceElement
                        ? `${referenceElement?.offsetWidth || 80}px`
                        : undefined,
            }}
            {...attributes.popper}
        >
            {options.map((option, index) => {
                const selected = isSelected(option)
                return (
                    <div
                        key={index}
                        className={classNames(
                            // selected
                            //     ? 'text-white bg-blue-500 dark:bg-blue-400'
                            //     : 'text-gray-900 dark:text-gray-300',
                            'text-gray-900 dark:text-gray-300',
                            'relative cursor-pointer select-none list-none',
                            size === 'sm' && 'py-1.5 pl-3 pr-9 text-xs',
                            size === 'md' &&
                                'py-2 pl-4 pr-9 text-sm font-medium leading-4',
                            size === 'base' &&
                                'py-2 pl-5 pr-9 text-sm font-medium',
                            size === 'lg' &&
                                'py-2 pl-5 pr-9 text-base font-medium',
                            size === 'xl' &&
                                'py-3 pl-7 pr-9 text-base font-medium'
                        )}
                        // onClick={(e) => {
                        //     debugger
                        //     handleSelect(option)
                        //     e.stopPropagation()
                        // }}
                    >
                        <CheckBox
                            checked={selected}
                            containerClassName={'truncate'}
                            label={
                                <span
                                    className={classNames(
                                        selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                        'text-gray-900 dark:text-gray-300'
                                    )}
                                >
                                    {childElement
                                        ? childElement({
                                              option,
                                              selected,
                                          })
                                        : option.name}
                                </span>
                            }
                            onChange={() => {
                                handleSelect(option)
                            }}
                        />
                    </div>
                )
            })}
        </Listbox.Options>
    )
}
