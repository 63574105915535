import {Amount} from '@components/elements'

export const StatusRate = ({status, percent}) => {
    return (
        <span className={'whitespace-nowrap'}>
            {status === 'positive' && <span>+</span>}
            <Amount value={percent} currency={'PER'} decimalLength={0} />
        </span>
    )
}
