export const TaxCardInfoLink = ({isDark}) => {
    return (
        <div className={'w-full text-sm'}>
            <p className={'mt-6 space-y-4 dark:text-gray-200'}>
                Сначала, если вы не знаете ответ на какой-то из вопросов:
                <ul className={'ml-4 mt-2 list-disc space-y-2'} role='list'>
                    <li>Какая у вас система налогообложения</li>
                    <li>
                        По какой ставке вы платите налог УСН/Налог на прибыль
                    </li>
                    <li>Платите ли вы НДС</li>
                    <li>
                        (если платите НДС) Как вы исчисляете НДС - по особому
                        режиму или по стандартному
                    </li>
                    <li>По какой ставке вы считаете исходящий НДС</li>
                </ul>
            </p>
            <p className={'mt-6 space-y-4 dark:text-gray-200'}>
                Уточните информацию у вашего бухгалтера, он точно знает.
            </p>
            <p className={'mt-6 space-y-4 dark:text-gray-200'}>
                Далее настройте расчет:
                <ul className={'ml-4 mt-2 list-decimal space-y-2'} role='list'>
                    <li>Выберите систему налогообложения.</li>
                    <li>Укажите ставку УСН/ННП.</li>
                    <p className={'mt-6 space-y-4 dark:text-gray-200'}>
                        При выборе системы налогообложения мы сразу подставляем
                        актуальную ставку, но убедитесь, что в вашем случае она
                        тоже актуальна
                    </p>
                    <li>
                        Если вы уплачиваете НДС, включите соответствующую
                        настройку.
                    </li>
                    <p className={'mt-6 space-y-4 dark:text-gray-200'}>
                        С 1 января 2025 года все налогоплательщики на УСН с
                        годовым оборотом более 60 млн. руб. обязаны, помимо
                        налога УСН, платить НДС, как налогоплательщики на ОСНО.
                    </p>
                    <li>
                        Если вы исчисляете НДС по особому режиму, включите
                        соответствующую настройку.
                    </li>
                    <p className={'mt-6 space-y-4 dark:text-gray-200'}>
                        На особом режиме НДС вы не можете получить налоговый
                        вычет по входящему НДС, но ставка исходящего НДС у вас
                        снижается до 5/7%.
                    </p>
                    <li>Если вы уплачиваете НДС, укажите вашу ставку НДС.</li>
                    <p className={'mt-6 space-y-4 dark:text-gray-200'}>
                        На обычном режиме ваша ставка будет 0, 10 или 20%. На
                        особом режиме 5 или 7%.
                    </p>
                    <li>
                        Выберите дату начала расчетов по выбранным настройкам.
                    </li>
                    <p className={'mt-6 space-y-4 dark:text-gray-200'}>
                        Например, если вы получили обязательство уплачивать НДС
                        на УСН с текущего месяца, выберите первое число текущего
                        месяца
                    </p>
                    <li>Нажмите “Сохранить”</li>
                    <p className={'mt-6 space-y-4 dark:text-gray-200'}>
                        В историю изменения ставки добавится новый блок. Это
                        значит, настройки успешно сохранены.
                    </p>
                </ul>
            </p>
            <p className={'mt-6 space-y-4 dark:text-gray-200'}>
                <strong>ВАЖНО:</strong>
                <p className={'mt-6 space-y-4 dark:text-gray-200'}>
                    Seller24 - платформа для управленческого учета, а не
                    бухгалтерского. Мы не можем предусмотреть все частные случаи
                    и тонкости работы с налогами в универсальном решении,
                    поэтому расчеты нашей платформы не будут сходиться с данными
                    вашей бухгалтерии на 100%. Но мы делаем все, чтобы
                    погрешность была минимальной и не влияла на решения, которые
                    вы принимаете по ценообразованию с помощью нашей платформы.
                </p>
            </p>
        </div>
    )
}
