import {ToggleLabeled} from '@components/forms'
import {useSelector} from '@hooks'

import {observer} from 'mobx-react'

const DarkTheme = observer(() => {
    const {isDarkTheme, toggleTheme, isSystemTheme, toggleSystemTheme} =
        useSelector((store) => store.themeStore)

    return (
        <>
            <div className='p-2'>
                <ToggleLabeled
                    labelLeft='Системная тема'
                    descriptionLeft='Следит за темой системы'
                    checked={isSystemTheme}
                    onChange={toggleSystemTheme}
                />
            </div>
            {!isSystemTheme && (
                <div className='p-2'>
                    <ToggleLabeled
                        labelLeft='Темная тема'
                        descriptionLeft='Сменяет тему вручную'
                        checked={isDarkTheme}
                        onChange={toggleTheme}
                    />
                </div>
            )}
        </>
    )
})

export default DarkTheme
