import {Fragment, useRef} from 'react'
import classNames from 'classnames'
import {ArrowSmDownIcon, ArrowSmUpIcon} from '@heroicons/react/outline'
import {LoaderFull} from '@components/elements/Loader'
import './Table.scss'
import {TableScrollWrapper} from '@components/applicationUi/Lists/Table/TableScrollWrapper'
import {useTableScrollTop} from '@components/applicationUi/Lists/Table/useTableScrollTop'
import {LockedWrapperWithoutIcon} from '@components/elements'
export const Table = ({
    isBlocked,
    exceedProducts,
    exceedCabinets,
    isTrial,
    loader,
    tableInstance,
    className,
    changeSortBy,
    cellClassName,
    headerStyle,
    cellStyle,
    tableClassName,
    renderRowSubComponent = () => {},
}) => {
    const tableScrollRef = useRef(null)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        state,
        visibleColumns,
        ...rest
    } = tableInstance

    const bodyList = page && page.length ? page : rows

    const {syncScroll, offsetWidth, headerScrollRef} =
        useTableScrollTop(tableScrollRef)

    const renderSortArrow = (column, onClick) => {
        const {sortBy} = state
        const sortedItem =
            sortBy && sortBy.find((item) => item.id === column.id)
        if (!sortedItem || !sortedItem.visible) return

        return (
            <LockedWrapperWithoutIcon
                isBlocked={isBlocked && sortedItem.blockedTooltip}
                isTrial={isTrial && sortedItem.blockedTooltip}
                exceedProducts={exceedProducts && sortedItem.blockedTooltip}
                exceedCabinets={exceedCabinets && sortedItem.blockedTooltip}
            >
                <ArrowSmUpIcon
                    onClick={onClick}
                    className={classNames(
                        'h-5 w-5 flex-shrink-0 cursor-pointer transition-all',
                        {
                            'rotate-180': sortedItem?.dir !== 'asc',
                            'text-blue-500 dark:text-blue-400':
                                sortedItem.active,
                            'text-gray-300 dark:text-gray-600':
                                !sortedItem.active,
                        }
                    )}
                />
            </LockedWrapperWithoutIcon>
        )
    }

    const onClickSortBy = (column) => {
        if (isBlocked || isTrial) return
        const {sortBy} = state
        const sortedItem = sortBy.find((item) => item.id === column.id)
        if (!sortedItem) return
        const dir = sortedItem.dir === 'asc' ? 'desc' : 'asc'
        const newSortedItem = {
            ...sortedItem,
            dir,
        }
        changeSortBy && changeSortBy(column.id, newSortedItem)
    }

    return (
        <>
            <TableScrollWrapper
                ref={headerScrollRef}
                data={bodyList}
                offsetWidth={offsetWidth}
                tableRef={tableScrollRef}
                syncScroll={syncScroll}
            />
            <div
                className={classNames(
                    className,
                    'relative w-full overflow-auto'
                )}
                ref={tableScrollRef}
                onScroll={syncScroll}
            >
                {loader && <LoaderFull absolute />}
                <table
                    {...getTableProps()}
                    className={classNames(tableClassName, 'w-full')}
                >
                    <thead
                        className={classNames(
                            'sticky top-0 z-[2] bg-gray-50',
                            'dark:bg-gray-900'
                        )}
                    >
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, idx) => {
                                    const {key, ...props} =
                                        column.getHeaderProps()
                                    return (
                                        <th
                                            key={key}
                                            {...props}
                                            scope='col'
                                            style={{
                                                ...headerStyle,
                                                position: column.sticky
                                                    ? 'sticky'
                                                    : 'static',
                                                right:
                                                    column.sticky === 'right'
                                                        ? column.stickyStyle
                                                            ? column.stickyStyle
                                                            : 0
                                                        : 'auto',
                                                left:
                                                    column.sticky === 'left'
                                                        ? column.stickyStyle
                                                            ? column.stickyStyle
                                                            : 0
                                                        : 'auto',
                                            }}
                                            className={classNames(
                                                cellClassName,
                                                column.cls,
                                                idx === 0 ? 'pl-8' : 'pl-4',
                                                'z-50 bg-gray-50 py-3 pr-4 text-left text-xs font-medium uppercase tracking-wider text-gray-500',
                                                'dark:bg-gray-900 dark:text-gray-400'
                                            )}
                                        >
                                            {column.border && (
                                                <div
                                                    className={`border-${
                                                        column.sticky ===
                                                        'right'
                                                            ? 'right'
                                                            : 'left'
                                                    }`}
                                                />
                                            )}
                                            <div
                                                className={classNames(
                                                    cellClassName,

                                                    {
                                                        'flex items-center':
                                                            state?.sortBy &&
                                                            !!state?.sortBy.find(
                                                                (item) =>
                                                                    item.id ===
                                                                    column.id
                                                            ),
                                                        'justify-end':
                                                            column.end,
                                                    }
                                                )}
                                            >
                                                {column.render('Header')}
                                                {renderSortArrow(
                                                    column,
                                                    () =>
                                                        changeSortBy &&
                                                        onClickSortBy(column)
                                                )}
                                            </div>
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {bodyList.map((row, rowIndex) => {
                            prepareRow(row)
                            const {key, rowProps} = row.getRowProps()
                            return (
                                <>
                                    <tr key={key} {...rowProps}>
                                        {row.cells.map((cell, cellIndex) => {
                                            return (
                                                <td
                                                    key={cellIndex}
                                                    {...cell.getCellProps()}
                                                    style={{
                                                        ...cellStyle,
                                                        position: cell.column
                                                            .sticky
                                                            ? 'sticky'
                                                            : 'static',
                                                        right:
                                                            cell.column
                                                                .sticky ===
                                                            'right'
                                                                ? cell.column
                                                                      .stickyStyle
                                                                    ? cell
                                                                          .column
                                                                          .stickyStyle
                                                                    : 0
                                                                : 'auto',
                                                        left:
                                                            cell.column
                                                                .sticky ===
                                                            'left'
                                                                ? cell.column
                                                                      .stickyStyle
                                                                    ? cell
                                                                          .column
                                                                          .stickyStyle
                                                                    : 0
                                                                : 'auto',
                                                    }}
                                                    className={classNames(
                                                        cell.column.cellCls,
                                                        cellClassName,
                                                        cellIndex === 0
                                                            ? 'pl-8'
                                                            : 'pl-4',
                                                        'relative whitespace-nowrap py-4 pr-4 text-sm text-gray-500',
                                                        'dark:text-gray-400',
                                                        !row.depth
                                                            ? rowIndex % 2 === 0
                                                                ? 'bg-white dark:bg-gray-700'
                                                                : 'bg-gray-50 dark:bg-gray-600'
                                                            : '',
                                                        {
                                                            'bg-gray-100 dark:bg-innerTableBlack':
                                                                row.depth > 0,
                                                        }
                                                    )}
                                                >
                                                    {cell.column.border && (
                                                        <div
                                                            className={`border-${
                                                                cell.column
                                                                    .sticky ===
                                                                'right'
                                                                    ? 'right'
                                                                    : 'left'
                                                            }`}
                                                        />
                                                    )}
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                    {row.isExpanded ? (
                                        <tr
                                            key={`expended_${rowIndex} .`}
                                            className={
                                                rowIndex % 2 === 0
                                                    ? 'bg-white dark:bg-gray-700'
                                                    : 'bg-gray-50 dark:bg-gray-600'
                                            }
                                        >
                                            <td
                                                id={'dataExpanded'}
                                                colSpan={visibleColumns.length}
                                            >
                                                {renderRowSubComponent({
                                                    row,
                                                })}
                                            </td>
                                        </tr>
                                    ) : null}
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}
