import {observer} from 'mobx-react'

import {ImportWbFilters} from '@views/marketplaces/pages/ImportWb/components/ImportWbOverlay/components/ImportWbFilters'
import {ImportWbLegalFiles} from '@views/marketplaces/pages/ImportWb/components/ImportWbOverlay/components/ImportWbLegalFiles'
import {ImportWbWithoutLegalFiles} from '@views/marketplaces/pages/ImportWb/components/ImportWbOverlay/components/ImportWbWithoutLegalFiles'
export const ImportWbOverlayPanel = observer(
    ({filtersStore, xlsUploadFileStore, zipUploadFileStore}) => {
        return (
            <div className={'flex flex-col space-y-4 py-6 pr-6'}>
                <h2
                    className={
                        'text-2xl font-medium text-gray-800 dark:text-gray-200'
                    }
                >
                    Загрузка отчетов Wildberries
                </h2>
                <ImportWbFilters filtersStore={filtersStore} />
                {filtersStore.validLegalEntity ? (
                    <ImportWbLegalFiles
                        xlsUploadFileStore={xlsUploadFileStore}
                        zipUploadFileStore={zipUploadFileStore}
                    />
                ) : (
                    <ImportWbWithoutLegalFiles
                        xlsUploadFileStore={xlsUploadFileStore}
                    />
                )}
            </div>
        )
    }
)

ImportWbOverlayPanel.displayName = 'ImportWbOverlayPanel'
