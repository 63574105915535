import {forwardRef, useEffect, useRef, useState} from 'react'
import cn from 'classnames'
import {TextField} from '@components/forms/TextFields'
import {useDebouncedFunction, useOnClickOutside} from '@hooks'
import {usePopper} from 'react-popper'
import {Transition} from '@headlessui/react'
import {tr} from 'date-fns/locale'
const LIST_EDGE_THRESHOLD = 50 // px

const TextFieldSuggestion = forwardRef(
    (
        {
            onRecentChange,
            onFetchSuggestion,
            data = [],
            renderSuggestItem,
            onChange,
            className,
            isLoadingData = false,
            isNextLoadData = false,
            openAlways = false,
            ...rest
        },
        ref
    ) => {
        const [value, setValue] = useState('')
        const [isStop, setIsStop] = useState(false)
        const [isFocus, setIsFocus] = useState(false)

        const [opened, setOpened] = useState(false)
        const [referenceElement, setReferenceElement] = useState(null)
        const [popperElement, setPopperElement] = useState(null)
        const {styles, attributes} = usePopper(
            referenceElement,
            popperElement,
            {
                placement: 'bottom',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 0],
                        },
                    },
                ],
            }
        )
        const counter = useRef(1)

        useOnClickOutside({current: referenceElement}, () => {
            setOpened(false)
        })

        useEffect(() => {
            return () => {
                setOpened(false)
                setIsStop(true)
                counter.current = 1
            }
        }, [])

        useEffect(() => {
            onFetchSuggestion(value, 1)
            if (value) {
                setOpened(true)
            } else {
                setOpened(false)
                counter.current = 1
            }
        }, [value])

        useEffect(() => {
            if (data.length && isFocus) {
                setOpened(true)
            } else {
                setOpened(false)
                counter.current = 1
            }
        }, [data])

        const setValueDebounce = (value) => {
            setValue(value)
        }

        const setValuePending = useDebouncedFunction(
            setValueDebounce,
            500,
            isStop
        )

        const handleChangeSuggestion = (e) => {
            onChange(e)
            setValuePending(e.target.value)
        }

        const handleClickSuggestionItem = (item) => () => {
            onRecentChange(item)
            setOpened(false)
        }

        const getScrollBottom = (target) => {
            const {scrollTop, scrollHeight, offsetHeight} = target
            return scrollHeight - (scrollTop + offsetHeight)
        }

        const checkLoadCondition = ({target}) => {
            const scrollBottom = getScrollBottom(target)
            return scrollBottom < LIST_EDGE_THRESHOLD
        }

        const handleOnScroll = (e) => {
            if (
                !isLoadingData &&
                typeof onFetchSuggestion === 'function' &&
                isNextLoadData &&
                checkLoadCondition(e)
            ) {
                onFetchSuggestion(value, counter.current + 1)
                counter.current += 1
            }
        }

        return (
            <div
                className={cn('relative', className)}
                ref={setReferenceElement}
            >
                <TextField
                    ref={ref}
                    onChange={handleChangeSuggestion}
                    onFocus={() => {
                        setIsFocus(true)
                        if (openAlways) {
                            setOpened(true)
                        }
                    }}
                    onBlur={() => setIsFocus(false)}
                    {...rest}
                />
                {data.length && opened ? (
                    <Transition
                        show={opened}
                        as={'div'}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div
                            className={cn(
                                'absolute z-10 mt-1 max-h-60 w-full rounded-md bg-white py-1 text-base shadow-lg',
                                'overflow-auto ring-1 ring-black ring-opacity-5',
                                'focus:outline-none sm:text-sm',
                                'dark:bg-gray-700'
                            )}
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                            onScroll={handleOnScroll}
                        >
                            {data.map((item, personIdx) => (
                                <div
                                    key={personIdx}
                                    className={cn(
                                        'relative cursor-pointer select-none px-4 py-2 text-gray-900',
                                        'hover:bg-blue-500 hover:text-white',
                                        'dark:text-gray-300'
                                    )}
                                    onClick={handleClickSuggestionItem(item)}
                                >
                                    {renderSuggestItem(item, personIdx)}
                                </div>
                            ))}
                        </div>
                    </Transition>
                ) : null}
            </div>
        )
    }
)

export {TextFieldSuggestion}
