//libs

import {observer} from 'mobx-react'

//components
import {Button} from '@components/elements'
import {Link} from 'react-router-dom'

//icons
import {CubeTransparentIcon} from '@heroicons/react/outline'

export const NoCredentials = observer(
    ({
        className,
        iconNode,
        title = 'У вас нет подключенных маркетплейсов',
        summary = 'На их основе будут отображаться данные',
        cta = 'Подключить площадки',
        to = '/Marketplaces',
    }) => {
        return (
            <>
                <div
                    className={`\ \ relative flex h-full w-full flex-col items-center text-center ${className}`}
                >
                    {!iconNode ? (
                        <CubeTransparentIcon
                            className={
                                'mb-4 h-full max-h-[250px] w-1/2 max-w-[250px] text-gray-300 dark:text-gray-600'
                            }
                        />
                    ) : (
                        iconNode
                    )}
                    {!!title?.length && (
                        <p
                            className={
                                'whitespace-pre-line text-2xl font-semibold dark:text-gray-100'
                            }
                        >
                            {title}
                        </p>
                    )}
                    {!!summary?.length && (
                        <p
                            className={
                                'mt-4 whitespace-pre-line text-lg text-gray-600 dark:text-gray-400'
                            }
                        >
                            {summary}
                        </p>
                    )}
                    <Link to={to} className={'mt-6'}>
                        <Button>{cta}</Button>
                    </Link>
                </div>
            </>
        )
    }
)

NoCredentials.displayName = 'NoCredentials'
