import {useEffect, useMemo} from 'react'
import {useLatest} from '@hooks'

export const useResizeObserver = (cb) => {
    const latestCb = useLatest(cb)

    const observerCallback = (entries, observer) => {
        window.requestAnimationFrame(() => {
            if (!Array.isArray(entries) || !entries.length) {
                return
            }
            latestCb.current(entries, observer)
        })
    }

    const resizeObserver = useMemo(
        () => new ResizeObserver(observerCallback),
        []
    )

    useEffect(() => () => resizeObserver.disconnect(), [])

    return resizeObserver
}
